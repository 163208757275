import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { DashboardWidget } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
  gap: ${theme.spacing.x2};
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id?: string, widgetId?: string, widgetMeta?: any) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function WorkspaceOutcomeTaskGraphWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    handleSaveWidget('workspace-outcome-task-graph', 'leaderboard', undefined, widget?.id);
  };

  return (
    <Container>
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default WorkspaceOutcomeTaskGraphWidgetDetails;

import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import KoalaAvatar from 'koala/components/Avatar';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { InitiativeComment } from 'types';
import MentionBox from 'components/MentionBox';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';

const InitiativeCommentContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'avatar header'
    '. content'
    '. actions';
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0px;

  align-items: center;
`;

const AvatarSection = styled.div`
  grid-area: avatar;
`;

const TitleSection = styled.div`
  grid-area: header;
  display: flex;
  gap: ${theme.spacing.x1};
  height: 3.2rem;
  align-items: center;
`;

const ContentSection = styled.div`
  grid-area: content;
`;

const ActionSection = styled.div`
  display: flex;
  grid-area: actions;
  gap: ${theme.spacing.x1};
`;

interface Props {
  comment: InitiativeComment;
  setIsEditing: (value: boolean) => void;
}

function EditInitiativeComment(props: Props) {
  const { comment, setIsEditing } = props;
  const { user } = comment.membership;
  const { t } = useTranslation();
  const [body, setBody] = useState(comment.body);
  const queryCache = useQueryCache();

  // Review comment mutations
  const [updateInitiativeCommentMutation, { isLoading }] = useMutation(remoteApi.updateInitiativeComment, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiativeComments);
      setIsEditing(false);
    },
  });

  const handleSubmit = () => {
    const params = {
      body,
    };
    updateInitiativeCommentMutation({
      initiativeCommentId: comment.id,
      initiative_comment: params,
    });
  };

  const placeholder = t('modals.initiative.statusPlaceholder') ?? 'Add a comment';
  return (
    <InitiativeCommentContainer>
      <AvatarSection>
        <KoalaAvatar membership={comment.membership} tooltipType="none" />
      </AvatarSection>
      <TitleSection>
        <p>
          <b>{user.fullname}</b>
        </p>
        <InitiativeWorkStatus providedState={comment.work_state} showArrow={false} />
      </TitleSection>
      <ContentSection>
        <MentionBox
          setValue={setBody}
          value={body}
          placeholder={placeholder}
          comment={true}
          cmdEnterCallback={handleSubmit}
        />
      </ContentSection>
      <ActionSection>
        <KoalaButton onClick={handleSubmit} loading={isLoading} disabled={isLoading}>
          {t('shared.save')}
        </KoalaButton>
        <KoalaTextButton onClick={() => setIsEditing(false)}>{t('shared.cancel')}</KoalaTextButton>
      </ActionSection>
    </InitiativeCommentContainer>
  );
}

export default React.memo(EditInitiativeComment);

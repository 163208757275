import React from 'react';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, LeaderboardRow, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { DashboardWidget, Membership, Workspace } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaLoader from 'koala/components/Loader';
import ProgressBarV2 from 'components/ProgressBarV2';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function InitiativeProgressLeaderboardWidget(props: Props) {
  const { widget } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const direction = widget.widget_meta.order ?? 'desc';

  const queryKeyInitiativeProgressLadder = [
    queryKeys.currentWorkspace,
    currentWorkspace.slug,
    'initiative_progress_ladder',
    direction,
  ];
  const { isFetching: isFetchingInitiativeProgressLadder, data: initiativeProgressLadderResponse } = useQuery(
    queryKeyInitiativeProgressLadder,
    remoteApi.fetchWorkspaceInitiativeProgressLadder,
    {
      staleTime: 0,
    },
  );

  const initiativeProgressLadder: Membership[] = initiativeProgressLadderResponse?.data || [];

  let widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.initiativeProgressLeaderboard', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      });

  // Set a special reverse leaderboard title if the direction is ascending
  if (!widget.title && direction === 'asc') {
    widgetTitle = t('workspaceDashboards.lowInitiativeProgressLeaderboard', {
      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
    });
  }

  const handleUserClick = (membership: Membership) => {
    const path = `/${currentWorkspace.slug}/people/${membership.id}`;
    window.open(path, '_blank');
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        {isFetchingInitiativeProgressLadder && <KoalaLoader />}
        <Container className="panel-checkins-chart">
          {initiativeProgressLadder.length === 0 && <span>{t('workspaceDashboards.noActivity')}</span>}
          {initiativeProgressLadder.map((member, index) => {
            let number_label = (index + 1).toString();
            if (direction === 'desc' && index === 0) {
              number_label = '🏆';
            }
            return (
              <LeaderboardRow key={member.id} onClick={() => handleUserClick(member)}>
                {direction === 'desc' && <div className="number">{number_label}</div>}
                <div className="profile">
                  <KoalaAvatar membership={member} tooltipType="none" handleClick={() => handleUserClick(member)} />
                  <Link to={`/${currentWorkspace.slug}/people/${member.id}`} target="_blank">
                    {member.cached_fullname ?? member.cached_email}
                  </Link>
                </div>
                <div className="progress">
                  <ProgressBarV2
                    percentage={member.initiative_progress_prct * 100}
                    totalCount={member.total_initiatives_count}
                    label={`${member.closed_initiatives_count}/${member.total_initiatives_count}`}
                  />
                </div>
              </LeaderboardRow>
            );
          })}
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default InitiativeProgressLeaderboardWidget;

import queryKeys from 'config/queryKeys';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Initiative, WorkspaceSettings } from 'types';
import * as remoteApi from 'api/remote';
import * as membershipUtils from 'utils/membershipUtils';
import { PanelGrid, PanelHeader } from 'components/GlobalPanel';
import styled from 'styled-components';
import KoalaIconButton from 'koala/components/IconButton';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaCheckbox from 'koala/components/Checkbox';
import KoalaInlineTextInput from 'koala/components/InlineTextInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import DropdownMenu from 'components/DropdownMenu';
import { getWorkStateDropdownData, getWorkStateParams } from 'utils/initiativeUtils';
import UserSelectButton from 'components/UserSelectButton';
import * as integrationUtils from 'utils/integrationUtils';
import InitiativeContributors from './InitiativeContributors';
import TagPickerInitiative from 'components/TagPickerInitiative';
import InitiativeStatus from './InitiativeStatus';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useHotkeys } from 'react-hotkeys-hook';
import ReactTooltip from 'react-tooltip';
import IntegrationInitiative from './IntegrationInitiative';
import InitiativeDueDate from 'components/InitiativeDueDate';
import * as workspaceUtils from 'utils/workspaceUtils';
import InitiativeDescription from './InitiativeDescription';
import OutcomeExpandable from 'components/OutcomeExpandable';
import InitiativeLinksTab from './InitiativeLinksTab';
import ReadOnlyButton from 'components/ReadOnlyButton';
import ShareButton from 'components/ShareButton';
import * as routes from 'routes';

const AvatarLine = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;
const CustomPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomPanelContent = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: auto 40rem;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'description info'
    'comments info';
  overflow-y: auto;

  .panel-tasks {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2};
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  .plan-link {
    font-weight: 600;
    color: ${theme.colors.subtleText};
  }
`;

const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  border-right: 1px solid ${theme.colors.inputBorder};
  padding: 1.2rem ${theme.spacing.x2};
  width: 100%;
  grid-area: comments;
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
  }
`;

const DescriptionColumn = styled(CommentColumn)`
  grid-area: description;
  border-bottom: 1px solid ${theme.colors.N20};
`;

const InfoColumn = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  background: ${theme.colors.N3};
  grid-area: info;
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: ${theme.spacing.x2};
  }
`;

const HeaderActions = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

const InitiativeTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .initiative-closed {
    .content {
      text-decoration: line-through;
    }
  }

  .inline-text {
    font-size: 1.6rem;
  }
`;

const InfoLine = styled.div`
  display: flex;
  font-size: 1.2rem;
  margin-bottom: ${theme.spacing.x2};
  align-items: center;

  &.align-top {
    align-items: start;
  }
  label {
    color: ${theme.colors.subtleText};
    width: 8rem;
    min-width: 8rem;
  }
`;

const ContributorsListing = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const TagPicker = styled.div`
  display: flex;
  width: 100%;

  label {
    display: none;
  }
  .tag-container,
  .tag-select {
    width: 100%;
  }
  .tag-picker {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
  }
`;

const TitleAndAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  initiativeId: string;
}

function NewInitiativePanel(props: Props) {
  const { initiativeId } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.initiative';
  const history = useHistory();
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const [initiative, setInitiative] = useState<Initiative | null>(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentWorkspaceSettings: WorkspaceSettings = useSelector(
    (state: any) => state.session.currentWorkspaceSettings,
    shallowEqual,
  );
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  useHotkeys('C', () => handleUpdateStatus(), { scopes: ['panels'] });
  useHotkeys(
    'E',
    (event: KeyboardEvent) => {
      event.stopImmediatePropagation();
      if (initiative) {
        history.push(`#initiative:${initiative.nano_slug}:edit`);
      }
    },
    { scopes: ['panels'] },
  );

  const staleTime = 0;

  const [updateInitiativeMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
      queryCache.invalidateQueries(queryKeys.initiativeComments);
    },
  });
  const [archiveInitiativeMutation] = useMutation(remoteApi.archiveInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
    },
  });
  const [deleteInitiativeMutation] = useMutation(remoteApi.deleteInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      history.goBack();
    },
  });

  // Get the initiative details
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response) => {
      // Set the params on success
      const initiative: Initiative = response.data;
      setInitiative(initiative);
    },
  });

  const handleCancel = (e: Event) => {
    e.preventDefault();
    history.goBack();
  };

  if (!initiative) {
    return <></>;
  }
  const { plan } = initiative;

  const checked = initiative.state === 'closed';

  const handleCheckboxChange = (e: ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // Toggle the state between open and closed
    const state = initiative.state === 'open' ? 'closed' : 'open';
    const work_state = initiative.state === 'open' ? 'done' : 'backlog';
    const params = {
      state,
      work_state,
    };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const updateTitle = (newTitle: string) => {
    if (!initiative) return;

    const params = {
      title: newTitle,
    };
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: params,
    };
    updateInitiativeMutation(mutationParams);
  };

  const handleInitiativeStateChange = (value: any, initiative: Initiative) => {
    const action = value.props['data-action'];
    if (action === initiative.work_state) {
      return;
    }
    const params = getWorkStateParams(action);
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };

  const filteredStates = getWorkStateDropdownData(t, currentWorkspaceSettings);

  const handleUpdateStatus = () => {
    dispatch(setGlobalModalContent(`initiative:${initiative.id}:work_state`));
  };

  const menuItems = [
    <span key="edit" data-action="edit">
      {t('shared.edit')}
    </span>,
    <span key="move" data-action="move">
      {t('shared.move')}
    </span>,
  ];
  if (initiative.state === 'open') {
    menuItems.push(
      <span key="close" data-action="close">
        {t('shared.close')}
      </span>,
    );
  } else {
    menuItems.push(
      <span key="open" data-action="open">
        {t('shared.reopen')}
      </span>,
    );
  }
  if (!initiative.archived) {
    menuItems.push(
      <span key="archive" data-action="archive">
        {t('shared.archive')}
      </span>,
      <span key="delete" data-action="delete">
        {t('shared.delete')}
      </span>,
    );
  }
  if (initiative.archived) {
    menuItems.push(
      <span key="unarchive" data-action="unarchive">
        {t('shared.unarchive')}
      </span>,
      <span key="delete" data-action="delete">
        {t('shared.delete')}
      </span>,
    );
  }
  menuItems.push(
    <span key="audit-log" data-action="audit-log">
      {t('shared.audit')}
    </span>,
  );

  const closeInitiative = () => {
    const params = {
      state: 'closed',
      work_state: 'done',
    };

    updateInitiativeMutation({
      initiativeId,
      initiative: params,
    });
  };

  const openInitiative = () => {
    const params = {
      state: 'open',
      work_state: 'backlog',
    };

    updateInitiativeMutation({
      initiativeId,
      initiative: params,
    });
  };

  const archiveInitiative = () => {
    if (!initiative) return;

    const params = {
      archived: true,
    };
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: params,
    };
    archiveInitiativeMutation(mutationParams);
  };

  const unArchiveInitiative = () => {
    if (!initiative) return;

    const params = {
      archived: false,
    };
    const mutationParams = {
      initiativeId: initiative.id,
      initiative: params,
    };
    archiveInitiativeMutation(mutationParams);
  };

  const deleteInitiative = () => {
    if (window.confirm(`Delete this ${translate(currentWorkspace, CustomTermKey.INITIATIVE, 1)}?`)) {
      deleteInitiativeMutation(initiativeId);
    }
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    const blockId = `initiative:${initiative.nano_slug}`;
    switch (action) {
      case 'open':
        openInitiative();
        break;
      case 'close':
        closeInitiative();
        break;
      case 'edit':
        const hashPath = `#${blockId}:edit`;
        history.push(hashPath);
        break;
      case 'move':
        dispatch(setGlobalModalContent(`${blockId}:move`));
        break;
      case 'archive':
        archiveInitiative();
        break;
      case 'unarchive':
        unArchiveInitiative();
        break;
      case 'delete':
        deleteInitiative();
        break;
      case 'audit-log':
        dispatch(setGlobalModalContent(`audit:${initiative.nano_slug}:show`));
    }
  };

  const handleAssignUser = (value: string) => {
    const membership_id = value;
    if (membership_id) {
      const params = {
        membership_id,
      };

      updateInitiativeMutation({
        initiativeId: initiative.id,
        initiative: params,
      });
    }
  };

  const handleUnassignUser = () => {
    const params = {
      membership_id: null,
    };
    updateInitiativeMutation({
      initiativeId: initiative.id,
      initiative: params,
    });
  };

  const handleDateChanged = (date: Date) => {
    const params = {
      due_at: date,
    };
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };

  const handleDateCleared = () => {
    const params = {
      due_at: null,
    };
    updateInitiativeMutation({ initiativeId: initiative.id, initiative: params });
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent(`initiative:${initiativeId}:share`));
  };

  const integrationType = integrationUtils.getIntegrationType(initiative);
  const isIntegration = integrationType !== 'Tability';
  const { membership } = initiative;

  if (isIntegration) {
    return <IntegrationInitiative initiative={initiative} />;
  }

  const allowDateEdit =
    !isIntegration &&
    (workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace?.pricing_version >= 2);

  const link = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', plan.nano_slug)}#initiative:${initiative.nano_slug}:show`;

  return (
    <PanelGrid>
      <PanelHeader>
        <CustomPanelHeader>
          <HeaderTitle>
            <Link className="plan-link hide-mobile" to={`/${currentWorkspace.slug}/plans/${plan.nano_slug}/outcomes`}>
              <PlanIconLabel plan={plan} size="small" />
            </Link>
          </HeaderTitle>
          <HeaderActions>
            <ShareButton shareCallback={handleShare} id={initiative.id} link={link} />
            {!isReadOnly && (
              <DropdownMenu
                trigger={<KoalaIconButton iconName="ellipsis" />}
                onSelection={handleMenuSelection}
                items={menuItems}
              />
            )}
            <KoalaIconButton iconName="close" onClick={handleCancel} />
          </HeaderActions>
        </CustomPanelHeader>
        <InitiativeTitle>
          <KoalaCheckbox
            disabled={isReadOnly}
            checked={checked}
            handleChange={handleCheckboxChange}
            loading={isUpdating}
          />
          <KoalaInlineTextInput
            text={initiative.title}
            placeholder={`Enter ${translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase()}`}
            handleChangeText={updateTitle}
            canEdit={!isReadOnly}
            className={`initiative-${initiative.state}`}
            hideOverflow={false}
            isUpdating={isUpdating}
          />
          {initiative.archived && (
            <KoalaTextBadge className="archive-label" isLowercase={true} variant="violet-light">
              {t('shared.status.archived')}
            </KoalaTextBadge>
          )}
        </InitiativeTitle>
      </PanelHeader>
      <CustomPanelContent>
        <DescriptionColumn>
          <h4>
            {t('shared.related')} {translate(currentWorkspace, CustomTermKey.OUTCOME, 1)}
          </h4>
          <OutcomeExpandable
            outcome={initiative.outcome}
            hideExpand={true}
            hideActions={true}
            hideOwners={true}
            hideTags={true}
          />
          <h4>{t(`${translationKey}.description`)}</h4>
          <InitiativeDescription initiative={initiative} isReadOnly={isReadOnly} />
        </DescriptionColumn>
        <CommentColumn>
          <TitleAndAction>
            <h4>{t(`${translationKey}.statusUpdates`)}</h4>
            {isReadOnly && (
              <div>
                <ReadOnlyButton label={t(`${translationKey}.createNewStatus`)} appearance="primary" />
              </div>
            )}
            {!isReadOnly && (
              <>
                <KoalaButton dataFor="new-initiative-status" onClick={handleUpdateStatus}>
                  {t(`${translationKey}.createNewStatus`)}
                </KoalaButton>

                <ReactTooltip type="dark" id="new-initiative-status" className="tooltip" effect="solid" delayShow={200}>
                  {t(`${translationKey}.statusTooltip`, {
                    initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
                  })}
                </ReactTooltip>
              </>
            )}
          </TitleAndAction>
          <InitiativeStatus initiative={initiative} />
        </CommentColumn>
        <InfoColumn>
          {integrationType !== 'Tability' && (
            <InfoLine>
              <label>{t(`${translationKey}.link`)}</label>
              <KoalaButton appearance="subtle" size="small" href={initiative.title} target="_blank">
                {t(`${translationKey}.viewLink`, { label: integrationType })}
              </KoalaButton>
            </InfoLine>
          )}
          <InfoLine>
            <label>{t(`${translationKey}.status`)}</label>
            {isReadOnly && <InitiativeWorkStatus initiative={initiative} showArrow={false} />}
            {!isReadOnly && (
              <DropdownMenu
                trigger={<InitiativeWorkStatus initiative={initiative} showArrow={true} />}
                items={filteredStates}
                onSelection={(e: any) => handleInitiativeStateChange(e, initiative)}
              />
            )}
          </InfoLine>
          <InfoLine>
            <label>{t(`${translationKey}.owner`)}</label>
            <AvatarLine>
              <UserSelectButton
                canEdit={!isReadOnly}
                handleAssignUser={handleAssignUser}
                selectedUser={initiative.membership ?? null}
                handleUnassignUser={initiative.membership ? handleUnassignUser : undefined}
                size="small"
              />
              <span>{membership ? membership.user.fullname || membership.user.email : 'Not assigned'}</span>
            </AvatarLine>
          </InfoLine>
          <InfoLine>
            <label>{t(`${translationKey}.contributors`)}</label>
            <ContributorsListing>
              <InitiativeContributors initiativeId={initiative.id} isReadOnly={isReadOnly} />
            </ContributorsListing>
          </InfoLine>
          <InfoLine>
            <label>{t(`panels.dueDate`)}</label>
            <ContributorsListing>
              <InitiativeDueDate
                dueAt={initiative.due_at}
                state={initiative.state}
                dataFor={`${initiative.nano_slug}-dueDate`}
                handleDateChange={allowDateEdit && !isReadOnly ? handleDateChanged : undefined}
                handleDateClear={allowDateEdit && !isReadOnly ? handleDateCleared : undefined}
              />
            </ContributorsListing>
          </InfoLine>
          {!isReadOnly && (
            <InfoLine>
              <label>{t(`${translationKey}.tags`)}</label>
              <TagPicker>
                <TagPickerInitiative initiative={initiative} />
              </TagPicker>
            </InfoLine>
          )}
          <h4>{t(`panels.shared.links`)}</h4>
          <InitiativeLinksTab initiative={initiative} isReadOnly={isReadOnly} />
        </InfoColumn>
      </CustomPanelContent>
    </PanelGrid>
  );
}

export default React.memo(NewInitiativePanel);

import React, { useState } from 'react';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

import { useQuery, useMutation, useQueryCache } from 'react-query';

import * as remoteApi from 'api/remote';
import { Outcome, OutcomeRelationship, Relationship, Objective } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaLoader from 'koala/components/Loader';
import RelatedOutcome from 'components/RelatedOutcome';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PlanIconLabel from 'components/PlanIconLabel';

interface Props {
  outcome: Outcome;
  hasEditPermission: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  header {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ${theme.spacing.x1};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
`;

const OutcomesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;

const ObjectivesContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  font-size: 1.2rem;
  color: ${theme.colors.subtleText};
  a {
    color: ${theme.colors.subtleText};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Line = styled.div`
  display: flex;
  position: relative;
  gap: ${theme.spacing.x1};
  justify-content: space-between;

  .action {
    opacity: 0.2;
    transition: all ease 0.2s;
  }

  &:hover {
    .action {
      opacity: 1;
    }
  }
`;

const ObjectiveLabel = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  padding-top: ${theme.spacing.x1};
  .icon--custom {
    width: 2rem;
    height: 2rem;
  }
`;

function AlignmentAccordion(props: Props) {
  const { outcome, hasEditPermission } = props;

  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'panels.outcome';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  let relationships_count = 0;
  const [legacyParents, setLegacyParents] = useState<OutcomeRelationship[]>([]);
  const [legacyRelated, setLegacyRelated] = useState<OutcomeRelationship[]>([]);
  const [legacyContributing, setLegacyContributing] = useState<OutcomeRelationship[]>([]);

  const [parentOutcomes, setParentOutcomes] = useState<Relationship[]>([]);
  const [relatedOutcomes, setRelatedOutcomes] = useState<Relationship[]>([]);
  const [contributingOutcomes, setContributingOutcomes] = useState<Relationship[]>([]);

  const [parentObjectives, setParentObjectives] = useState<Relationship[]>([]);
  const [relatedObjectives, setRelatedObjectives] = useState<Relationship[]>([]);
  const [contributingObjectives, setContributingObjectives] = useState<Relationship[]>([]);

  const queryKey = [queryKeys.outcomeRelationships, outcome?.id];
  const staleTime = queryKeys.staleTime;

  // Functions to destroy the relationship
  const [deleteOutcomeRelationshipMutation, { isLoading: isDeleting }] = useMutation(
    remoteApi.deleteOutcomeRelationship,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.outcomeRelationships);
      },
    },
  );

  const handleDeleteOutcomeRelationship = (outcomeRelationshipId: string) => {
    deleteOutcomeRelationshipMutation(outcomeRelationshipId);
  };

  // Functions to destroy the relationship
  const [deleteRelationshipMutation, { isLoading: isDeletingRelationship }] = useMutation(
    remoteApi.deleteRelationship,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.relationships);
      },
    },
  );

  const handleDeleteRelationship = (relationshipId: string) => {
    deleteRelationshipMutation(relationshipId);
  };

  // Get the outcomes
  const { isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeRelationships, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomeRelationships: OutcomeRelationship[] = response.data;
      const legacyParents: OutcomeRelationship[] = [];
      const legacyRelated: OutcomeRelationship[] = [];
      const legacyContributing: OutcomeRelationship[] = [];
      outcomeRelationships.forEach((outcomeRelationship) => {
        if (
          outcomeRelationship.outcome_source_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          legacyParents.push(outcomeRelationship);
        }
        if (outcomeRelationship.relationship_type === 'relates_to') {
          legacyRelated.push(outcomeRelationship);
        }
        if (
          outcomeRelationship.outcome_target_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          legacyContributing.push(outcomeRelationship);
        }
      });
      setLegacyParents(legacyParents);
      setLegacyRelated(legacyRelated);
      setLegacyContributing(legacyContributing);
    },
  });

  const queryKeyOutcomes = [queryKeys.relationships, outcome?.id, 'outcomes'];

  const { isLoading: isLoadingOutcomes } = useQuery(queryKeyOutcomes, remoteApi.fetchRelationshipsForOutcome, {
    staleTime,
    onSuccess: (response: any) => {
      const relationships: Relationship[] = response.data;

      const parentOutcomes: Relationship[] = [];
      const relatedOutcomes: Relationship[] = [];
      const contributingOutcomes: Relationship[] = [];
      relationships.forEach((relationship) => {
        if (relationship.source_id === outcome.id && relationship.relationship_type === 'contributes_to') {
          parentOutcomes.push(relationship);
        }
        if (relationship.relationship_type === 'relates_to') {
          relatedOutcomes.push(relationship);
        }
        if (relationship.target_id === outcome.id && relationship.relationship_type === 'contributes_to') {
          contributingOutcomes.push(relationship);
        }
      });
      setParentOutcomes(parentOutcomes);
      setRelatedOutcomes(relatedOutcomes);
      setContributingOutcomes(contributingOutcomes);
    },
  });

  const queryKeyObjectives = [queryKeys.relationships, outcome?.id, 'objectives'];

  const { isLoading: isLoadingObjectives } = useQuery(queryKeyObjectives, remoteApi.fetchRelationshipsForOutcome, {
    staleTime,
    onSuccess: (response: any) => {
      const relationships: Relationship[] = response.data;

      const parentObjectives: Relationship[] = [];
      const relatedObjectives: Relationship[] = [];
      const contributingObjectives: Relationship[] = [];
      relationships.forEach((relationship) => {
        if (relationship.source_id === outcome.id && relationship.relationship_type === 'contributes_to') {
          parentObjectives.push(relationship);
        }
        if (relationship.relationship_type === 'relates_to') {
          relatedObjectives.push(relationship);
        }
        if (relationship.target_id === outcome.id && relationship.relationship_type === 'contributes_to') {
          contributingObjectives.push(relationship);
        }
      });
      setParentObjectives(parentObjectives);
      setRelatedObjectives(relatedObjectives);
      setContributingObjectives(contributingObjectives);
    },
  });

  if (isLoading || isLoadingOutcomes || isLoadingObjectives) {
    return <KoalaLoader />;
  }

  relationships_count =
    legacyParents?.length +
    legacyRelated?.length +
    legacyContributing?.length +
    parentOutcomes?.length +
    relatedOutcomes?.length +
    contributingOutcomes?.length +
    parentObjectives?.length +
    relatedObjectives?.length +
    contributingObjectives?.length;

  if (relationships_count === 0) {
    return <p className="subtle">{t(`${translationKey}.noDependencies`)}</p>;
  }

  const DisplayLegacyOutcome = ({
    outcome,
    aligned = false,
    outcomeRelationshipId,
  }: {
    outcome: Outcome;
    aligned?: boolean;
    outcomeRelationshipId: string;
  }) => {
    const dataId = `relationship:${outcome.id}`;
    const { plan, objective } = outcome;
    return (
      <>
        <Line data-tip data-for={dataId}>
          <RelatedOutcome outcome={outcome} aligned={aligned} />
          {hasEditPermission && (
            <div className="action">
              {isDeleting && <KoalaLoader />}
              {!isDeleting && (
                <KoalaIconButton
                  onClick={() => handleDeleteOutcomeRelationship(outcomeRelationshipId)}
                  iconName="close"
                  size="xsmall"
                />
              )}
            </div>
          )}
        </Line>
        <ReactTooltip type="dark" id={dataId} className="tooltip" effect="solid" delayShow={400}>
          <PlanIconLabel plan={plan} size="small" />
          <ObjectiveLabel>
            <KoalaIcon iconName="flag" iconSize="small" iconAppearance={theme.colors.T80} />
            {objective.title}
          </ObjectiveLabel>
        </ReactTooltip>
      </>
    );
  };

  const DisplayOutcome = ({
    outcome,
    aligned = false,
    relationshipId,
  }: {
    outcome: Outcome;
    aligned?: boolean;
    relationshipId: string;
  }) => {
    const dataId = `relationship:${outcome.id}`;
    const { plan, objective } = outcome;
    return (
      <>
        <Line data-tip data-for={dataId}>
          <RelatedOutcome outcome={outcome} aligned={aligned} />
          {hasEditPermission && (
            <div className="action">
              {isDeletingRelationship && <KoalaLoader />}
              {!isDeletingRelationship && (
                <KoalaIconButton
                  onClick={() => handleDeleteRelationship(relationshipId)}
                  iconName="close"
                  size="xsmall"
                />
              )}
            </div>
          )}
        </Line>
        <ReactTooltip type="dark" id={dataId} className="tooltip" effect="solid" delayShow={400}>
          <PlanIconLabel plan={plan} size="small" />
          <ObjectiveLabel>
            <KoalaIcon iconName="flag" iconSize="small" iconAppearance={theme.colors.T80} />
            {objective.title}
          </ObjectiveLabel>
        </ReactTooltip>
      </>
    );
  };

  const DisplayObjective = ({
    objective,
    aligned = false,
    relationshipId,
  }: {
    objective: Objective;
    aligned?: boolean;
    relationshipId: string;
  }) => {
    const dataId = `relationship:${objective.id}`;
    return (
      <>
        <Line data-tip data-for={dataId}>
          <ObjectivesContainer>
            <KoalaIcon iconName="flag" iconSize="xsmall" iconAppearance={theme.colors.T80} />
            <Link to={`#objective:${objective.nano_slug}:show`}>{objective.title}</Link>
          </ObjectivesContainer>
          {hasEditPermission && (
            <div className="action">
              {isDeletingRelationship && <KoalaLoader />}
              {!isDeletingRelationship && (
                <KoalaIconButton
                  onClick={() => handleDeleteRelationship(relationshipId)}
                  iconName="close"
                  size="xsmall"
                />
              )}
            </div>
          )}
        </Line>
        <ReactTooltip type="dark" id={dataId} className="tooltip" effect="solid" delayShow={400}>
          <PlanIconLabel plan={objective.plan} size="small" />
        </ReactTooltip>
      </>
    );
  };

  return (
    <Container>
      {(legacyParents?.length > 0 || parentOutcomes?.length > 0 || parentObjectives?.length > 0) && (
        <div>
          <header>
            {t(`${translationKey}.parent`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, legacyParents?.length),
            })}
          </header>
          <OutcomesContainer>
            {parentObjectives.map((relationship) => {
              const objective = relationship.target as Objective;
              return <DisplayObjective objective={objective} relationshipId={relationship.id} />;
            })}
            {legacyParents.map((outcomeRelationship) => {
              const outcome = outcomeRelationship.outcome_target;
              return <DisplayLegacyOutcome outcome={outcome} outcomeRelationshipId={outcomeRelationship.id} />;
            })}
            {parentOutcomes.map((relationship) => {
              const outcome = relationship.target as Outcome;
              return <DisplayOutcome outcome={outcome} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {(legacyContributing?.length > 0 || contributingOutcomes?.length > 0 || contributingObjectives?.length > 0) && (
        <div>
          <header>
            <KoalaIcon iconName="alignmentArrow" iconSize="xsmall" iconAppearance="custom" />
            {t(`${translationKey}.aligned`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, legacyContributing?.length),
            })}
          </header>
          <OutcomesContainer>
            {contributingObjectives.map((relationship) => {
              const objective = relationship.source as Objective;
              return <DisplayObjective objective={objective} aligned={true} relationshipId={relationship.id} />;
            })}
            {legacyContributing.map((outcomeRelationship) => {
              const outcome = outcomeRelationship.outcome_source;
              return (
                <DisplayLegacyOutcome outcome={outcome} aligned={true} outcomeRelationshipId={outcomeRelationship.id} />
              );
            })}
            {contributingOutcomes.map((relationship) => {
              const outcome = relationship.source as Outcome;
              return <DisplayOutcome outcome={outcome} aligned={true} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {(legacyRelated?.length > 0 || relatedOutcomes?.length > 0 || relatedObjectives?.length > 0) && (
        <div>
          <header>
            {t(`${translationKey}.related`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
          </header>
          <OutcomesContainer>
            {relatedObjectives.map((relationship) => {
              let objective;
              if (relationship.source_id === outcome.id) {
                objective = relationship.target as Objective;
              } else {
                objective = relationship.source as Objective;
              }
              return <DisplayObjective objective={objective} aligned={true} relationshipId={relationship.id} />;
            })}
            {legacyRelated.map((outcomeRelationship) => {
              let outcome;
              if (outcomeRelationship.outcome_source_id === props.outcome.id) {
                outcome = outcomeRelationship.outcome_target;
              } else {
                outcome = outcomeRelationship.outcome_source;
              }
              return <DisplayLegacyOutcome outcome={outcome} outcomeRelationshipId={outcomeRelationship.id} />;
            })}
            {relatedOutcomes.map((relationship) => {
              let outcome;
              if (relationship.source_id === props.outcome.id) {
                outcome = relationship.target as Outcome;
              } else {
                outcome = relationship.source as Outcome;
              }
              return <DisplayOutcome outcome={outcome} relationshipId={relationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
    </Container>
  );
}

export default AlignmentAccordion;

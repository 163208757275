import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import KoalaConfidenceBlock from 'koala/components/ConfidenceBlock';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import { format } from 'date-fns';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'react-redux';
import CurrentGrowth from 'components/CurrentGrowth';
import OutcomeCurrentValueBar from 'components/OutcomeCurrentValueBar';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import Initiatives from './Initiatives';
import { Link } from 'react-router-dom';
import * as routes from 'routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  cursor: pointer;

  &.expanded {
    min-height: 400px;
  }
`;

const DividerBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.N20};
`;

const SectionContainer = styled.div<{ isRow?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  justify-content: space-between;
  gap: ${theme.spacing.half};
  padding: ${theme.spacing.x2};

  .plan-link {
    color: ${theme.colors.subtleText};
    &:hover {
      text-decoration: underline;
    }
  }

  .outcome-link {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ScoreSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.x1};

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ScoreText = styled.h2<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;

const MetaContentLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: ${theme.spacing.x1};
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.N3};
  }
  padding: ${theme.spacing.x1};
`;

const InitiativeSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &.expanded {
    overflow: hidden;

    .icon-btn--square {
      transform: rotate(90deg);
    }
  }
`;

const DifferenceBlock = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  flex: 1;
  flex-direction: column;
`;

const DifferenceRow = styled.div`
  flex: 1;
  display: flex;
`;

interface Props {
  outcome: Outcome;
  dataExpanded: boolean;
  toggleDataExpanded: (value: React.MouseEvent<HTMLDivElement>) => void;
  handleOutcomeClicked: (e: React.MouseEvent<HTMLDivElement>, outcomeSlug: string) => void;
}

function OutcomeContent(props: Props) {
  const { outcome, toggleDataExpanded, dataExpanded, handleOutcomeClicked } = props;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const { current_checkin, from, score_format, previous_checkin, plan } = outcome;

  // Split score format into numeric part and text part
  let formattedScore = '';
  //  if there's a current checkin, use the score
  if (current_checkin) {
    formattedScore = checkinUtils.checkinScore(current_checkin, outcome, t);
    // if there's no current checkin and has metric, use the starting value
  } else if (outcome.outcome_type !== 'no_metric') {
    formattedScore = from !== null ? outcomeUtils.nFormatter(from, 2) : '';
    formattedScore = outcomeUtils.applyFormat(score_format, formattedScore);
    // if there's no current checkin and no metric, show pending
  } else {
    formattedScore = t('shared.pending');
  }

  const currentColor = current_checkin ? current_checkin.confidence : 'grey';
  const textColor = checkinUtils.confidenceToValueColor(currentColor);

  const expandedClass = (itemState: boolean) => {
    if (itemState) {
      return 'expanded';
    }
    return '';
  };

  const handleToggleInitiatives = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleDataExpanded(e);
  };

  const onOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    handleOutcomeClicked?.(e, outcome.nano_slug);
  };

  // Plan link
  let routeTarget = '';
  let planRoute = '';
  if (plan) {
    routeTarget = plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
    planRoute = routeTarget.replace(':workspaceSlug', currentWorkspace.slug).replace(':planId', plan.nano_slug);
  }

  const hashPath = `#outcome:${outcome.nano_slug}:show`;

  return (
    <Container className={expandedClass(dataExpanded)} onClick={onOutcomeClick}>
      <SectionContainer>
        <Link to={planRoute} onClick={(e) => e.stopPropagation()} className="plan-link">
          <small>
            <PlanIconLabel plan={outcome.plan} size="xsmall" />
          </small>
        </Link>
        <HeaderRow>
          {outcome.outcome_type !== 'kpi' && (
            <KoalaConfidenceBlock
              currentConfidence={outcome.current_checkin?.confidence}
              className="outcome-card-confidence"
            />
          )}
          <Link to={hashPath} onClick={(e) => e.stopPropagation()} className="outcome-link">
            {outcome.title}
          </Link>
        </HeaderRow>
      </SectionContainer>

      <DividerBlock />

      <SectionContainer isRow>
        <ScoreSection>
          {current_checkin ? (
            <small>
              {t('components.outcomeCard.latest')} ({format(new Date(current_checkin.created_at), 'dd MMM')})
            </small>
          ) : (
            <small>{t('components.outcomeCard.pending')}</small>
          )}
          <ScoreText textColor={textColor}>{formattedScore}</ScoreText>
        </ScoreSection>
        <KoalaAvatar tooltipType="none" membership={outcome.membership} />
      </SectionContainer>

      <DividerBlock />

      {previous_checkin && (
        <>
          <SectionContainer isRow>
            <DifferenceBlock>
              <small>Progress</small>
              <OutcomeCurrentValueBar
                outcome={outcome}
                checkin={outcome.current_checkin}
                showPercentageProgress={true}
              />
            </DifferenceBlock>
            <DifferenceBlock>
              <small>{t('components.outcomeCard.change')}</small>
              <DifferenceRow>
                <CurrentGrowth outcome={outcome} />
              </DifferenceRow>
            </DifferenceBlock>
            <DifferenceBlock>
              <small>Updated</small>
              <DifferenceRow>
                <small className="subtle">
                  {current_checkin
                    ? formatDistanceToNowLocale(current_checkin.checkin_date, i18n, true, true)
                    : 'pending'}
                </small>
              </DifferenceRow>
            </DifferenceBlock>
          </SectionContainer>
        </>
      )}
      {!previous_checkin && (
        <SectionContainer>
          <small>Pending update</small>
        </SectionContainer>
      )}

      <DividerBlock />
      <InitiativeSection className={expandedClass(dataExpanded)}>
        <MetaContentLine onClick={handleToggleInitiatives}>
          <KoalaIconButton iconName="rightChevron" size="xsmall" edge="square" onClick={handleToggleInitiatives} />
          <small>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</small>
        </MetaContentLine>
        {dataExpanded && <Initiatives outcome={outcome} hideClosedInitiatives={false} />}
      </InitiativeSection>
    </Container>
  );
}

export default React.memo(OutcomeContent);

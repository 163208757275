import React from 'react';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, LeaderboardRow, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { DashboardWidget, Outcome, Workspace } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import KoalaLoader from 'koala/components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaColorBlock from 'koala/components/ColorBlock';
import { confidenceToColor } from 'utils/checkinUtils';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

const OutcomeTitle = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function MostViewedOutcomeWidget(props: Props) {
  const { widget } = props;
  const history = useHistory();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const direction = widget.widget_meta.order ?? 'desc';
  const queryKey = [queryKeys.dashboardWidgets, widget.id, 'mostViewedOutcome', direction];
  const { data: dataResponse, isLoading } = useQuery(queryKey, remoteApi.fetchMostViewedOutcomeWidgetDetails);
  const outcomes: Outcome[] = dataResponse ? dataResponse.data : [];

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.mostViewedOutcome', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
      });

  const handleRowClicked = (outcome: Outcome) => {
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        {isLoading && <KoalaLoader />}
        <Container className="panel-checkins-chart">
          {outcomes.length === 0 && <span>{t('workspaceDashboards.noActivity')}</span>}
          {outcomes.map((outcome, index) => {
            if (outcome.views_count === 0) {
              return <></>;
            }
            return (
              <LeaderboardRow key={index}>
                <div className="number">{index + 1}</div>
                <OutcomeTitle className="profile" onClick={() => handleRowClicked(outcome)}>
                  <KoalaColorBlock color={confidenceToColor(outcome.current_checkin?.confidence)} />
                  {outcome.title}
                </OutcomeTitle>
                <span>{outcome.views_count}</span>
              </LeaderboardRow>
            );
          })}
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default MostViewedOutcomeWidget;

import React, { useState } from 'react';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { DashboardWidget } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import * as routes from 'routes';
import { Base64 } from 'js-base64';
import KoalaLoader from 'koala/components/Loader';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;
  flex-direction: column;
  align-items: center;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }

  :hover {
    cursor: pointer;
    span {
      text-decoration: underline;
    }
  }
`;

const LargeNumber = styled.div`
  font-size: 8rem;
`;

interface Props {
  widget: DashboardWidget;
}

function OverdueCheckinUserCountWidget(props: Props) {
  const { widget } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const [count, setCount] = useState<number>();

  const filterParams = { is_in_progress: true, with_pending_checkin: true };
  // Construct the query key using the plan Id
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];

  // Get the plan details
  const { isLoading, isFetching } = useInfiniteQuery(queryKey, remoteApi.searchOutcomes, {
    onSuccess: (data: any) => {
      const resultsCount = parseInt(data[0].headers['x-total']);
      setCount(resultsCount);
    },
  });

  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.overdueCheckinUser');

  const handleViewOutcomes = () => {
    const insightsWithPendingCheckin = Base64.encode(
      '{"plan_status":"is_in_progress","sorting":"plan","insights":"with_pending_checkin"}',
    );
    const link = routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
      ':filter',
      insightsWithPendingCheckin,
    );
    window.open(link, '_blank');
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        {isLoading && isFetching && <KoalaLoader />}
        <Container className="panel-checkins-chart" onClick={handleViewOutcomes}>
          <LargeNumber>{count}</LargeNumber>
          <span>{t('workspaceDashboards.overdueCheckinUserDetails')}</span>
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default OverdueCheckinUserCountWidget;

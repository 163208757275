import queryKeys from 'config/queryKeys';
import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { User, Membership, Workspace } from 'types';
import * as remoteApi from 'api/remote';
import KoalaIcon from 'koala/components/Icons';
import { shallowEqual, useSelector } from 'react-redux';

import { Menu, MenuItem } from 'react-aria-menubutton';
import Loader from 'components/Loader';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import UserRow from './UserRow';

const UserSelection = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 3.2rem 1fr;
  grid-template-areas: 'icon text';
  align-items: center;
`;

const UserSelectionIcon = styled.div`
  grid-area: icon;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const UserSelectionText = styled.div`
  margin-left: ${theme.spacing.x1};
  grid-area: text;
  span {
    word-break: break-word;
  }
`;

interface Props {
  ignoredMemberships?: string[];
  canUnassign?: boolean;
}

function UserItems(props: Props) {
  const { ignoredMemberships, canUnassign } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const { t } = useTranslation();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);

  const workspaceMemberships: Membership[] = useSelector((state: any) => state.session.workspaceMemberships);
  const [nameToSearch, setNameToSearch] = useState<string | null>(null);
  const [searchedMemberships, setSearchedMemberships] = useState<Membership[]>([]);
  const [recentVisits, setRecentVisits] = useState<Membership[]>([]);
  const searchMembershipsQueryKey = [
    queryKeys.memberships,
    workspaceSlug,
    {
      name: nameToSearch,
      per_page: 10,
    },
  ];

  const staleTime = 0;

  const { isFetching: isFetchingMemberships } = useQuery(
    searchMembershipsQueryKey,
    remoteApi.fetchWorkspaceMemberships,
    {
      staleTime,
      onSuccess: (response) => {
        setSearchedMemberships(response.data);
      },
      enabled: nameToSearch !== null && nameToSearch !== '',
    },
  );

  const recentVisitsQueryKey = [queryKeys.recentVisits, workspaceSlug, 0, 'Membership'];
  useQuery(recentVisitsQueryKey, remoteApi.fetchRecentVisits, {
    onSuccess: (response) => {
      const recentVisits: any[] = response.data;
      const memberships: Membership[] = recentVisits.map((recentVisit) => recentVisit.visited);
      setRecentVisits(memberships);
    },
    staleTime,
  });

  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  let users = [];

  // if searching for user
  if (nameToSearch) {
    searchedMemberships.forEach((membership: Membership) => {
      if (ignoredMemberships && ignoredMemberships.includes(membership.id)) {
        return; // Ignore the ignoredMemberships
      } else {
        users.push(<UserRow membership={membership} data-action={membership.id} />);
      }
    });
  } else {
    // if not searching for user, show current user, recent visits then show others
    // add current user
    if (!ignoredMemberships || (ignoredMemberships && !ignoredMemberships.includes(currentMembership.id))) {
      users.push(
        <UserRow membership={{ ...currentMembership, user: currentUser }} data-action={currentMembership.id} />,
      );
    }
    // add recent visits
    recentVisits.forEach((membership: Membership) => {
      if (ignoredMemberships && ignoredMemberships.includes(membership.id)) {
        return; // Ignore the ignoredMemberships
      } else if (membership.id === currentMembership.id) {
        return; // Ignores current user
      } else {
        users.push(<UserRow membership={membership} data-action={membership.id} />);
      }
    });
    // fill in with workspace memberships
    const remainingSpace = 10 - users.length;
    if (remainingSpace > 0) {
      workspaceMemberships.forEach((membership: Membership) => {
        if (ignoredMemberships && ignoredMemberships.includes(membership.id)) {
          return; // Ignore the ignoredMemberships
        } else if (recentVisits.some((visit) => visit.id === membership.id)) {
          return; // Skip if in recent visits
        } else if (currentMembership.id === membership.id) {
          return; // Skip if current user
        } else if (users.length < 10) {
          users.push(<UserRow membership={membership} data-action={membership.id} />);
        }
      });
    }
  }

  if (canUnassign) {
    users.push(
      <UserSelection data-action={'unassign'}>
        <UserSelectionIcon>
          <KoalaIcon iconName="close" />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.userSelect.unassign')}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  // only show add user option if correct permissions
  if (isAdmin || !currentWorkspace.invites_restricted) {
    users.push(
      <UserSelection data-action={'invite user'}>
        <UserSelectionIcon>
          <KoalaIcon iconName="plus" />
        </UserSelectionIcon>
        <UserSelectionText>
          <span className="owner-option-name">{t('shared.userSelect.invite')}</span>
        </UserSelectionText>
      </UserSelection>,
    );
  }

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  return (
    <>
      <input type="text" onChange={handleSearch} placeholder={t('shared.userSelect.search') ?? 'Search user'} />
      <ul>
        {isFetchingMemberships && (
          <li className="AriaMenuButton-menuItemWrapper">
            <MenuItem className="AriaMenuButton-menuItem">
              <Loader />
            </MenuItem>
          </li>
        )}
        {!isFetchingMemberships &&
          users.map((item, index) => (
            <li className="AriaMenuButton-menuItemWrapper" key={index}>
              <MenuItem className="AriaMenuButton-menuItem">{item}</MenuItem>
            </li>
          ))}
      </ul>
    </>
  );
}

// We need this function to make sure that we don't trigger any useless membership search.
// We'll only display the menu content (and execute related code) once it's opened.
function MenuItems(props: Props) {
  const menuContent = (menuState: any) => {
    if (menuState.isOpen) {
      // @ts-ignore
      return <UserItems {...props} />;
    } else {
      return null;
    }
  };

  // @ts-ignore
  return <Menu className="AriaMenuButton-menu">{menuContent}</Menu>;
}

export default React.memo(MenuItems);

import React from 'react';
import styled from 'styled-components';
import ObjectiveListIcon from './assets/ObjectiveListIcon.png';
import OutcomeListIcon from './assets/OutcomeListIcon.png';
import SummaryIcon from './assets/SummaryIcon.png';
import OutcomeSummaryIcon from './assets/OutcomeSummaryIcon.png';
import InitiativeSummaryIcon from './assets/InitiativeSummaryIcon.png';
import UserProfileIcon from './assets/UserProfileIcon.png';
import OutcomeStatsIcon from './assets/OutcomeStatsIcon.png';
import OutcomeDistIcon from './assets/OutcomeDistIcon.png';
import PlanNCSIcon from './assets/PlanNCSIcon.png';
import PlanConfidenceIcon from './assets/PlanConfidenceIcon.png';
import OutcomeTaskIcon from './assets/OutcomeTaskIcon.png';
import InitiativeListIcon from './assets/InitiativeListIcon.png';
import TextBlockIcon from './assets/TextBlockIcon.png';
import LeaderboardIcon from './assets/LeaderboardIcon.png';
import CountIcon from './assets/CountIcon.png';
import OutcomeGraphIcon from './assets/OutcomeGraphIcon.png';
import theme from 'theme';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const Container = styled.div<{ isClickable: boolean }>`
  border: 1px solid ${theme.colors.N20};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  height: 143px;
  width: 183px;

  :hover {
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
    background-color: ${(props) => (props.isClickable ? theme.colors.N3 : 'transparent')};
  }

  :active,
  :focus {
    background-color: ${(props) => (props.isClickable ? theme.colors.B5 : 'transparent')};
  }
`;

const CardImageBlock = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 9.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.x2} ${theme.spacing.x2} 0px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const CardTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface WidgetOptionCardProps {
  type: string;
  handleClick?: (type: string) => void;
}

function WidgetOptionCard(props: WidgetOptionCardProps) {
  const { type, handleClick } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  let imageSrc,
    title,
    backgroundColor = '';
  switch (type) {
    case 'plan-summary':
      imageSrc = SummaryIcon;
      title = t('workspaceDashboards.planSummary');
      backgroundColor = theme.colors.G5;
      break;
    case 'objective-summary':
      imageSrc = SummaryIcon;
      title = t('workspaceDashboards.objectiveSummary', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      });
      backgroundColor = theme.colors.T10;
      break;
    case 'outcome-summary':
      imageSrc = OutcomeSummaryIcon;
      title = t('workspaceDashboards.outcomeSummary', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'initiative-summary':
      imageSrc = InitiativeSummaryIcon;
      title = t('workspaceDashboards.initiativeSummary', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      });
      backgroundColor = theme.colors.B5;
      break;
    case 'user-profile':
      imageSrc = UserProfileIcon;
      title = t('workspaceDashboards.userProfile');
      backgroundColor = theme.colors.P10;
      break;
    case 'objective-list':
      imageSrc = ObjectiveListIcon;
      title = t('workspaceDashboards.objectiveList', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      });
      backgroundColor = theme.colors.T10;
      break;
    case 'outcome-list':
      imageSrc = OutcomeListIcon;
      title = t('workspaceDashboards.outcomeList', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'outcome-stats':
      imageSrc = OutcomeStatsIcon;
      title = t('shared.ncs.full');
      backgroundColor = theme.colors.Y10;
      break;
    case 'outcome-progress':
      imageSrc = OutcomeStatsIcon;
      title = t('workspaceDashboards.outcomeProgress', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'outcome-distribution':
      imageSrc = OutcomeDistIcon;
      title = t('workspaceDashboards.outcomeDistribution', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'plan-ncs-graph':
      imageSrc = PlanNCSIcon;
      title = t('workspaceDashboards.PlanNCSGraph');
      backgroundColor = theme.colors.G5;
      break;
    case 'plan-confidence-graph':
      imageSrc = PlanConfidenceIcon;
      title = t('workspaceDashboards.PlanConfidenceGraph');
      backgroundColor = theme.colors.G5;
      break;
    case 'outcome-task-graph':
      imageSrc = OutcomeTaskIcon;
      title = t('workspaceDashboards.OutcomeTaskGraph', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      });
      backgroundColor = theme.colors.G5;
      break;
    case 'initiative-list':
      imageSrc = InitiativeListIcon;
      title = t('workspaceDashboards.initiativeList', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      });
      backgroundColor = theme.colors.B5;
      break;
    case 'outcome-graph':
      imageSrc = OutcomeGraphIcon;
      title = t('workspaceDashboards.OutcomeGraph', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'text-block':
      imageSrc = TextBlockIcon;
      title = t('workspaceDashboards.textBlock');
      backgroundColor = theme.colors.P10;
      break;
    case 'header-block':
      imageSrc = TextBlockIcon;
      title = t('workspaceDashboards.headerBlock');
      backgroundColor = theme.colors.P10;
      break;
    case 'active-users':
      imageSrc = CountIcon;
      title = t('workspaceDashboards.activeUsers');
      backgroundColor = theme.colors.P10;
      break;
    case 'overdue-checkin-user-count':
      imageSrc = CountIcon;
      title = t('workspaceDashboards.overdueCheckins');
      backgroundColor = theme.colors.Y10;
      break;
    case 'checkin_streak_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.checkinStreakLeaderboard');
      backgroundColor = theme.colors.Y10;
      break;
    case 'outcome_progress_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.outcomeProgressLeaderboard', {
        label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      });
      backgroundColor = theme.colors.Y10;
      break;
    case 'initiative_progress_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.initiativeProgressLeaderboard', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      });
      backgroundColor = theme.colors.B5;
      break;
    case 'confidence_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.confidenceLeaderboard');
      backgroundColor = theme.colors.Y10;
      break;
    case 'most_checkins_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.mostCheckinsLeaderboard');
      backgroundColor = theme.colors.Y10;
      break;
    case 'completed_tasks_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.initiativeLeaderboard', {
        label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      });
      backgroundColor = theme.colors.B5;
      break;
    case 'reactions_left_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.reactionsLeaderboard');
      backgroundColor = theme.colors.P10;
      break;
    case 'workspace-outcome-task-graph':
      imageSrc = OutcomeTaskIcon;
      title = t('workspaceDashboards.WorkspaceOutcomeTaskGraph', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      });
      backgroundColor = theme.colors.G5;
      break;
    case 'workspace-confidence-graph':
      imageSrc = PlanConfidenceIcon;
      title = t('workspaceDashboards.PlanConfidenceGraph');
      backgroundColor = theme.colors.G5;
      break;
    case 'most_commented_outcome_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.mostCommentedOutcome', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      });
      backgroundColor = theme.colors.P10;
      break;
    case 'most_viewed_outcome_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.mostViewedOutcome', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      });
      backgroundColor = theme.colors.P10;
      break;
    case 'most_reacted_outcome_leaderboard':
      imageSrc = LeaderboardIcon;
      title = t('workspaceDashboards.mostReactedOutcome', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      });
      backgroundColor = theme.colors.P10;
      break;
    default:
      imageSrc = '';
      title = '';
  }

  const isClickable = handleClick !== undefined;

  return (
    <Container onClick={() => isClickable && handleClick(type)} isClickable={isClickable}>
      <CardImageBlock backgroundColor={backgroundColor}>
        <CardImage src={imageSrc} alt={title} />
      </CardImageBlock>
      <CardTitle>{title ? title : type}</CardTitle>
    </Container>
  );
}

export default WidgetOptionCard;

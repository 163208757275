import KoalaAvatar from 'koala/components/Avatar';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Membership } from 'types';

const UserSelection = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 3.2rem 1fr;
  grid-template-areas: 'icon text';
  align-items: center;
`;

const UserSelectionIcon = styled.div`
  grid-area: icon;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const UserSelectionText = styled.div`
  margin-left: ${theme.spacing.x1};
  grid-area: text;
  span {
    word-break: break-word;
  }
`;

interface Props {
  membership: Membership;
}

function UserRow(props: Props) {
  const { membership } = props;
  const name = membership.cached_fullname ?? membership.cached_email;
  return (
    <UserSelection>
      <UserSelectionIcon>
        <KoalaAvatar membership={membership} size={2.4} tooltipType="none" />
      </UserSelectionIcon>
      <UserSelectionText>
        <span className="owner-option-name">{name}</span>
      </UserSelectionText>
    </UserSelection>
  );
}

export default React.memo(UserRow);

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Objective, Outcome } from 'types';
import { InitiativeStates } from 'utils/initiativeUtils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import StateColumn from './StateColumn';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { midString } from 'state/reducers/utils';

// Actions
import { editorUpdateInitiative } from 'state/actions/workspaceEditorActions';

import * as initiativeUtils from 'utils/initiativeUtils';
import * as integrationUtils from 'utils/integrationUtils';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.x1};
`;

const ObjectiveTitle = styled.div`
  position: sticky;
  left: 0px;
  z-index: 1;
  width: 100%;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const StateColumns = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0px;
`;

function compare(a: Initiative, b: Initiative) {
  // Compare column ranks
  if (a.column_rank < b.column_rank) {
    return -1;
  }
  if (a.column_rank > b.column_rank) {
    return 1;
  }

  // Compare created dates
  if (!a.created_at || !b.created_at) {
    return 0;
  }
  if (a.created_at < b.created_at) {
    return -1;
  }
  if (a.created_at > b.created_at) {
    return 1;
  }
  return 0;
}

interface Props {
  objective: Objective;
  initiatives: Initiative[];
  outcomes: Outcome[];
}

function KanbanObjectiveSection(props: Props) {
  const dispatch = useDispatch();
  const { objective, initiatives } = props;
  const queryCache = useQueryCache();
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentWorkspaceSettings = useSelector((state: any) => state.session.currentWorkspaceSettings, shallowEqual);
  const { t } = useTranslation();

  const initiativesByState: { [key: string]: Initiative[] } = {
    [InitiativeStates.BACKLOG]: [],
    [InitiativeStates.PLANNED]: [],
    [InitiativeStates.DESIGN]: [],
    [InitiativeStates.IN_PROGRESS]: [],
    [InitiativeStates.IN_REVIEW]: [],
    [InitiativeStates.READY]: [],
    [InitiativeStates.BLOCKED]: [],
    [InitiativeStates.DONE]: [],
    [InitiativeStates.ABANDONED]: [],
  };
  if (initiatives) {
    initiatives.forEach((initiative) => {
      let state: string = initiative.work_state ?? InitiativeStates.BACKLOG;

      if (initiative.integration_type) {
        const integrationStatus = integrationUtils.getIntegrationStatus(initiative, currentWorkspace, t);
        state = integrationStatus.status;
      }
      if (initiativesByState[state]) {
        initiativesByState[state].push(initiative);
      }
    });
  }

  const [updateInitiativeMutation] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentInitiative);
    },
  });

  interface InitiativeParams {
    state: string;
    column_rank: string;
    work_state: string;
  }
  const updateInitiative = (initiativeId: string, initiativeParams: InitiativeParams) => {
    dispatch(editorUpdateInitiative(initiativeId, initiativeParams));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const initiativeId = result.draggableId;
    const initiative = initiatives.find((initiative) => initiative.id === initiativeId);
    // check initiative isn't integration
    if (initiative && initiative.integration_remote_id && initiative.integration_type) {
      const integrationType = integrationUtils.getIntegrationType(initiative);
      const alertMessage = t('workspacePlan.initiatives.integrationAlert', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
        integrationType,
      });
      alert(alertMessage);
      return;
    }

    const [, state] = result.destination.droppableId.split(':');
    const stateInitiatives = initiativesByState[state];
    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;
    let column_rank = '';

    if (result.destination.droppableId !== result.source.droppableId) {
      // if changing columns
      // get the rank for the item it will be going above and below
      // and find the column rank inbetween
      let currentItemRank = stateInitiatives[destinationIndex]?.column_rank ?? '';
      let previousItemRank = stateInitiatives[destinationIndex - 1]?.column_rank ?? '';
      column_rank = midString(previousItemRank, currentItemRank);
    } else if (result.destination.droppableId === result.source.droppableId) {
      // if not changing columns
      if (sourceIndex === destinationIndex) {
        // hasn't moved
        return;
      } else if (sourceIndex > destinationIndex) {
        // Going backwards
        // get ranks above above and below and find inbetween
        let currentItemRank = stateInitiatives[destinationIndex]?.column_rank ?? '';
        let previousItemRank = stateInitiatives[destinationIndex - 1]?.column_rank ?? '';
        column_rank = midString(previousItemRank, currentItemRank);
      } else if (sourceIndex < destinationIndex) {
        // going forwards
        // get current and next rank and find inbetween
        let currentItemRank = stateInitiatives[destinationIndex]?.column_rank ?? '';
        let nextItemRank = stateInitiatives[destinationIndex + 1]?.column_rank ?? '';
        column_rank = midString(currentItemRank, nextItemRank);
      }
    }
    const state_params = initiativeUtils.getWorkStateParams(state);
    const initiativeParams = { ...state_params, column_rank };
    updateInitiative(initiativeId, initiativeParams);
    updateInitiativeMutation({ initiativeId: initiativeId, initiative: initiativeParams });
  };

  const objectiveClicked = () => {
    history.push(`#objective:${objective.nano_slug}:show`);
  };

  return (
    <>
      <ObjectiveTitle onClick={objectiveClicked}>
        <h3>{objective.title}</h3>
      </ObjectiveTitle>
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <StateColumns>
            {Object.keys(initiativesByState).map((workState, i) => {
              if (!initiativeUtils.isInitiativeStateActive(currentWorkspaceSettings, workState)) {
                return <></>;
              }
              const initiatives = initiativesByState[workState].sort(compare);
              return <StateColumn key={i} objectiveId={objective.id} workState={workState} initiatives={initiatives} />;
            })}
          </StateColumns>
        </DragDropContext>
      </Container>
    </>
  );
}

export default KanbanObjectiveSection;

import InitiativeDueDate from 'components/InitiativeDueDate';
import KoalaCheckbox from 'koala/components/Checkbox';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Workspace } from 'types';

import * as integrationUtils from 'utils/integrationUtils';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import ReactTooltip from 'react-tooltip';
import { KoalaTextBadgeVariants } from 'koala/shared/global';

const Row = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.x1};

  &.clickable {
    cursor: pointer;

    :hover {
      background: ${theme.colors.N3};
    }
    :active,
    :focus,
    &.selected {
      background: ${theme.colors.B5};
    }

    .title:hover {
      text-decoration: underline;
    }
  }
  &.read-only {
    .title:hover {
      cursor: default;
    }
  }

  &.heirarchy {
    display: grid;
    grid-template-columns: 1fr 30rem 25rem;
    gap: ${theme.spacing.x1};

    .initiative-details {
      min-width: 15rem;
      justify-content: space-between;
    }

    @media ${theme.devices.smallDesktop} {
      grid-template-columns: 1fr 25rem 25rem;
    }
    @media ${theme.devices.laptop} {
      grid-template-columns: 1fr 13rem 13rem;
    }
    @media ${theme.devices.tablet} {
      grid-template-columns: 1fr 13rem;
    }
  }

  &.compact {
    padding: ${theme.spacing.half};
    :hover {
      background: ${theme.colors.N3};
    }
  }
`;
const InitiativeDetails = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  .title {
    width: 100%;
    min-width: 1rem;
    display: block;
    overflow: hidden;
    word-break: break-word;
  }
  &.closed {
    .title {
      text-decoration: line-through;
    }
  }
`;

const InitiativeMeta = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  justify-content: flex-end;
  align-items: center;

  .initiative-state {
    /* min-width: 8.5rem; */
    display: flex;
    align-items: center;
  }
  .initiative-due-date {
    display: flex;
    align-items: center;
  }

  @media ${theme.devices.laptop} {
    .initiative-due-date {
      display: none;
    }
  }
`;

const IntegrationIcon = styled.img`
  width: 2rem;
  height: 2rem;
`;

interface Props {
  initiative: Initiative;
  workspace: Workspace;
  showHeirarchy?: boolean;
  showMeta?: boolean;
  isSelected?: boolean;
  isReadOnly?: boolean;
  allowLink?: boolean;
  hideOwners?: boolean;
  hideDueDate?: boolean;
  compact?: boolean;
}

function InitiativeRow(props: Props) {
  const { initiative, isSelected, showHeirarchy, showMeta, isReadOnly, allowLink, hideDueDate, compact, workspace } =
    props;
  const [checked, setChecked] = useState(initiative.state !== 'open');

  const { t } = useTranslation();

  useEffect(() => {
    setChecked(initiative.state !== 'open');
  }, [setChecked, initiative.state]);

  const showHashRoute = `#initiative:${initiative.nano_slug}:show`;

  let initiativeClass = `item initiative-row ${isSelected ? 'selected' : ''} ${showHeirarchy ? 'heirarchy' : ''} ${
    isReadOnly ? 'read-only' : 'clickable'
  }`;
  if (compact) {
    initiativeClass += ' compact';
  }

  let title = initiative.title;
  let dueDate = initiative.due_at;
  let imageSource = null;
  let isIntegration = false;
  let isClosed = initiative.state === 'closed';
  let integrationStatus = 'backlog';
  let integrationStatusColor = 'neutral-light';
  let integrationType = initiative.integration_type;

  if (initiative.integration_type && initiative.integration_remote_id) {
    isIntegration = true;
    title = initiative.integration_title;
    imageSource = integrationUtils.getIntegrationImageSource(initiative.integration_type);
    dueDate = integrationUtils.getDueDate(initiative);
    isClosed = integrationUtils.isIntegrationClosed(initiative);
    const { state, color } = integrationUtils.getIntegrationStatus(initiative, workspace, t);
    integrationStatus = state;
    integrationStatusColor = color;
    integrationType = integrationUtils.getIntegrationType(initiative);
  }
  const closedClass = isClosed ? 'closed' : '';

  return (
    <Row className={initiativeClass}>
      <InitiativeDetails className={`initiative-details ${closedClass}`}>
        {!isIntegration && (
          <KoalaCheckbox checked={checked} size="small" isClickable={false} className="initiative-checkbox" />
        )}
        {isIntegration && imageSource && <IntegrationIcon src={imageSource} />}
        {allowLink && (
          <Link to={showHashRoute} className="title">
            {title}
          </Link>
        )}
        {!allowLink && <div className="title">{title}</div>}
      </InitiativeDetails>
      {showMeta && (
        <InitiativeMeta className="initiative-meta">
          <div className="initiative-state">
            {isIntegration && integrationStatus && (
              <KoalaTextBadge
                variant={integrationStatusColor as (typeof KoalaTextBadgeVariants)[number]}
                isLowercase
                size="small"
              >
                {integrationStatus}
              </KoalaTextBadge>
            )}
            {!isIntegration && !isReadOnly && <InitiativeWorkStatus initiative={initiative} showArrow={false} />}
          </div>
          {!hideDueDate && (
            <div className="initiative-due-date">
              <InitiativeDueDate dueAt={dueDate} state={initiative.state} dataFor={`${initiative.nano_slug}-dueDate`} />
              {isIntegration && (
                <ReactTooltip
                  id={`${initiative.nano_slug}-dueDate`}
                  place="top"
                  effect="solid"
                  type="dark"
                  delayShow={300}
                >
                  {t(`panels.initiative.integrationDueDate`, { integrationType })}
                </ReactTooltip>
              )}
            </div>
          )}
        </InitiativeMeta>
      )}
    </Row>
  );
}

export default InitiativeRow;

import { Initiative, Workspace } from 'types';
import React from 'react';
import KoalaTextBadge from 'koala/components/TextBadge';
import { getWorkState, getWorkStateColor } from 'utils/initiativeUtils';
import styled from 'styled-components';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { KoalaTextBadgeVariants } from 'koala/shared/global';
import { shallowEqual, useSelector } from 'react-redux';

const BadgeContainer = styled.div<{ isClickable: boolean }>`
  height: 2.2rem;
  width: fit-content;

  .text-badge > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .text-badge:hover {
    filter: ${({ isClickable }) => (isClickable ? 'brightness(0.95)' : 'none')};
  }
`;

interface Props {
  initiative?: Initiative;
  showArrow: boolean;
  providedState?: string;
  size?: 'small' | 'medium';
}

function InitiativeWorkStatus(props: Props) {
  const { initiative, showArrow, providedState, size } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  let id = `initiative-status-${Math.floor(Math.random() * 100000 + 1)}`;
  const { t } = useTranslation();

  let state = workspace?.custom_term_backlog ?? 'Backlog';
  let colorVariant: (typeof KoalaTextBadgeVariants)[number] = 'neutral-light';
  if (initiative) {
    id = `initiative-status-${initiative.nano_slug}`;
    state = getWorkState(initiative.work_state ?? 'backlog', workspace, t);
    colorVariant = getWorkStateColor(initiative.work_state ?? 'backlog');
  } else if (providedState) {
    state = getWorkState(providedState, workspace, t);
    colorVariant = getWorkStateColor(providedState);
  }

  const badgeSize = size ?? 'small';

  return (
    <>
      <BadgeContainer data-tip data-for={id} isClickable={showArrow} className="initiative-work-status">
        <KoalaTextBadge
          isLowercase={true}
          variant={colorVariant}
          size={badgeSize}
          suffixIcon={
            showArrow ? <KoalaIcon iconName="downChevron" iconSize="small" iconAppearance="currentColor" /> : undefined
          }
        >
          {state}
        </KoalaTextBadge>
      </BadgeContainer>
    </>
  );
}

export default InitiativeWorkStatus;

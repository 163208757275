import React from 'react';
import * as TabilityTypes from 'types';
import styled from 'styled-components';
import theme from 'theme';

import { useSelector, shallowEqual } from 'react-redux';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { useParams } from 'react-router-dom';
import useDrivePicker from 'react-google-drive-picker';
import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import GoogleSheetsDetailsSearchableDropdown from 'components/GoogleSheetsDetailsSearchableDropdown';
import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';
const InlineItems = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();

  const outcomeSpreadsheetId = outcome.data_source_meta?.spreadsheet?.id || '';
  const outcomeSheetId = outcome.data_source_meta?.sheet?.id || '';
  const outcomeColumn = outcome.data_source_meta?.column || '';
  const outcomeRow = outcome.data_source_meta?.row || '';

  const outcomeReference = `${outcomeSpreadsheetId}:${outcomeSheetId}:${outcomeColumn}:${outcomeRow}`;
  // Query keys and query params
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:google_sheets:cell${outcomeReference}`,
    'current',
  ];
  const staleTime = 300;

  const { data: outcomeResponse, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = outcomeResponse?.data.result;

  if (currentValue === null) {
    return <FormField>Sorry, an error occurred while fetching the current value.</FormField>;
  }

  if (currentValue?.error) {
    const error = `${currentValue.error}`;
    return (
      <FormField>
        <p>{t(`panels.editOutcome.queryError`)}</p>
        <p>{error}</p>
      </FormField>
    );
  }

  return (
    <FormField>
      <label>Preview value (unformatted)</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  integration: any;
}

function GoogleSheetSource(props: Props) {
  const { outcome } = props;
  const [openPicker] = useDrivePicker();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );

  const [token, setToken] = React.useState<string | null>(null);
  const [spreadsheet, setSpreadsheet] = React.useState(outcome.data_source_meta?.spreadsheet);
  const [sheet, setSheet] = React.useState(outcome.data_source_meta?.sheet);
  const [row, setRow] = React.useState(outcome.data_source_meta?.row);
  const [column, setColumn] = React.useState(outcome.data_source_meta?.column);
  const [type, setType] = React.useState(outcome.data_source_meta?.type);

  const outcomeSpreadsheetId = outcome.data_source_meta?.spreadsheet?.id || '';
  const outcomeSheetId = outcome.data_source_meta?.sheet?.id;
  const outcomeColumn = outcome.data_source_meta?.column || '';
  const outcomeRow = outcome.data_source_meta?.row || '';
  const outcomeType = outcome.data_source_meta?.type || '';

  const outcomeReference = `${outcomeSpreadsheetId}:${outcomeSheetId}:${outcomeColumn}:${outcomeRow}:${outcomeType}`;

  const queryCache = useQueryCache();

  // Load the Google Token
  const queryKey = [queryKeys.currentWorkspace, workspaceSlug, 'token'];

  const { isLoading } = useQuery(queryKey, remoteApi.fetchGoogleToken, {
    staleTime: 0,
    onSuccess: (response) => {
      setToken(response.data);
    },
  });

  // Spreadsheet selection function
  const handleOpenPicker = (e: any) => {
    e.preventDefault();
    if (!token || !process.env.REACT_APP_GOOGLE_API_KEY || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
      return null;
    }
    const googleClientIdParts = process.env.REACT_APP_GOOGLE_CLIENT_ID.split('.');
    openPicker({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
      // viewId: "SPREADSHEETS",
      token,
      appId: googleClientIdParts[0],
      //showUploadView: false,
      //showUploadFolders: false,
      //supportDrives: true,
      //multiselect: false,
      viewMimeTypes: 'application/vnd.google-apps.spreadsheet',
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
        }
        if (data.action === 'picked') {
          const spreadsheet = data.docs[0];
          setSpreadsheet(spreadsheet);
          setSheet(null);
          setRow(null);
          setColumn(null);
        }
      },
    });
  };

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }]: [any, any] = useMutation(
    remoteApi.updateOutcomeDataSource,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentOutcome);
      },
    },
  );

  const handleTypeChange = (option: any) => {
    setType(option.value);
    if (option.value === 'last_in_row') {
      setRow('');
      setColumn('last');
    } else if (option.value === 'last_in_column') {
      setColumn('');
      setRow('last');
    } else {
      setColumn('');
      setRow('');
    }
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    if (type === 'last_in_row') {
      setColumn('last');
    }
    if (type === 'last_in_column') {
      setRow('last');
    }
    const data_source_meta = {
      spreadsheet,
      sheet,
      type,
      row,
      column,
      membership_id: currentMembership.id, // need the membership id to know which google integration to use
    };

    let body = {
      data_source_origin: 'google_sheets',
      data_source_type: 'cell',
      data_source_meta,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  if (!token || !process.env.REACT_APP_GOOGLE_API_KEY || !process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    return <FormField>Authentication token not found. Try reconnecting your account.</FormField>;
  }

  const spreadsheetId = spreadsheet?.id || '';
  const sheetId = sheet?.id;

  const reference = `${spreadsheetId}:${sheetId}:${column}:${row}:${type}`;
  const disabled = !spreadsheetId || !sheetId;

  const options = [
    { value: 'cell', label: 'Set specific cell' },
    { value: 'last_in_row', label: 'Last value in row' },
    { value: 'last_in_column', label: 'Last value in column' },
  ];
  const selectedOption = options.find((option) => option.value === type);

  return (
    <>
      {!spreadsheet && (
        <FormField>
          <label>Spreadsheet</label>
          <KoalaButton onClick={handleOpenPicker}>Select spreadsheet</KoalaButton>
        </FormField>
      )}

      {spreadsheet && (
        <>
          <FormField>
            <label>Spreadsheet</label>
            <InlineItems>
              {spreadsheet.name}
              <KoalaButton onClick={handleOpenPicker} appearance="secondary" size="small">
                Change spreadsheet
              </KoalaButton>
            </InlineItems>
          </FormField>
          <GoogleSheetsDetailsSearchableDropdown spreadsheetId={spreadsheet.id} setSheet={setSheet} sheet={sheet} />
        </>
      )}
      {spreadsheet && sheet && (
        <>
          <FormField>
            <label>Type</label>
            <div style={{ width: '50%' }}>
              <KoalaSelect
                options={options}
                handleChange={(option) => handleTypeChange(option)}
                placeholder="Select method"
                selectedOption={selectedOption}
              />
            </div>
          </FormField>
          {type !== 'last_in_row' && (
            <FormField>
              <label>Column</label>
              <input
                value={column}
                onChange={(e) => setColumn(e.currentTarget.value)}
                placeholder="Column"
                className="tiny"
              />
            </FormField>
          )}
          {type !== 'last_in_column' && (
            <FormField>
              <label>Row</label>
              <input value={row} onChange={(e) => setRow(e.currentTarget.value)} placeholder="Row" className="tiny" />
            </FormField>
          )}
          {outcomeReference !== reference && (
            <FormField>
              <KoalaButton
                onClick={handleUpdate}
                loading={isUpdating}
                disabled={disabled || isUpdating}
                appearance="subtle"
              >
                Save & preview
              </KoalaButton>
            </FormField>
          )}
          {outcome.data_source_origin === 'google_sheets' && outcomeReference === reference && !disabled && (
            <>
              <CurrentValue outcome={outcome} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(GoogleSheetSource);

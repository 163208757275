import React, { useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import styled from 'styled-components';
const ErrorContainer = styled.small`
  color: #d0402e;
`;

interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcome.id, `data_source:tableau`, 'current'];
  // const staleTime = 300;

  const { data, isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {});

  if (isFetching) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = toNumber(data?.data.result);

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  workspaceSlug: string;
}

function TableauSource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [workbook, setWorkbook] = useState<string>(
    outcome.data_source_meta ? outcome.data_source_meta.workbook_id : '',
  );
  const [view, setView] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.view_id : '');
  const [column, setColumn] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.column : '');
  const [aggregationType, setAggregateType] = useState<string>(outcome.data_source_type ?? '');
  const [viewOptions, setViewOptions] = useState<KoalaSelectOption[]>([]);
  const [workbookOptions, setWorkbookOptions] = useState<KoalaSelectOption[]>([]);
  const [columnOptions, setColumnOptions] = useState<KoalaSelectOption[]>([]);
  const [workbookError, setWorkbookErrors] = useState('');

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let body = {
      data_source_origin: 'tableau',
      data_source_type: aggregationType,
      data_source_meta: { column, workbook_id: workbook, view_id: view },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const staleTime = 0;
  const queryKeyWorkbook = ['tableau_workbook', workspaceSlug];
  const { isLoading: isLoadingWorkbook } = useQuery(queryKeyWorkbook, remoteApi.listTableauWorkbooks, {
    staleTime,
    onSuccess: (response) => {
      setWorkbookErrors('');

      if (!response.data?.workbook) {
        setWorkbookOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.workbook.map((e: any) => ({
        label: e.name,
        value: e.id,
      }));
      setWorkbookOptions(options);
    },
    onError: (error: any) => {
      setWorkbookErrors(error.response.data.error);
    },
  });

  const queryKeyViews = ['tableau_workbook_views', workspaceSlug, workbook];
  const { isLoading: isLoadingViews } = useQuery(queryKeyViews, remoteApi.listTableauViews, {
    staleTime,
    onSuccess: (response) => {
      setWorkbookErrors('');

      if (!response.data?.view) {
        setViewOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.view.map((e: any) => ({
        label: e.name,
        value: e.id,
      }));
      setViewOptions(options);
    },
    onError: (error: any) => {
      setWorkbookErrors(error.response.data.error);
    },
    enabled: !!workbook,
  });

  const queryKeyViewDetails = ['tableau_views', workspaceSlug, view];
  const { isLoading: isLoadingViewDetails } = useQuery(queryKeyViewDetails, remoteApi.fetchTableauViewDetails, {
    staleTime,
    onSuccess: (response) => {
      setWorkbookErrors('');

      if (!response.data?.headers) {
        setColumnOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.headers.map((header: string) => ({
        label: header,
        value: header,
      }));

      setColumnOptions(options);
    },
    onError: (error: any) => {
      setWorkbookErrors(error.response.data.error);
    },
    enabled: !!view,
  });

  const onWorkbookChange = (option: any) => {
    const value = option ? option.value : null;
    setWorkbook(value);
    setViewOptions([]);
    setView('');
  };
  const selectedWorkbookOption = workbookOptions.find((option) => option.value === workbook);

  const onViewChange = (option: any) => {
    const value = option ? option.value : null;
    setView(value);
    setColumnOptions([]);
    setColumn('');
  };
  const selectedViewOption = viewOptions.find((option) => option.value === view);

  const onColumnChange = (option: any) => {
    const value = option ? option.value : null;
    setColumn(value);
  };
  const selectedColumnOption = columnOptions.find((option) => option.value === column);

  const onAggregateTypeChange = (option: any) => {
    const value = option ? option.value : null;
    setAggregateType(value);
  };

  const aggregateOptions: KoalaSelectOption[] = [
    { label: 'Sum', value: 'sum' },
    { label: 'Count', value: 'count' },
    { label: 'Average', value: 'average' },
    { label: 'Max', value: 'max' },
    { label: 'Min', value: 'min' },
  ];
  const selectedAggregateOption: any = aggregateOptions.find((option) => option.value === aggregationType);

  const tableauKey = `modals.dataConnectors.tableauData`;
  const saveable = aggregationType && workbook && view && column;
  return (
    <>
      <FormField>
        <label>
          {t(`${tableauKey}.workbook`)}
          {isLoadingWorkbook && <KoalaLoader />}
        </label>
        {!isLoadingWorkbook && (
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onWorkbookChange(options)}
              placeholder={t(`${tableauKey}.workbookPlaceholder`) ?? 'Select Workbook'}
              selectedOption={selectedWorkbookOption}
              options={workbookOptions}
              className="small"
            />
          </div>
        )}
      </FormField>
      {workbook && !isLoadingWorkbook && (
        <FormField>
          <label>
            {t(`${tableauKey}.view`)}
            {isLoadingViews && <KoalaLoader />}
          </label>
          {!isLoadingViews && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onViewChange(options)}
                placeholder={t(`${tableauKey}.viewPlaceholder`) ?? 'Select View'}
                selectedOption={selectedViewOption}
                options={viewOptions}
                className="small"
              />
            </div>
          )}
        </FormField>
      )}
      {isLoadingViewDetails && <KoalaLoader />}

      {view && !isLoadingViewDetails && (
        <>
          <FormField>
            <label>
              {t(`${tableauKey}.viewData`)}
              {isLoadingViewDetails && <KoalaLoader />}
            </label>
            {!isLoadingViews && (
              <div style={{ width: '50%' }}>
                <KoalaSelect
                  handleChange={(options) => onColumnChange(options)}
                  placeholder={t(`${tableauKey}.viewDataPlaceholder`) ?? 'Select View Data Name'}
                  selectedOption={selectedColumnOption}
                  options={columnOptions}
                  className="small"
                />
              </div>
            )}
          </FormField>
        </>
      )}
      {workbook && view && column && (
        <FormField>
          <label>{t(`${tableauKey}.aggregation`)}</label>
          <div style={{ width: '50%' }}>
            <KoalaSelect
              handleChange={(options) => onAggregateTypeChange(options)}
              placeholder={t(`${tableauKey}.aggregationPlaceholder`) ?? 'Select aggregation type'}
              selectedOption={selectedAggregateOption}
              options={aggregateOptions}
              className="small"
            />
          </div>
        </FormField>
      )}
      {workbookError && <ErrorContainer>{workbookError}</ErrorContainer>}

      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={isUpdating || !saveable}
          appearance="subtle"
          type="button"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'tableau' &&
        outcome.data_source_type === aggregationType &&
        outcome.data_source_meta['view_id'] === view &&
        outcome.data_source_meta['column'] === column && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(TableauSource);

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Dashboard, DashboardWidget } from 'types';
import { WidgetBlock } from '.';
import DashboardWidgetBlock from 'components/DashboardWidgetBlock';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import EmptyStatePanel from 'components/EmptyStatePanel';
import { useTranslation } from 'react-i18next';
import { Responsive, WidthProvider } from 'react-grid-layout';
import KoalaLoader from 'koala/components/Loader';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Container = styled.div`
  height: 100%;
  position: relative;
  .grid-layout {
    height: 100%;
  }

  .block {
    background: skyblue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .static {
    background: blue;
  }
  .droppable-element {
    height: 50px;
    width: 250px;
    background: skyblue;
    margin: 10px;
    border: 2px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-grid-item.react-grid-placeholder {
    background: ${theme.colors.R10};
  }
`;

interface Props {
  dashboard: Dashboard;
}

function DashboardContent(props: Props) {
  const { dashboard } = props;
  const { t } = useTranslation();
  const [rowHeight, setRowHeight] = useState(30);
  const layout = JSON.parse(dashboard.grid_layout);
  const [dashboardWidgets, setDashboardWidgets] = useState<DashboardWidget[]>([]);

  const dashboardWidgetsQueryKey = [queryKeys.dashboardWidgets, dashboard.id];
  const { isLoading, isFetching } = useQuery(dashboardWidgetsQueryKey, remoteApi.fetchDashboardWidgets, {
    staleTime: 0,
    onSuccess: (response: AxiosResponse<DashboardWidget[]>) => {
      const data = response.data;
      setDashboardWidgets(data);
    },
  });

  const handleBreakpointChange = (newBreakpoint: string, newCols: number) => {
    if (newCols >= 6) {
      setRowHeight(30);
    } else {
      setRowHeight(35);
    }
  };

  const gridItems = useMemo(
    () =>
      dashboardWidgets?.map((widget) => {
        let widgetLayout = layout?.find((l: any) => l.i === widget.id) ?? { x: 0, y: 0, h: 2, w: 2, i: widget.id };
        const widgetClass = `read-only ${widget.widget_type}`;
        return (
          <WidgetBlock className={widgetClass} key={widget.id} data-grid={widgetLayout}>
            <DashboardWidgetBlock widget={widget} />
          </WidgetBlock>
        );
      }),
    [dashboardWidgets, layout],
  );

  if (isLoading || isFetching) {
    return (
      <EmptyStatePanel>
        <KoalaLoader />
      </EmptyStatePanel>
    );
  }

  if (!dashboardWidgets || dashboardWidgets.length === 0) {
    return <EmptyStatePanel>{t('workspaceDashboards.noWidgets')}</EmptyStatePanel>;
  }

  return (
    <Container>
      <ResponsiveReactGridLayout
        rowHeight={rowHeight}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 3, xxs: 3 }}
        isDraggable={false}
        isResizable={false}
        margin={[8, 8]}
        onBreakpointChange={handleBreakpointChange}
      >
        {gridItems}
      </ResponsiveReactGridLayout>
    </Container>
  );
}

export default React.memo(DashboardContent);

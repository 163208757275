import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import MarkdownContent from 'components/MarkdownContent';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import styled from 'styled-components';
import theme from 'theme';
import { CachedReaction, InitiativeComment, Membership, User } from 'types';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import * as remoteApi from 'api/remote';
import queryKeys from 'config/queryKeys';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaLoader from 'koala/components/Loader';
import Reactions from 'components/Reactions';
import ShareIconButton from 'components/ShareIconButton';
import * as routes from 'routes';
import { useHistory, useParams } from 'react-router-dom';
import DropdownMenu from 'components/DropdownMenu';
import * as membershipUtils from 'utils/membershipUtils';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'title title actions'
    'content content content'
    'timestamp reactions reactions';
  column-gap: ${theme.spacing.x1};
  padding-top: ${theme.spacing.x1};

  .block-actions {
    opacity: 0;
  }

  &:hover {
    .block-actions {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  grid-area: title;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const Timeblock = styled.label`
  grid-area: timestamp;
  display: block;
  align-items: center;
  color: ${theme.colors.subtleText};
  width: fit-content;
  word-wrap: normal;
  padding-top: ${theme.spacing.half};
`;

const ReactionContainer = styled.div`
  grid-area: reactions;
  display: flex;
  flex-direction: row;
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: ${theme.spacing.half};
`;

const TitleSection = styled.div`
  display: inline-block;
  align-items: center;
  padding-top: ${theme.spacing.half};

  .initiative-work-status {
    display: inline-block;
  }
`;

const ContentSection = styled.div`
  grid-area: content;
  padding-top: ${theme.spacing.x1};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  margin: ${theme.spacing.half} 0;
`;

interface Props {
  comment: InitiativeComment;
  setIsEditing: (value: boolean) => void;
  setShowNewReply: (value: boolean) => void;
  isSetNewState: boolean;
}

function DisplayInitiativeComment(props: Props) {
  const { comment, setIsEditing, setShowNewReply, isSetNewState } = props;
  const { membership } = comment;
  const user = membership ? membership.user : null;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const translationKey = 'modals.initiative';

  const queryCache = useQueryCache();
  const currentUser: User = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const [reactions, setReactions] = useState<CachedReaction[]>([]);

  useEffect(() => {
    if (comment.cached_reactions && typeof comment.cached_reactions !== 'string') {
      setReactions(comment.cached_reactions);
    } else {
      setReactions([]);
    }
  }, [comment]);

  const [addReactionMutation] = useMutation(remoteApi.createInitiativeCommentReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const [deleteReactionMutation] = useMutation(remoteApi.deleteReaction, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const [deleteCommentMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteInitiativeComment, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.initiativeComments, comment.initiative_id]);
    },
  });

  const handleReply = () => {
    setShowNewReply(true);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = () => {
    deleteCommentMutation(comment.id);
  };

  const addReaction = (emotion: string) => {
    addReactionMutation({ initiativeCommentId: comment.id, emotion: emotion });
  };
  const removeReaction = (reactionId: string) => {
    deleteReactionMutation(reactionId);
  };

  const handleAvatarClicked = (membership: Membership) => {
    history.push(`/${workspaceSlug}/people/${membership.id}`);
  };

  const handleMenuSelection = (value: ReactElement) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'edit':
        handleEdit();
        break;
      case 'delete':
        handleDelete();
        break;
    }
  };

  if (isDeleting) {
    return <KoalaLoader />;
  }
  const canEdit = user && currentUser.id === user.id;
  const canDelete = canEdit || currentMembership.role === 'owner' || currentMembership.role === 'admin';

  let menu_items: ReactElement[] = [];
  if (canEdit) {
    menu_items.push(
      <span key="edit" data-action="edit" data-id={comment.id}>
        {t('shared.edit')}
      </span>,
    );
  }
  if (canDelete) {
    menu_items.push(
      <span key="delete" data-action="delete" data-id={comment.id}>
        {t('shared.delete')}
      </span>,
    );
  }
  const link = `https://${process.env.REACT_APP_DOMAIN}${routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', comment.plan_nano_slug)}#initiative:${comment.initiative_id}:show`;

  return (
    <Container>
      <Header>
        <KoalaAvatar
          membership={comment.membership}
          handleClick={comment.membership ? () => handleAvatarClicked(comment.membership) : undefined}
          tooltipType="card"
        />
        <TitleSection>
          <b>{user?.fullname}</b> {isSetNewState ? t(`${translationKey}.setStatusTo`) : ''}{' '}
          <InitiativeWorkStatus providedState={comment.work_state} showArrow={false} />
        </TitleSection>
      </Header>
      <Actions className="block-actions">
        <ShareIconButton link={link} id={comment.id} />
        {!isReadOnly && menu_items.length > 0 && (
          <DropdownMenu
            trigger={<KoalaIconButton iconName="ellipsis" className="actions" />}
            onSelection={handleMenuSelection}
            items={menu_items}
          />
        )}
      </Actions>
      {comment.body && (
        <ContentSection>
          <MarkdownContent source={comment.body} />
        </ContentSection>
      )}
      <Timeblock>{formatDistanceToNowLocale(comment.created_at, i18n, true, false)}</Timeblock>
      <ReactionContainer>
        {!isReadOnly && <KoalaIconButton iconName="comment" onClick={handleReply} />}
        <Reactions reactions={reactions} addCallback={addReaction} removeCallback={removeReaction} />
      </ReactionContainer>
    </Container>
  );
}

export default React.memo(DisplayInitiativeComment);

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';

// API
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import { fetchPublicInitiatives } from 'api/remote';

import PublicInitiativeRow from 'components/PublicInitiativeRow';
import MarkdownContent from 'components/MarkdownContent';
import OutcomeWeight from 'components/OutcomeWeight';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MetricsCard from 'components/MetricsCard';

export const BlockContent = styled.div`
  grid-area: content;
  background: transparent;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  a.outcome {
    font-size: 1.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .outcome-title {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;
export const BlockMeta = styled.div`
  grid-area: meta;
`;

const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 18rem;
  grid-template-rows: auto;
  grid-template-areas:
    'content meta'
    'initiative meta';
  gap: ${theme.spacing.x4};
  padding: ${theme.spacing.x2};

  &:hover {
    background: ${theme.colors.N3};
  }

  .block-action {
    opacity: 0;
  }
`;

const BlockInitiative = styled.div`
  grid-area: initiative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  planId: string;
  hideClosedInitiatives?: boolean;
}

function OutcomeBlock(props: Props) {
  const { outcome, planId, hideClosedInitiatives } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const staleTime = 60 * 1000 * 5;
  const queryKeyInitiatives = [queryKeys.initiatives, outcome.id];
  const { data: initiativesResponse }: any = useQuery(queryKeyInitiatives, fetchPublicInitiatives, {
    staleTime,
  });
  let initiatives: TabilityTypes.Initiative[] = initiativesResponse ? initiativesResponse.data : [];
  if (hideClosedInitiatives) {
    initiatives = initiatives.filter((initiative) => initiative.state !== 'closed');
  }

  const currentCheckin = outcome.current_checkin;

  const body = currentCheckin ? currentCheckin.body : 'Waiting on first check-in.';

  const path = `https://${process.env.REACT_APP_DOMAIN}/${workspaceSlug}/plans/${planId}/outcomes#outcome:${outcome.nano_slug}:show`;

  return (
    <BlockGrid>
      <BlockContent>
        <div className="outcome-title">
          <OutcomeWeight outcome={outcome} />
          <a className="outcome" href={path} target="_blank" rel="noopener noreferrer">
            {outcome.title}
          </a>
        </div>
        {!currentCheckin && <p className="subtle">{t('publicPlan.noCheckin')}</p>}
        {currentCheckin && body && <MarkdownContent source={body} />}
        {currentCheckin && !body && <p className="subtle">{t('shared.noComment')}</p>}
      </BlockContent>
      <BlockMeta>
        <MetricsCard outcome={outcome} />
      </BlockMeta>
      {initiatives.length > 0 && (
        <BlockInitiative>
          <b>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}:</b>
          {initiatives.map((initiative) => {
            return (
              <PublicInitiativeRow
                isReadOnly={true}
                showHeirarchy={false}
                initiative={initiative}
                hideDueDate={true}
                key={initiative.id}
                showMeta={true}
                compact={true}
                workspace={currentWorkspace}
              />
            );
          })}
        </BlockInitiative>
      )}
    </BlockGrid>
  );
}

export default React.memo(OutcomeBlock);

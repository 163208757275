import { PanelContent, PanelGrid, PanelHeader } from 'components/GlobalPanel';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Follower, Membership } from 'types';
import * as remoteApi from 'api/remote';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OwnerLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  &.header {
    margin-bottom: ${theme.spacing.x1};
  }
`;

const NameContainer = styled.div`
  min-width: 30rem;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const FollowersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;
interface Props {
  membershipId: string;
}

function FollowersPanel(props: Props) {
  const { membershipId } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isCurrentMembership = currentMembership.id === membershipId;

  const [followers, setFollowers] = useState<Follower[]>([]);
  const followingQueryKey = [queryKeys.followers, membershipId, {}];
  const [membership, setMembership] = useState<Membership>();
  const queryKey = [queryKeys.memberships, membershipId];
  useQuery(queryKey, remoteApi.fetchMembershipDetails, {
    onSuccess: (response) => {
      const membership = response.data;
      setMembership(membership);
    },
  });

  const { isLoading: isLoadingFollowers, isFetching } = useQuery(followingQueryKey, remoteApi.fetchFollowers, {
    staleTime: 0,
    onSuccess: (response) => {
      const followers = response.data;
      setFollowers(followers);
    },
  });

  const handleCancel = () => {
    history.goBack();
  };

  const isLoading = isLoadingFollowers || isFetching;
  const name = membership?.cached_fullname ?? membership?.cached_email;

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{isCurrentMembership ? t('panels.followers.title') : t('panels.followers.titleOtherUser', { name })}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        {isLoading && <KoalaLoader />}
        {!isLoading && (
          <FollowersSection>
            {followers.length === 0 && <p className="subtle">{t('panels.followers.empty')}</p>}
            {followers.map((follower) => (
              <OwnerLine key={follower.id}>
                <NameContainer>
                  <OwnerOption className="active">
                    <KoalaAvatar membership={follower.membership_source} size={2.2} tooltipType="none" />
                    <span className="owner-option-name">
                      {follower.membership_source.cached_fullname ?? follower.membership_source.cached_email}
                    </span>
                  </OwnerOption>
                </NameContainer>
              </OwnerLine>
            ))}
          </FollowersSection>
        )}
      </PanelContent>
    </PanelGrid>
  );
}

export default FollowersPanel;

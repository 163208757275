import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { midString } from 'state/reducers/utils';
import { Initiative, Outcome } from 'types';

// API
import * as remoteApi from 'api/remote';

// Components
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Loader from 'components/Loader';
import NewInitiativeForm from './NewInitiativeForm';
import KoalaProgressBar from 'koala/components/ProgressBar';
import InitiativeRow from 'components/InitiativeRow';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KoalaTextBadge from 'koala/components/TextBadge';

const ProgressBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x1};

  .percentage-text {
    color: ${theme.colors.subtleText};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const InitiativeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  .initiative-row {
    display: flex;
    border-bottom: none;
    padding: 0;

    &:hover {
      background: none;
    }
  }
  .initiative-details {
  }
  .initiative-state {
  }
`;

const InitiativeWrapper = styled.div<{ isDragging: boolean }>`
  background: ${theme.colors.N0};
`;

interface Props {
  outcome: Outcome;
  hideMeta?: boolean;
  openTaskInNewTab?: boolean;
  isReadOnly?: boolean;
}

function InitiativesTab(props: Props) {
  const { outcome, hideMeta, openTaskInNewTab, isReadOnly } = props;
  const history = useHistory();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const translationKey = 'modals.checkin';
  // Construct the query key using the plan Id
  const queryKeyInitiatives = [queryKeys.initiatives, outcome.id];
  const staleTime = 60 * 1000 * 3;

  // Get the plan details
  const { data: initiativesResponse, isLoading }: any = useQuery(queryKeyInitiatives, remoteApi.fetchInitiatives, {
    staleTime,
  });

  const [updateInitiativeMutation]: [any, any] = useMutation(remoteApi.updateInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeyInitiatives);
    },
  });

  let initiatives: Initiative[] = [];
  if (initiativesResponse) {
    initiatives = initiativesResponse.data;
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const lastInitiative = initiatives[initiatives.length - 1];

  const totalInitiativesCount = initiatives.length;
  const closedInitiativesCount = initiatives.filter((i: any) => i.state === 'closed').length;
  const progressPrct = Math.round((closedInitiativesCount / totalInitiativesCount) * 100);

  const droppableId = `initiativesModalList-${outcome.id}`;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const initiativeId = result.draggableId;
    let rank = '';
    let selectedInitiatve = initiatives[result.source.index];

    if (result.source.index === result.destination.index) {
      return;
    }

    // Going backwards
    if (result.source.index > result.destination.index) {
      const index = result.destination.index;

      const currentItem = initiatives[index];
      let currentItemRank = currentItem && currentItem.rank ? currentItem.rank : '';

      // Then get the previous item
      const previousItem = initiatives[index - 1];
      let previousItemRank = previousItem && previousItem.rank ? previousItem.rank : '';

      rank = midString(previousItemRank, currentItemRank);
    }

    // Going forward
    if (result.source.index < result.destination.index) {
      const index = result.destination.index;

      const currentItem = initiatives[index];
      let currentItemRank = currentItem && currentItem.rank ? currentItem.rank : '';

      // Then get the next item
      const nextItem = initiatives[index + 1];
      let nextItemRank = nextItem && nextItem.rank ? nextItem.rank : '';

      rank = midString(currentItemRank, nextItemRank);
    }

    let initiativeParams = {
      rank,
    };

    if (selectedInitiatve) {
      selectedInitiatve.rank = rank;
      // Reorder the initiatives by rank
      initiatives.sort((a, b) => {
        return a.rank.localeCompare(b.rank);
      });
    }

    updateInitiativeMutation({
      initiativeId: initiativeId,
      initiative: initiativeParams,
    });

    return;
  };

  return (
    <Container className="panel-tasks">
      {totalInitiativesCount > 0 && (
        <ProgressBlock>
          <KoalaTextBadge variant="blue-light">
            {t(`${translationKey}.completed`, { closed: closedInitiativesCount, total: totalInitiativesCount })}
          </KoalaTextBadge>
          <KoalaProgressBar appearance="secondary" confidence="blue" value={progressPrct} />
          <span className="percentage-text">{progressPrct}%</span>
        </ProgressBlock>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <InitiativeContainer ref={provided.innerRef} {...provided.droppableProps}>
              {initiatives.map((initiative: Initiative, index) => {
                const blockId = `initiative:${initiative.nano_slug}`;
                const showHashRoute = `#${blockId}:show`;
                const handleTaskClick = () => {
                  if (openTaskInNewTab) {
                    window.open(`/${workspaceSlug}/plans/${outcome.plan.nano_slug}/outcomes${showHashRoute}`, '_blank');
                  } else {
                    history.push(showHashRoute);
                  }
                };
                let showMeta = hideMeta ? false : true;
                return (
                  <Draggable draggableId={initiative.id} index={index} key={initiative.id}>
                    {(provided, snapshot) => {
                      return (
                        <InitiativeWrapper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >
                          <InitiativeRow
                            initiative={initiative}
                            showMeta={showMeta}
                            handleClick={handleTaskClick}
                            key={initiative.id}
                            allowLink={!openTaskInNewTab}
                          />
                        </InitiativeWrapper>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </InitiativeContainer>
          )}
        </Droppable>
      </DragDropContext>
      {!isReadOnly && <NewInitiativeForm outcome={outcome} lastInitiative={lastInitiative} />}
    </Container>
  );
}

export default React.memo(InitiativesTab);

// This file contains the filters for the app

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { startOfYear, endOfYear, subDays, addDays } from 'date-fns';

import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import MembershipFilter from '../WorkspaceOutcomes/MembershipFilter';
import ContributorFilter from '../WorkspaceOutcomes/ContributorFilter';
import PlanFilter from '../WorkspaceOutcomes/PlanFilter';
import TagFilter from '../WorkspaceOutcomes/TagFilter';
import TeamFilter from 'pages/WorkspaceOutcomes/TeamFilter';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import DropdownMenu from 'components/DropdownMenu';
import KoalaButton from 'koala/components/Button';
import KoalaSelect from 'koala/components/Select';
import { useTranslation } from 'react-i18next';
import { InitiativeStates } from 'utils/initiativeUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { Workspace } from 'types';

const Container = styled.div`
  gap: ${theme.spacing.x1};
  display: flex;
  flex-wrap: wrap;
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-areas: 'label label' 'select button';
  align-items: center;
  column-gap: ${theme.spacing.half};
  label {
    grid-area: label;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1rem;
    display: block;
    margin-bottom: 0.4rem;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  .react-datepicker__input-container {
    input {
      height: 30px;
      font-size: 1.2rem;
      padding: 0px 6px;
    }
  }

  span {
    display: block;
    margin-right: ${theme.spacing.x1};
  }

  > div {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  updateURL: any;
  customFilterHash: { [key: string]: any };
}

function Filters(props: Props) {
  const { customFilterHash, updateURL } = props;
  const { t } = useTranslation();
  const filterHash = { ...customFilterHash };
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [displayedFilters, setDisplayedFilters] = useState(Object.keys(customFilterHash));

  useEffect(() => {
    setDisplayedFilters(Object.keys(customFilterHash));
  }, [customFilterHash]);

  const updateFilters = (attribute: string, value: any) => {
    filterHash[attribute] = value;

    if (attribute === 'timeline' && value === 'custom') {
      const refDate = Date.now();
      const start_at = subDays(startOfYear(refDate), 1).toISOString();
      const finish_at = addDays(endOfYear(refDate), 1).toISOString();
      filterHash.start_at = start_at;
      filterHash.finish_at = finish_at;
    } else if (attribute === 'duedate' && value === 'custom') {
      const refDate = Date.now();
      const due_after = subDays(startOfYear(refDate), 1).toISOString();
      const due_before = addDays(endOfYear(refDate), 1).toISOString();
      filterHash.due_after = due_after;
      filterHash.due_before = due_before;
    }

    if (filterHash.timeline === null) {
      filterHash.start_at = null;
      filterHash.finish_at = null;
    }
    if (filterHash.duedate === null) {
      filterHash.due_after = null;
      filterHash.due_before = null;
    }
    updateURL(filterHash);
  };

  const getSelectedOptionsFromFilter = (id: string, options: any) => {
    return options.filter((o: any) => {
      if (filterHash[id]) {
        return filterHash[id].indexOf(o.value) >= 0;
      }
      return false;
    });
  };

  const getSelectedSingleOptionFromFilter = (id: string, options: any) => {
    const op = options.filter((o: any) => {
      if (typeof filterHash[id] !== 'undefined') {
        return filterHash[id] === o.value;
      }
      return false;
    });
    return op;
  };

  const handleMultiOptionSelect = (selectId: string, options: any) => {
    if (options) {
      const values = options.map((option: any) => option.value);
      updateFilters(selectId, values);
    }
  };

  const handleSingleOptionSelect = (selectId: string, option: any) => {
    const value = option ? option.value : null;
    updateFilters(selectId, value);
  };

  const handleAsyncFilterSelect = (selectId: string, options: any) => {
    if (options) {
      updateFilters(selectId, options);
    }
  };

  const handleSelectDate = (selectId: string, date: any) => {
    if (date) {
      updateFilters(selectId, date.toISOString());
    }
  };

  const handleAddFilter = (value: any) => {
    filterHash[value.key] = null;
    updateURL(filterHash);
  };

  const planStatusOptions = [
    { label: t('workspaceFilters.planStatusInProgress'), value: 'is_in_progress' },
    { label: t('workspaceFilters.planStatusDraft'), value: 'is_draft' },
  ];

  const stateOptions = [
    { label: t('workspaceFilters.stateOpen'), value: 'open' },
    { label: t('workspaceFilters.stateClosed'), value: 'closed' },
  ];

  const timelineOptions = [
    { label: t('shared.timelineThisQuarter'), value: 'current_quarter' },
    { label: t('shared.timelineLastQuarter'), value: 'last_quarter' },
    { label: t('shared.timelineNextQuarter'), value: 'next_quarter' },
    { label: t('shared.timelineCustom'), value: 'custom' },
  ];

  const workStateOptions: { label: string; value: string }[] = [
    { label: workspace.custom_term_backlog ?? t('shared.initiatives.backlog'), value: InitiativeStates.BACKLOG },
    { label: workspace.custom_term_planned ?? t('shared.initiatives.planned'), value: InitiativeStates.PLANNED },
    { label: workspace.custom_term_design ?? t('shared.initiatives.design'), value: InitiativeStates.DESIGN },
    {
      label: workspace.custom_term_in_progress ?? t('shared.initiatives.in_progress'),
      value: InitiativeStates.IN_PROGRESS,
    },
    { label: workspace.custom_term_in_review ?? t('shared.initiatives.in_review'), value: InitiativeStates.IN_REVIEW },
    { label: workspace.custom_term_ready ?? t('shared.initiatives.ready'), value: InitiativeStates.READY },
    { label: workspace.custom_term_blocked ?? t('shared.initiatives.blocked'), value: InitiativeStates.BLOCKED },
    { label: workspace.custom_term_done ?? t('shared.initiatives.done'), value: InitiativeStates.DONE },
    { label: workspace.custom_term_abandoned ?? t('shared.initiatives.abandoned'), value: InitiativeStates.ABANDONED },
  ];

  const insightsOptions = [
    { label: t('workspaceFilters.overdue'), value: 'is_overdue' },
    { label: t('workspaceFilters.withoutOwner'), value: 'without_owner' },
    { label: t('workspaceFilters.reporting'), value: 'reporting' },
  ];

  const selectedTimeline = getSelectedSingleOptionFromFilter('timeline', timelineOptions);
  const selectedMemberships = filterHash['membership'];
  const selectedTeams = filterHash['team'];
  const selectedContributors = filterHash['contributor'];
  const selectedPlans = filterHash['plan'];
  const selectedTags = filterHash['tag'];

  const start_at: any = filterHash['start_at'] ? Date.parse(filterHash['start_at']) : Date.now();
  const finish_at: any = filterHash['finish_at'] ? Date.parse(filterHash['finish_at']) : Date.now();

  const defaultFilter = {
    timeline: 'current_quarter',
  };
  const isDefaultFilter = JSON.stringify(customFilterHash) === JSON.stringify(defaultFilter);

  const filterItems = [
    <span key="plan_status">{t('workspaceFilters.planStatus')}</span>,
    <span key="team">{t('workspaceFilters.teams')}</span>,
    <span key="membership">{t('workspaceFilters.owners')}</span>,
    <span key="contributor">{t('workspaceFilters.contributors')}</span>,
    <span key="plan">{t('workspaceFilters.plans')}</span>,
    <span key="roadmap_state">{t('workspaceFilters.planning')}</span>,
    <span key="work_state">{t('workspaceFilters.work_state')}</span>,
    <span key="state">{t('workspaceFilters.completion')}</span>,
    <span key="tag">{t('workspaceFilters.tags')}</span>,
    <span key="timeline">{t('workspaceFilters.timeline')}</span>,
    <span key="insights">{t('workspaceFilters.insights')}</span>,
  ];

  const handleResetFilters = () => {
    updateURL(defaultFilter);
  };

  const handleRemoveActiveFilter = (filter: string) => {
    if (filterHash[filter] !== undefined) {
      delete filterHash[filter];
      updateURL(filterHash);
    }
  };

  return (
    <Container>
      <FilterWrapper>
        <label>&nbsp;</label>
        <DropdownMenu
          position="left"
          onSelection={handleAddFilter}
          items={filterItems.filter((item) => !displayedFilters.includes(item.key as string))}
          trigger={
            <KoalaButton appearance="subtle" className="add-filter-button">
              {t('workspaceFilters.filterButton')}
            </KoalaButton>
          }
        />
      </FilterWrapper>
      {displayedFilters.includes('plan_status') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.planStatus')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="plan_status"
            placeholder={t('workspaceFilters.placeholder')}
            options={planStatusOptions}
            handleChange={(options) => handleSingleOptionSelect('plan_status', options)}
            selectedOption={getSelectedSingleOptionFromFilter('plan_status', planStatusOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('plan_status')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('team') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.teams')}</label>
          <TeamFilter value={selectedTeams} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('team')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('membership') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.owners')}</label>
          <MembershipFilter value={selectedMemberships} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('membership')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('contributor') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.contributors')}</label>
          <ContributorFilter value={selectedContributors} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('contributor')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('plan') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.plans')}</label>
          <PlanFilter value={selectedPlans} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('plan')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('work_state') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.work_state')}</label>
          <KoalaSelect
            isClearable
            isMultiSelect
            size="xsmall"
            id="work_state"
            placeholder={t('workspaceFilters.placeholder')}
            options={workStateOptions}
            handleChange={(options) => {
              handleMultiOptionSelect('work_state', options);
            }}
            selectedOption={getSelectedOptionsFromFilter('work_state', workStateOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('work_state')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('state') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.completion')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="state"
            placeholder={t('workspaceFilters.placeholder')}
            options={stateOptions}
            handleChange={(options) => handleSingleOptionSelect('state', options)}
            selectedOption={getSelectedSingleOptionFromFilter('state', stateOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('state')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('insights') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.insights')}</label>
          <KoalaSelect
            isClearable
            size="xsmall"
            id="insights"
            placeholder={t('workspaceFilters.placeholder')}
            options={insightsOptions}
            handleChange={(options) => handleSingleOptionSelect('insights', options)}
            selectedOption={getSelectedSingleOptionFromFilter('insights', insightsOptions)}
          />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('insights')} />
        </FilterWrapper>
      )}
      {displayedFilters.includes('timeline') && (
        <>
          <FilterWrapper>
            <label>{t('workspaceFilters.timeline')}</label>
            <KoalaSelect
              isClearable
              size="xsmall"
              id="timeline"
              placeholder={t('workspaceFilters.placeholder')}
              options={timelineOptions}
              handleChange={(options) => handleSingleOptionSelect('timeline', options)}
              selectedOption={selectedTimeline}
            />
            <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('timeline')} />
          </FilterWrapper>
          {selectedTimeline && selectedTimeline[0] && selectedTimeline[0].value === 'custom' && (
            <FilterWrapper>
              <label>{t('workspaceFilters.customTimeline')}</label>
              <DatesWrapper>
                <span>{t('workspaceFilters.customTimelineStart')}</span>
                <DatePicker
                  selected={start_at}
                  onChange={(date: any) => handleSelectDate('start_at', date)}
                  dateFormat="d MMM yyyy"
                />
                <span>{t('workspaceFilters.customTimelineEnd')}</span>
                <DatePicker
                  selected={finish_at}
                  onChange={(date: any) => handleSelectDate('finish_at', date)}
                  dateFormat="d MMM yyyy"
                />
              </DatesWrapper>
            </FilterWrapper>
          )}
        </>
      )}
      {displayedFilters.includes('tag') && (
        <FilterWrapper>
          <label>{t('workspaceFilters.tags')}</label>
          <TagFilter value={selectedTags} handleChange={handleAsyncFilterSelect} />
          <KoalaIconButton iconName="close" size="small" onClick={() => handleRemoveActiveFilter('tag')} />
        </FilterWrapper>
      )}

      {!isDefaultFilter && (
        <FilterWrapper>
          <label>&nbsp;</label>
          <KoalaTextButton onClick={handleResetFilters}>{t('workspaceFilters.resetFilters')}</KoalaTextButton>
        </FilterWrapper>
      )}
    </Container>
  );
}

export default Filters;

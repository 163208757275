import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { DashboardWidget } from 'types';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
  gap: ${theme.spacing.x2};
`;

interface Props {
  handleSaveWidget: (type: string, source: string, source_id?: string, widgetId?: string, widgetMeta?: any) => void;
  isLoading: boolean;
  isEditing?: boolean;
  widget?: DashboardWidget;
}

function CompletedTasksLeaderboardWidgetDetails(props: Props) {
  const { handleSaveWidget, isLoading, isEditing, widget } = props;
  const { t } = useTranslation();
  const [direction, setDirection] = useState(widget?.widget_meta.order ?? 'desc');

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const order = direction ?? 'desc';
    handleSaveWidget('completed_tasks_leaderboard', 'leaderboard', undefined, widget?.id, { order });
  };

  const handleDirectionChange = (item: any) => {
    setDirection(item.value);
  };

  const directionOptions: KoalaSelectOption[] = [
    { label: t('workspaceDashboards.highest'), value: 'desc' },
    { label: t('workspaceDashboards.lowest'), value: 'asc' },
  ];

  const directionLabel = t('workspaceDashboards.order');
  const selectedOption = directionOptions.filter((option) => option.value === direction);

  return (
    <Container>
      <div>
        <label>{directionLabel}</label>
        <KoalaSelect
          handleChange={(option) => handleDirectionChange(option)}
          placeholder={directionLabel}
          selectedOption={selectedOption}
          options={directionOptions}
          size="small"
          id="segment"
        />
      </div>
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading}>
          {isEditing ? t('shared.save') : t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default CompletedTasksLeaderboardWidgetDetails;

import React, { useState } from 'react';
import { Outcome, OutcomeSectionMeta, RetrospectiveSection } from 'types';
import styled from 'styled-components';
import theme from 'theme';
import KoalaTextBadge from 'koala/components/TextBadge';
import * as checkinUtils from 'utils/checkinUtils';
import MarkdownContent from 'components/MarkdownContent';
import KoalaColorBlock from 'koala/components/ColorBlock';
import ReactTooltip from 'react-tooltip';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import OutcomeCard from 'components/OutcomeCard';
import { Link } from 'react-router-dom';
const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ql-editor.ql-display-only {
    padding: 0 !important;
  }

  .avatar-tooltip {
    padding: 0px;
    background: transparent;
    border: none;
    &.show {
      opacity: 1 !important;
    }
  }
`;
const HeaderRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  justify-content: space-between;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;
const DescriptionRow = styled.div``;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  padding: 0 0 1.2rem 0;
`;

interface Props {
  section: RetrospectiveSection;
  allowLink?: boolean;
}

function OutcomeSectionView(props: Props) {
  const { section } = props;
  const meta = JSON.parse(section.section_meta) as OutcomeSectionMeta;
  const [outcome, setOutcome] = useState<Outcome | null>(null);

  const outcomeQueryKey = [queryKeys.currentOutcome, meta.outcome_id];
  useQuery(outcomeQueryKey, remoteApi.fetchOutcomeDetails, {
    enabled: !!meta.outcome_id,
    onSuccess: (response) => {
      setOutcome(response.data);
    },
  });

  const confidence = meta.outcome_grade_color ?? '';
  const confidenceColor = checkinUtils.confidenceToColor(confidence);
  let doc = new DOMParser().parseFromString(section.body, 'text/html');
  const sectionIsEmpty = !section.body || !doc.body.textContent || doc.body.textContent === '';
  const allowLink = meta.outcome_id !== 'undefined';
  const outcomePath = `#outcome:${meta.outcome_id}:show`;

  return (
    <ViewContainer>
      <Content>
        <HeaderRow>
          <HeaderBlock>
            <KoalaColorBlock color={confidenceColor} dataFor={`outcome-block-${outcome?.nano_slug}`} />
            {allowLink ? (
              <Link to={outcomePath}>
                <b>{meta.outcome_title}</b>
              </Link>
            ) : (
              <b>{meta.outcome_title}</b>
            )}
          </HeaderBlock>
          <KoalaTextBadge
            isLowercase={true}
            variant={checkinUtils.confidenceToVariant(confidence)}
            edge="circle"
            size="small"
            style={{ border: 'none' }}
            maxWidth="20rem"
            dataFor={`outcome-block-${outcome?.nano_slug}`}
          >
            {meta.score}
          </KoalaTextBadge>
        </HeaderRow>
      </Content>
      {!sectionIsEmpty && (
        <DescriptionRow>
          <MarkdownContent source={section.body} />
        </DescriptionRow>
      )}
      {outcome && (
        <ReactTooltip
          place="bottom"
          type="light"
          effect="solid"
          clickable={true}
          delayHide={200}
          delayShow={200}
          className="avatar-tooltip"
          arrowColor="transparent"
          id={`outcome-block-${outcome.nano_slug}`}
        >
          <OutcomeCard outcome={outcome} />
        </ReactTooltip>
      )}
    </ViewContainer>
  );
}
export default React.memo(OutcomeSectionView);

import React from 'react';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { DashboardWidget } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;
  flex-direction: column;
  align-items: center;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

const LargeNumber = styled.div`
  font-size: 8rem;
`;

interface Props {
  widget: DashboardWidget;
}

function ActiveUserWidget(props: Props) {
  const { widget } = props;
  const { t } = useTranslation();

  const activeUsersQueryKey = [queryKeys.dashboardWidgets, widget.id, 'activeUsers'];
  const { data: activeUsersResponse } = useQuery(activeUsersQueryKey, remoteApi.fetchActiveUserWidgetDetails);

  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.activeUsersDetails');
  const activeUserCount = activeUsersResponse ? activeUsersResponse.data : 0;

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        <Container className="panel-checkins-chart">
          <LargeNumber>{activeUserCount}</LargeNumber>
          <span>{t('workspaceDashboards.activeUsersDetails')}</span>
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default ActiveUserWidget;

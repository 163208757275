import React from 'react';
import { ObjectiveSectionMeta, RetrospectiveSection } from 'types';
import styled from 'styled-components';
import theme from 'theme';
import KoalaTextBadge from 'koala/components/TextBadge';
import * as checkinUtils from 'utils/checkinUtils';
import { useTranslation } from 'react-i18next';
import MarkdownContent from 'components/MarkdownContent';
import { Link } from 'react-router-dom';
const ViewContainer = styled.div`
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ql-editor.ql-display-only {
    padding: 0 !important;
  }
`;
const MetaRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  justify-content: space-between;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const DescriptionRow = styled.div`
  border-top: 1px solid ${theme.colors.N10};
  padding: 1.2rem 0 0 0;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  padding: 0 0 1.2rem 0;
`;

interface Props {
  section: RetrospectiveSection;
  allowLink?: boolean;
}

function ObjectiveSectionView(props: Props) {
  const { section, allowLink } = props;
  const { t } = useTranslation();
  const meta = JSON.parse(section.section_meta) as ObjectiveSectionMeta;
  const sectionMeta: ObjectiveSectionMeta = JSON.parse(section.section_meta);
  const objectivePath = `#objective:${sectionMeta.objective_id}:show`;
  const confidence = meta.objective_grade_color ?? '';
  let doc = new DOMParser().parseFromString(section.body, 'text/html');
  const sectionIsEmpty = !section.body || !doc.body.textContent || doc.body.textContent === '';

  return (
    <ViewContainer>
      <Content>
        <MetaRow>
          <KoalaTextBadge variant={checkinUtils.confidenceToVariant(confidence)} isLowercase>
            {meta.objective_grade_label || checkinUtils.confidenceToScore(confidence, t)}
          </KoalaTextBadge>
        </MetaRow>
        <HeaderRow>
          {allowLink && (
            <Link to={objectivePath}>
              <h2>{meta.objective_title}</h2>
            </Link>
          )}
          {!allowLink && <h2>{meta.objective_title}</h2>}

          <KoalaTextBadge variant="blue-light" isLowercase>
            {(meta.objective_outcome_progress_prct * 100).toFixed(0)}%
          </KoalaTextBadge>
        </HeaderRow>
      </Content>
      {!sectionIsEmpty && (
        <DescriptionRow>
          <MarkdownContent source={section.body} />
        </DescriptionRow>
      )}
    </ViewContainer>
  );
}
export default React.memo(ObjectiveSectionView);

/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React, { ChangeEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Base64 } from 'js-base64';

// Routes
import * as routes from 'routes';

// Components
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import SegmentList from './SegmentList';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { Team } from 'types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 18rem;
  justify-content: flex-start;
  height: 100%;
  border-right: 1px solid ${theme.colors.N10};
  overflow: auto;

  font-size: 1.4rem;
  a {
    margin-bottom: 0px;
    padding: ${theme.spacing.x1} ${theme.spacing.x2};

    :hover {
      background: ${theme.colors.N5};
      color: ${theme.colors.N100};
    }
    &.active {
      background: ${theme.colors.N10};
      color: ${theme.colors.N100};
      font-weight: 500;
    }
  }

  @media ${theme.devices.tablet} {
    width: 15rem;
  }

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.N10};
  width: 100%;

  .search {
    border: 1px solid ${theme.colors.N10};
    font-size: 1.2rem;
    height: 2.8rem;
    margin: 0px ${theme.spacing.x1} ${theme.spacing.x1};
    width: unset;
  }
`;

const Header = styled.div`
  margin: ${theme.spacing.x2} ${theme.spacing.x2} ${theme.spacing.x1};
  text-transform: uppercase;
  color: ${theme.colors.N100};
  font-size: 1rem;
  font-weight: 800;
`;

function InboxNav() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const allActiveFilter = Base64.encode('{"plan_status":"is_in_progress","sorting":"plan"}');
  const allDraftFilter = Base64.encode('{"plan_status":"is_draft","sorting":"plan"}');

  const ownedFilter = Base64.encode(
    `{"plan_status":"is_in_progress","membership":[{"value":"${currentMembership.id}","label":"${currentMembership.cached_fullname}"}],"sorting":"plan"}`,
  );
  const contributingFilter = Base64.encode(
    `{"plan_status":"is_in_progress","contributor":[{"value":"${currentMembership.id}","label":"${currentMembership.cached_fullname}"}],"sorting":"plan"}`,
  );

  const insightsOverdue = Base64.encode('{"plan_status":"is_in_progress","sorting":"plan","insights":"is_overdue"}');
  const insightsWithoutOnwer = Base64.encode(
    '{"plan_status":"is_in_progress","sorting":"plan","insights":"without_owner"}',
  );
  const insightsReporting = Base64.encode('{"plan_status":"is_in_progress","sorting":"plan","insights":"reporting"}');

  const performSearch = (newName: string) => {
    setSearchValue(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debouncePerformSearch.current(value);
  };

  // get users teams
  const teamsQueryKey = [queryKeys.teams, currentMembership.id];
  const { data: teamResponse } = useQuery(teamsQueryKey, remoteApi.fetchMembershipTeams);

  let teams: Team[] = [];
  if (teamResponse) {
    teams = teamResponse.data;
  }

  return (
    <Container>
      <SectionContainer>
        <Header>{t('workspaceFilters.segments.title')}</Header>
        <input
          className="search"
          onChange={handleSearch}
          placeholder={t('workspaceFilters.segments.placeholder') ?? 'Search segments'}
        />
        <SegmentList searchValue={searchValue} />
      </SectionContainer>

      {teams.length > 0 && (
        <SectionContainer>
          <Header>{t('workspaceFilters.segments.teams')}</Header>
          {teams.map((team, i) => {
            const teamFilter = Base64.encode(
              `{"plan_status":"is_in_progress","sorting":"plan", "team": [{"value": "${team.id}", "label": "${team.name}"}]}`,
            );
            return (
              <NavLink
                to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
                  ':filter',
                  teamFilter,
                )}
                exact={true}
                key={i}
              >
                {team.name}
              </NavLink>
            );
          })}
        </SectionContainer>
      )}

      <SectionContainer>
        <Header>
          {t('workspaceFilters.segments.allInitiatives', {
            initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
          })}
        </Header>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            allActiveFilter,
          )}
          exact={true}
        >
          {t('workspaceFilters.segments.active')}
        </NavLink>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            allDraftFilter,
          )}
          exact={true}
        >
          {t('workspaceFilters.segments.draft')}
        </NavLink>
      </SectionContainer>

      <SectionContainer>
        <Header>
          {t('workspaceFilters.segments.yourInitiatives', {
            initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
          })}
        </Header>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            ownedFilter,
          )}
          exact={true}
        >
          {t('workspaceFilters.segments.owned')}
        </NavLink>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            contributingFilter,
          )}
          exact={true}
        >
          {t('workspaceFilters.segments.contributing')}
        </NavLink>
      </SectionContainer>

      <SectionContainer>
        <Header>{t('workspaceFilters.insights')}</Header>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            insightsOverdue,
          )}
          exact={true}
        >
          {t('workspaceFilters.overdue')}
        </NavLink>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            insightsWithoutOnwer,
          )}
          exact={true}
        >
          {t('workspaceFilters.withoutOwner')}
        </NavLink>
        <NavLink
          to={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
            ':filter',
            insightsReporting,
          )}
          exact={true}
        >
          {t('workspaceFilters.reporting')}
        </NavLink>
      </SectionContainer>
    </Container>
  );
}

export default InboxNav;

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ReactFlow, {
  Edge,
  Node,
  Background,
  Controls,
  useReactFlow,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
  NodeMouseHandler,
} from 'reactflow';
import styled from 'styled-components';
import useExpandCollapse from './useExpandCollapse';
import theme from 'theme';
import ChildNode from './ChildNode';
import OutcomeNode from './OutcomeNode';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const nodeTypes = {
  child: ChildNode,
  outcome: OutcomeNode,
};

interface Props {
  initialEdges: Edge[];
  initialNodes: Node[];
  fullScreenEnabled: boolean;
  handleOutcomeClicked: (e: React.MouseEvent<HTMLDivElement>, outcomeSlug: string) => void;
}

function OutcomeMap(props: Props) {
  const { initialEdges, initialNodes, fullScreenEnabled, handleOutcomeClicked } = props;
  const reactFlowInstance = useReactFlow();

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialNodes, initialEdges]);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  // set expanded when dependencies expanded
  const toggleNodeExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }
          return n;
        }),
      );
    },
    [setNodes],
  );

  // set expanded when tasks expanded
  const toggleDataExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, dataExpanded: !n.data.dataExpanded },
            };
          }

          return n;
        }),
      );
    },
    [setNodes],
  );

  const initialNodesWithCallbacks = useMemo(
    () =>
      initialNodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          toggleNodeExpand,
          toggleDataExpand,
          handleOutcomeClicked,
        },
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  // Run only once at component mount
  useEffect(() => {
    setEdges([...initialEdges]);
    setNodes(initialNodesWithCallbacks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This function filters the nodes to avoid duplicate
  // This works because findIndex always returns the first element found in an array
  // See https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const filteredNodes = nodes.filter(
    (node, index, self) => index === self.findIndex((reference) => reference.id === node.id),
  );
  // This function filters the edges to avoid duplicate (see above)
  const filteredEdges = edges.filter(
    (edge, index, self) => index === self.findIndex((reference) => reference.id === edge.id),
  );

  // handle expand, collapse and layout
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(filteredNodes, filteredEdges, {
    layoutNodes: true,
  });

  // fit graph in screen
  useEffect(() => {
    const zoom = fullScreenEnabled ? 0.8 : 0.3;
    const timer = setTimeout(() => {
      reactFlowInstance.fitView({ duration: 300, maxZoom: zoom, minZoom: zoom });
    }, 200); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [reactFlowInstance, fullScreenEnabled]);

  const DisplayControls = () => {
    if (fullScreenEnabled) {
      return <Controls />;
    }
    return null;
  };

  return (
    <Container>
      <ReactFlow
        id="outcome-minimap"
        nodes={visibleNodes}
        edges={visibleEdges}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        minZoom={0.1}
        maxZoom={2}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        style={{ background: theme.colors.N3 }}
        proOptions={{ account: 'paid-pro', hideAttribution: true }}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.8,
        }}
      >
        <Background color={theme.colors.N20} size={2} />
        <DisplayControls />
      </ReactFlow>
    </Container>
  );
}

export default OutcomeMap;

import queryKeys from 'config/queryKeys';
import React, { useState, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Dashboard, Membership, Workspace } from 'types';
import parse from 'parse-link-header';

import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import { DashboardActions, DashboardLoadMore, EmptyDashboardSection } from '.';
import { useTranslation } from 'react-i18next';
import DashboardLine from './DashboardLine';
import KoalaButton from 'koala/components/Button';
import EmptyStatePanel from 'components/EmptyStatePanel';
import styled from 'styled-components';
import theme from 'theme';
import KoalaLoader from 'koala/components/Loader';
import DashboardTeaser from './dashboards_teaser.png';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

const ListingContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;

  img {
    max-width: 80rem;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.2);
  }
`;
const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  &.all-dashboards {
    border-top: 1px solid ${theme.colors.blockBorder};
  }
`;

const SearchInput = styled.input`
  border: 1px solid ${theme.colors.inputBorder};
  width: 30%;
`;

interface Props {
  workspace: Workspace;
  currentMembership: Membership;
}

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

function CustomDashboards(props: Props) {
  const { currentMembership, workspace } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nameToSearch, setNameToSearch] = useState('');
  const handleCreateDashboard = () => {
    dispatch(setGlobalModalContent('dashboard::create'));
  };

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  const dashboardQueryKey = [
    queryKeys.dashboards,
    'search',
    {
      workspaceSlug: workspace.slug,
      limit: 10,
      filter: {
        title: nameToSearch,
        premade_type: null,
        is_favorited_by: {
          is_favorite: false,
          membership_id: currentMembership.id,
        },
      },
    },
  ];
  const {
    data: dashboardsResponse,
    isLoading: dashboardsLoading,
    isFetching,
    fetchMore,
    canFetchMore,
    isFetchingMore,
  } = useInfiniteQuery(dashboardQueryKey, remoteApi.searchDashboards, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });
  const favoritedDashboardQueryKey = [
    queryKeys.dashboards,
    'favorites',
    {
      workspaceSlug: workspace.slug,
      limit: 10,
      filter: {
        title: nameToSearch,
        premade_type: null,
        is_favorited_by: {
          is_favorite: true,
          membership_id: currentMembership.id,
        },
      },
    },
  ];
  const {
    data: dashboardsFavoritedResponse,
    isLoading: isLoadingFavorites,
    isFetching: isFetchingFavorites,
    canFetchMore: canFetchMoreFavorites,
    fetchMore: fetchMoreFavorites,
    isFetchingMore: isFetchingMoreFavorites,
  } = useInfiniteQuery(favoritedDashboardQueryKey, remoteApi.searchDashboards, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  let favoriteDashboards: AxiosResponse<Dashboard[]>[] = dashboardsFavoritedResponse ?? [];

  let dashboards: AxiosResponse<Dashboard[]>[] = dashboardsResponse ?? [];
  let isEmpty = false;
  const isDashboardsEmpty = dashboards.length === 0 || dashboards[0].data.length === 0;
  const isFavoriteEmpty = favoriteDashboards.length === 0 || favoriteDashboards[0].data.length === 0;
  if (isDashboardsEmpty && isFavoriteEmpty && !nameToSearch) {
    isEmpty = true;
  }

  const isLoading = dashboardsLoading || isLoadingFavorites || isFetching || isFetchingFavorites;

  if (isLoading) {
    return (
      <EmptyStatePanel>
        <KoalaLoader />
      </EmptyStatePanel>
    );
  }

  return (
    <>
      <DashboardActions>
        <SearchInput
          placeholder={t('workspaceDashboards.filter') ?? 'Filter dashboards by name'}
          onChange={handleSearch}
        />
        <KoalaButton onClick={handleCreateDashboard}>{t('workspaceDashboards.create')}</KoalaButton>
      </DashboardActions>
      <ListingContainer>
        {isEmpty && (
          <EmptyStatePanel>
            <PanelContainer>
              <h3>{t('workspaceDashboards.empty.title')}</h3>
              <img src={DashboardTeaser} alt="Tability dashboards" />
              <p>
                {t('workspaceDashboards.empty.description')}{' '}
                <a
                  href="https://guides.tability.app/docs/features/reporting/custom-dashboards"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('shared.learnMore')}
                </a>
              </p>
            </PanelContainer>
          </EmptyStatePanel>
        )}

        {!isEmpty && (
          <>
            <GroupSeparator className="item">{t('workspaceDashboards.favorites')}</GroupSeparator>
            {isFavoriteEmpty && (
              <EmptyDashboardSection className="item">{t('workspaceDashboards.noFavorites')}</EmptyDashboardSection>
            )}
            {!isLoadingFavorites &&
              favoriteDashboards.map((group, i: number) =>
                group.data.map((dashboard: Dashboard) => (
                  <DashboardLine dashboard={dashboard} workspace={workspace} isFavorite={true} />
                )),
              )}
            {canFetchMoreFavorites && (
              <DashboardLoadMore>
                <KoalaButton
                  appearance="secondary"
                  onClick={() => fetchMoreFavorites()}
                  loading={!!isFetchingMoreFavorites}
                >
                  {t('shared.loadMore')}
                </KoalaButton>
              </DashboardLoadMore>
            )}
            <GroupSeparator className="all-dashboards">{t('workspaceDashboards.allDashboards')}</GroupSeparator>
            {isDashboardsEmpty && (
              <EmptyDashboardSection>{t('workspaceDashboards.noDashboards')}</EmptyDashboardSection>
            )}
            {!dashboardsLoading &&
              dashboards.map((group, i: number) =>
                group.data.map((dashboard: Dashboard) => (
                  <DashboardLine dashboard={dashboard} workspace={workspace} isFavorite={false} />
                )),
              )}
            {canFetchMore && (
              <DashboardLoadMore>
                <KoalaButton onClick={() => fetchMore()} appearance="secondary" loading={!!isFetchingMore}>
                  {t('shared.loadMore')}
                </KoalaButton>
              </DashboardLoadMore>
            )}
          </>
        )}
      </ListingContainer>
    </>
  );
}

export default CustomDashboards;

import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import { Base64 } from 'js-base64';
import KoalaLoader from 'koala/components/Loader';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';
import KoalaConfidencePie from 'koala/components/ConfidencePie';
import { StatsResult } from 'pages/WorkspaceOutcomes';
import { VictoryTooltip } from 'victory';
import KoalaColorBlock from 'koala/components/ColorBlock';
import { getNCSTextColor } from 'utils/outcomeUtils';

const OutcomeContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background: #fff;
  justify-content: start;

  .item:last-of-type {
    border: none;
  }
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
`;

const PieContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x2} 0px ${theme.spacing.x1};
`;

const ProgressContainer = styled.div<{ color: string }>`
  font-weight: 700;
  display: flex;
  font-size: 2.8rem;
  justify-content: center;
  color: ${(props) => props.color};
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.x4};
  height: 100%;
`;
const KeyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  min-width: 12rem;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
`;

interface PieData {
  x: number;
  y: number;
  color: string;
  label?: string;
}

interface Props {
  widget: DashboardWidget;
}

function OutcomeStatsWidget(props: Props) {
  const { widget } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [outcomesStats, setOutcomesStats] = useState<StatsResult | null>(null);

  const segment: Segment = widget.source ? widget.source : null;
  let filter;
  if (segment?.id) {
    filter = segment ? segment.filter_hash : null;
  } else if (widget.widget_meta?.filter_hash) {
    filter = widget.widget_meta.filter_hash;
  }

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};

  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);
  // Query keys and query params

  const widgetTitle: string = widget.title ? widget.title : t('shared.ncs.full');

  // Get the outcome stats
  const queryKeyStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug: workspace.slug,
      filter: filterParams,
    },
  ];
  const { isLoading: isLoadingStats }: any = useQuery(queryKeyStats, remoteApi.fetchOutcomesStats, {
    onSuccess: (response: any) => {
      setOutcomesStats(response.data);
    },
  });

  const data: PieData[] = [];
  let dataIndex = 1;

  const segmentRoute =
    segment &&
    WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
      .replace(':segmentId', segment.id)
      .replace(':filter', segment.filter_hash);

  if (!outcomesStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <OutcomeContainer>
          {segment && (
            <SourceTitle>
              <Link to={segmentRoute} className="widget-source">
                {segment.title}
              </Link>
            </SourceTitle>
          )}
          <KoalaConfidencePie data={data} size="large" />
        </OutcomeContainer>
      </WidgetContainer>
    );
  }

  const { red_outcomes_count, yellow_outcomes_count, green_outcomes_count, grey_outcomes_count, total_outcomes_count } =
    outcomesStats;

  if (red_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: red_outcomes_count,
      color: theme.colors.red,
      label: 'off track',
    });
  }
  if (yellow_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: yellow_outcomes_count,
      color: theme.colors.yellow,
      label: 'at risk',
    });
  }
  if (green_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: green_outcomes_count,
      color: theme.colors.green,
      label: 'on track',
    });
  }
  if (grey_outcomes_count) {
    data.push({
      x: dataIndex++,
      y: grey_outcomes_count,
      color: theme.colors.grey,
      label: 'pending',
    });
  }

  if (isLoadingStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  const labelComponent = (
    <VictoryTooltip
      flyoutPadding={{ top: 4, bottom: 4, left: 8, right: 8 }}
      cornerRadius={4}
      flyoutStyle={{
        fill: theme.colors.N5,
        stroke: theme.colors.N5,
      }}
      style={[
        {
          fontSize: '12px',
          fontFamily: theme.font.fontFamily,
        },
      ]}
    />
  );

  const ncs = (green_outcomes_count / total_outcomes_count) * 100 - (red_outcomes_count / total_outcomes_count) * 100;
  const ncsColor = getNCSTextColor(ncs);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <OutcomeContainer>
        {segment && (
          <SourceTitle>
            <Link to={segmentRoute} className="widget-source">
              {segment.title}
            </Link>
          </SourceTitle>
        )}
        <ChartContainer>
          <PieContainer>
            <KoalaConfidencePie data={data} size="large" tooltipComponent={labelComponent} />
            <ProgressContainer color={ncsColor}>
              {Math.round(ncs)} {t('shared.ncs.acronym')}
            </ProgressContainer>
          </PieContainer>
          <KeyContainer>
            <StatRow>
              <KoalaColorBlock color={theme.colors.G50} />
              {green_outcomes_count} {t('shared.onTrack')}
            </StatRow>
            <StatRow>
              <KoalaColorBlock color={theme.colors.Y50} />
              {yellow_outcomes_count} {t('shared.atRisk')}
            </StatRow>
            <StatRow>
              <KoalaColorBlock color={theme.colors.R50} />
              {red_outcomes_count} {t('shared.offTrack')}
            </StatRow>
            <StatRow>
              <KoalaColorBlock color={theme.colors.N50} />
              {grey_outcomes_count} {t('shared.pending')}
            </StatRow>
          </KeyContainer>
        </ChartContainer>
      </OutcomeContainer>
    </WidgetContainer>
  );
}

export default OutcomeStatsWidget;

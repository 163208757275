import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import { Dashboard } from 'types';

import * as remoteApi from 'api/remote';
import { DashboardActions, EmptyDashboardSection } from '.';
import { useTranslation } from 'react-i18next';
import KoalaButton from 'koala/components/Button';
import DashboardContent from 'pages/WorkspaceDashboard/View/DashboardContent';
import KoalaLoader from 'koala/components/Loader';

interface Props {
  type: 'people' | 'outcomes' | 'initiatives';
  workspaceSlug: string;
}

function PremadeDashboard(props: Props) {
  const { type, workspaceSlug } = props;
  const { t } = useTranslation();
  const [dashboard, setDashboard] = useState<Dashboard>();

  // Get the dashboard details

  const dashboardQueryKey = [
    queryKeys.dashboards,
    'custom',
    {
      workspaceSlug,
      limit: 1,
      filter: {
        premade_type: type,
      },
    },
  ];
  const staleTime = 0;
  const { isLoading, isFetching } = useQuery(dashboardQueryKey, remoteApi.searchDashboards, {
    staleTime,
    onSuccess: (response) => {
      const data = response.data;
      setDashboard(data[0]);
    },
  });

  const [copyDashboardMutation, { isLoading: isCopying }] = useMutation(remoteApi.copyDashboard, {
    onSuccess: (response: any) => {
      queryCache.invalidateQueries(queryKeys.dashboards);
    },
  });

  const duplicateDashboard = () => {
    if (dashboard) {
      copyDashboardMutation(dashboard.id);
    }
  };
  if (isLoading || isFetching) {
    return (
      <EmptyDashboardSection>
        <KoalaLoader />
      </EmptyDashboardSection>
    );
  }
  if (!dashboard) {
    return <EmptyDashboardSection>{t('workspaceDashboards.noDashboard')}</EmptyDashboardSection>;
  }

  return (
    <>
      <DashboardActions>
        <div></div>
        <KoalaButton appearance="secondary" onClick={duplicateDashboard} disabled={isCopying} loading={isCopying}>
          {t('workspaceDashboards.copyDashboard')}
        </KoalaButton>
      </DashboardActions>
      <DashboardContent dashboard={dashboard} />
    </>
  );
}

export default PremadeDashboard;

import React, { memo, useState } from 'react';
import { Edge, Handle, Node, NodeProps, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import OutcomeContent from './OutcomeContent';
import KoalaButton from 'koala/components/Button';
import { Outcome, Relationship } from 'types';
import { useTranslation } from 'react-i18next';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaIcon from 'koala/components/Icons';
import { edgeDefaultsUp, nodeDefaults } from 'utils/mapUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const PlanContainer = styled.div`
  width: 350px;
  height: 300px;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;

  &.show-data {
    height: 450px;
  }
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const NodeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

function ChildNode(node: NodeProps) {
  const { data } = node;
  const { outcome, dataExpanded, expanded, toggleNodeExpand, toggleDataExpand, handleOutcomeClicked } = data;
  const [dependentOutcomes, setDependentOutcomes] = useState<Outcome[]>([]);
  const { t } = useTranslation();
  // We use addNodes and addEdges to add to the flow graph state
  const { addNodes, addEdges } = useReactFlow();

  // Get the outcomes
  const queryKey = [queryKeys.relationships, outcome.id, 'outcomes'];
  const { isLoading } = useQuery(queryKey, remoteApi.fetchRelationshipsForOutcome, {
    onSuccess: (response: any) => {
      const outcomeRelationships: Relationship[] = response.data;
      const dependents: Outcome[] = outcomeRelationships
        .filter(
          (r) => r.target_id === outcome.id && r.relationship_type === 'contributes_to' && r.source_type === 'Outcome',
        )
        .map((r) => r.source as Outcome);
      setDependentOutcomes(dependents);
      const newNodesToAdd: Node<any>[] = dependents.map((newOutcome) => ({
        id: newOutcome.nano_slug,
        type: 'child',
        data: {
          outcome: newOutcome,
          expanded: false,
          toggleDataExpand,
          toggleNodeExpand,
          handleOutcomeClicked,
          dataExpanded: false,
          isExpandable: true,
          parentId: outcome.nano_slug,
        },
        ...nodeDefaults,
      }));

      // Create the corresponding edges

      const newEdgesToAdd: Edge[] = dependents.map((newOutcome) => ({
        id: `${outcome.nano_slug} -> ${newOutcome.nano_slug}`,
        target: newOutcome.nano_slug,
        source: outcome.nano_slug,
        ...edgeDefaultsUp,
      }));

      // Add nodes and edges to the flow
      addNodes(newNodesToAdd);
      addEdges(newEdgesToAdd);
    },
  });

  // used to show children
  const toggleNodeExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
    toggleNodeExpand(event, node);
  };

  // used to show tasks
  const toggleDataExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
    toggleDataExpand(event, node);
  };

  const expandedDataClassName = dataExpanded ? 'show-data' : '';
  const hasChildren = dependentOutcomes.length > 0 && !isLoading;

  return (
    <Container onClick={(e) => e.stopPropagation()} className={expandedDataClassName}>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <NodeContent>
        <PlanContainer className={`${expandedDataClassName}`}>
          <OutcomeContent
            outcome={outcome}
            dataExpanded={dataExpanded}
            toggleDataExpanded={toggleDataExpanded}
            handleOutcomeClicked={handleOutcomeClicked}
          />
        </PlanContainer>
        {hasChildren && !expanded && (
          <div>
            <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
              <KoalaIcon iconName="downChevron" style={{ transform: 'rotate(-90deg)' }} />
              {t('workspacePlan.map.expand')}
            </KoalaButton>
          </div>
        )}
        {hasChildren && expanded && (
          <div>
            <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
              <KoalaIcon iconName="minus" />
              {t('workspacePlan.map.collapse')}
            </KoalaButton>
          </div>
        )}
      </NodeContent>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(ChildNode);

import React from 'react';
import styled from 'styled-components';
import { DashboardWidget, Workspace } from 'types';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import { DragHandle, WidgetContainer } from '.';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: auto;

  h2 {
    margin: auto ${theme.spacing.x2};
  }
`;

interface Props {
  widget: DashboardWidget;
}

function HeaderBlockWidget(props: Props) {
  const { widget } = props;
  const { t } = useTranslation();
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.textBlock');
  // Query keys and query params

  let text = 'Header';
  if (widget.widget_meta.text) {
    text = widget.widget_meta.text;
  } else if (widget.widget_meta.premade) {
    text = t(`workspaceDashboards.${widget.widget_meta.premade}`, {
      outcome: translate(workspace, CustomTermKey.OUTCOME, 1),
    });
  }

  return (
    <WidgetContainer>
      <ContentContainer>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <h2>{text}</h2>
      </ContentContainer>
    </WidgetContainer>
  );
}

export default HeaderBlockWidget;

import React from 'react';

import styled from 'styled-components';
import theme from 'theme';

import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useQueryCache, useMutation } from 'react-query';
import { updateWorkspace } from 'api/remote';
import { sessionActions } from 'state/actions/';

import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';

// Components
const Container = styled.div`
  font-size: 1.4rem;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.T30};
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: ${theme.spacing.x2};

  a {
    color: ${theme.colors.black};
    &:hover {
      text-decoration: underline;
    }
  }

  b {
    color: ${theme.colors.R80};
  }

  &:hover {
    background: ${theme.colors.T40};
    cursor: pointer;
    transition: all 0.1s ease-out;
  }

  &.warning {
    background: ${theme.colors.Y30};
    color: ${theme.colors.N100};
    .btn--subtle {
      border: 1px solid ${theme.colors.N100};
      color: ${theme.colors.N100} !important;
    }
    &:hover {
      background: ${theme.colors.Y40};
      cursor: pointer;
      transition: all 0.1s ease-out;
    }
  }

  animation: mymove 0.5s ease-out forwards;

  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .btn--subtle {
    border: 1px solid #fff;
    color: #fff !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    &:first-of-type,
    &:last-of-type {
      width: 25rem;
    }
  }
`;
const InlineElement = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

function Quickstart() {
  const history = useHistory();
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const queryKeyWorkspace = [queryKeys.currentWorkspace];
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Mutation that will update the plan in the backend
  const [updateWorkspaceMutation]: [any, any] = useMutation(updateWorkspace, {
    onSuccess: (response) => {
      queryCache.setQueryData(queryKeyWorkspace, response);
      dispatch(sessionActions.setCurrentWorkspace(response.data));
    },
  });

  const hideG2ReviewPromo = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const workspaceParams = {
      show_workspace_tour: false,
    };
    const mutationParams = {
      workspaceId: currentWorkspace.slug,
      workspace: workspaceParams,
    };
    updateWorkspaceMutation(mutationParams);
  };

  if (!currentWorkspace.show_workspace_tour) {
    return null;
  }

  return (
    <Container onClick={() => history.push(`#g2::credits`)}>
      <Wrapper>
        <div />
        <div>
          <InlineElement>
            💸 Get $100 credits to extend your trial
            <KoalaIconButton iconName="close" onClick={hideG2ReviewPromo} size="xsmall" edge="square" />
          </InlineElement>
        </div>
        <div />
      </Wrapper>
    </Container>
  );
}

export default Quickstart;

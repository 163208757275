import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { KoalaSizes } from 'koala/shared/global';
import { IntegrationUser, Membership } from 'types';
import KoalaIcon from '../Icons';
import Icon, { IconButton } from './Avatar.styles';
import { ReactElement } from 'react-markdown';
import ProfileCard from 'components/ProfileCard';
import IntegrationProfileCard from 'components/ProfileCard/IntegrationProfileCard';
import { useTranslation } from 'react-i18next';
import theme from 'theme';

interface iconItems {
  tooltipId: string;
  icon: ReactElement;
  isIntegration?: boolean;
}
interface Props {
  membership?: Membership | null;
  isIntegration?: boolean;
  /** either the KoalaSize or the rem value */
  size?: (typeof KoalaSizes)[number] | number;
  handleClick?: (e: any) => void;
  tooltipType: 'card' | 'text' | 'none';
  /** added after the users name  */
  tooltipText?: string;
  addBorder?: boolean;
  integrationAssignee?: IntegrationUser;
  integrationType?: string;

  className?: string;
  style?: React.CSSProperties;
}

const Container = styled.div`
  .avatar-tooltip {
    padding: 0px;
    background: transparent;
    border: none;

    &.show {
      opacity: 1 !important;
    }
  }
`;
const bgVariants = ['purple', 'red', 'green', 'yellow', 'blue', 'orange', 'teal'] as const;

function JiraIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-jira-${randomIndex}`;

  let avatarImg = integrationAssignee?.avatarImage;
  let color = integrationAssignee ? integrationAssignee.color : 'blue';
  let avatarInitials = integrationAssignee ? integrationAssignee.initials : 'NA';

  let avatarClass = className ?? '';
  avatarClass += avatarImg ? '' : ` avatar--${color}`;
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    tooltipId,
    icon,
    isIntegration: true,
  };
}

function ClickupIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-clickup-${randomIndex}`;
  const avatarImg = integrationAssignee?.avatarImage;
  const avatarInitials = integrationAssignee?.initials;
  const color = integrationAssignee ? integrationAssignee.color : theme.colors.N40;
  const icon = !!handleClick ? (
    <IconButton
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    tooltipId,
    icon,
    isIntegration: true,
  };
}

function LinearIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-clickup-${randomIndex}`;
  const avatarInitials = integrationAssignee?.initials;
  const color = integrationAssignee ? integrationAssignee.color : theme.colors.N40;
  const avatarImg = integrationAssignee?.avatarImage;

  const icon = !!handleClick ? (
    <IconButton
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={className}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    tooltipId,
    icon,
    isIntegration: true,
  };
}

function AsanaIcon(props: Props): iconItems {
  const { handleClick, integrationAssignee, className, size } = props;
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-clickup-${randomIndex}`;
  const avatarInitials = integrationAssignee?.initials;
  const color = integrationAssignee ? integrationAssignee.color : theme.colors.N40;
  const icon = !!handleClick ? (
    <IconButton
      className={className}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={className}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      style={{ backgroundColor: color, color: 'white' }}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    tooltipId,
    icon,
    isIntegration: true,
  };
}

function UserIcon(props: Props): iconItems {
  const { membership, className, size, handleClick, isIntegration } = props;
  if (!membership || !membership.user) {
    return { tooltipId: '', icon: <></>, isIntegration: false };
  }
  const user = membership.user;
  let avatarImg = null;
  let name = 'Not assigned';
  let avatarInitials = '';
  let hash = 10;
  let avatarClass = className ?? '';

  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-${user ? user.id : 'notassigned'}-${randomIndex}`;
  avatarImg = user && user.avatar_url ? `url(${user.avatar_url})` : null;
  name = user ? user.fullname || user.email : 'Account deleted';
  hash = user.id.charCodeAt(0) + user.id.charCodeAt(10) + user.id.charCodeAt(1) + user.id.charCodeAt(2);
  avatarClass += avatarImg ? '' : ` avatar--${bgVariants[hash % bgVariants.length]}`;
  if (name) {
    avatarInitials = name
      .trim()
      .split(' ')
      .map((x) => x.charAt(0).toUpperCase())
      .slice(0, 2)
      .join('');
  } else if (!user.fullname && !user.avatar_url && user.email) {
    avatarInitials = user.email.trim().charAt(0).toUpperCase();
  }
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      {avatarInitials}
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      bgImage={avatarImg ?? ''}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      {avatarInitials}
    </Icon>
  );

  return {
    tooltipId,
    icon,
    isIntegration,
  };
}

function UnassignedIcon(props: Props): iconItems {
  const { className, size, handleClick } = props;
  let avatarClass = className ?? '';
  const randomIndex = Math.floor(Math.random() * 100000 + 1);
  const tooltipId = `avatar-notassigned-${randomIndex}`;
  const icon = !!handleClick ? (
    <IconButton
      className={avatarClass}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
      type="button"
    >
      <KoalaIcon iconName="person" iconSize={size} />
    </IconButton>
  ) : (
    <Icon
      className={avatarClass}
      onClick={handleClick}
      data-tip
      data-for={tooltipId}
      size={typeof size === 'number' ? size : undefined}
    >
      <KoalaIcon iconName="person" iconSize={size} />
    </Icon>
  );
  return { tooltipId, icon, isIntegration: false };
}

function AvatarIcon(props: Props): iconItems {
  const { size, className, addBorder, membership, integrationAssignee, integrationType } = props;
  let avatarClass = `avatar-icon ${className ?? ''}`;
  if (typeof size === 'string') {
    avatarClass += ` avatar--${size}`;
  } else if (typeof size === 'number') {
    avatarClass += ` avatar--custom-size`;
  }
  if (addBorder) {
    avatarClass += ` avatar--border`;
  }

  const user = membership?.user;

  const isIntegration = !!integrationAssignee;
  if (membership && user) {
    return UserIcon({ ...props, className: avatarClass, isIntegration });
  } else if (integrationAssignee && integrationType === 'jira_cloud') {
    return JiraIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'clickup') {
    return ClickupIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'asana') {
    return AsanaIcon({ ...props, className: avatarClass });
  } else if (integrationAssignee && integrationType === 'linear') {
    return LinearIcon({ ...props, className: avatarClass });
  } else {
    return UnassignedIcon({ ...props, className: avatarClass });
  }
}

const KoalaAvatar: React.FunctionComponent<Props> = (props: Props) => {
  const {
    tooltipType,
    tooltipText,
    className,
    style,
    membership,
    integrationAssignee,
    integrationType,
    isIntegration,
  } = props;
  const { t } = useTranslation();

  const { tooltipId, icon } = AvatarIcon(props);

  if (tooltipType === 'text') {
    return (
      <Container style={style} className={`avatar ${className ?? ''}`}>
        {icon}
        <ReactTooltip id={tooltipId} effect="solid">
          {membership?.cached_fullname ?? membership?.cached_email ?? t('shared.notAssigned')}
          {tooltipText}
        </ReactTooltip>
      </Container>
    );
  } else if (tooltipType === 'card') {
    return (
      <Container style={style} className={`avatar ${className ?? ''}`}>
        {icon}
        {tooltipType === 'card' && (
          <ReactTooltip
            place="bottom"
            type="light"
            id={tooltipId}
            effect="solid"
            clickable={true}
            delayHide={500}
            delayShow={200}
            className="avatar-tooltip"
            arrowColor="transparent"
          >
            {!isIntegration && <ProfileCard membership={membership ?? null} />}
            {isIntegration && (
              <IntegrationProfileCard
                integrationAssignee={integrationAssignee ?? null}
                integrationType={integrationType}
                membership={membership ?? null}
              />
            )}
          </ReactTooltip>
        )}
      </Container>
    );
  }

  return (
    <Container style={style} className={`avatar ${className ?? ''}`}>
      {icon}
    </Container>
  );
};

KoalaAvatar.defaultProps = {
  tooltipType: 'card',
  size: 'medium',
  addBorder: false,
};

export default KoalaAvatar;

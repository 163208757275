import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import KoalaLoader from 'koala/components/Loader';
import { DashboardWidget, PlanStats } from 'types';
import Chart from 'components/CheckinChart';
import { format, getTime, parseISO, toDate } from 'date-fns';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryLegend, VictoryVoronoiContainer } from 'victory';
import KoalaIcon from 'koala/components/Icons';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface ChartProps {
  targetData: any;
  outcomesData: any;
  initiativesData: any;
  width: number;
  height: number;
}
function RenderChart(props: ChartProps) {
  const { targetData, outcomesData, initiativesData, width, height } = props;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const label = width / 2;
  return (
    <VictoryChart
      width={width}
      height={height - 10}
      domainPadding={{ x: 0, y: 20 }}
      padding={{ top: 10, bottom: 30, left: 35, right: 20 }}
      minDomain={{ y: 0 }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryLegend
        x={label - 150}
        y={0}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 12, fontFamily: theme.font.fontFamily, fill: theme.colors.N80 } }}
        data={[
          {
            name:
              t('workspacePlan.insights.outcomeProgress', {
                outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
              }) ?? 'Outcome Progress',
            symbol: { fill: theme.colors.T50 },
          },
          {
            name:
              t('workspacePlan.insights.initiativeProgress', {
                initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
              }) ?? 'Initiative Progress',
            symbol: { fill: theme.colors.V50 },
          },
        ]}
      />
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryArea
          style={{
            data: {
              fill: 'transparent',
              fillOpacity: 0.5,
              stroke: theme.colors.N10,
              strokeWidth: 2,
            },
          }}
          data={targetData}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.T50,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeWidth: 3,
              // strokeAreacap: 'round',
            },
          }}
          data={outcomesData}
          interpolation="monotoneX"
          labels={({ datum }: any) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.V50,
              strokeWidth: 3,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeLinecap: 'round',
            },
          }}
          data={initiativesData}
          interpolation="monotoneX"
          labels={({ datum }: any) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
      </VictoryGroup>
    </VictoryChart>
  );
}

interface Props {
  widget: DashboardWidget;
}

function WorkspaceOutcomeTaskGraphWidget(props: Props) {
  const { widget } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.WorkspaceOutcomeTaskGraph', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      });

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  const queryKey = [queryKeys.dashboardWidgets, widget.id, 'outcomeTasks'];
  const { data: dataResponse, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeTasksWidgetDetails);

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef} />
      </WidgetContainer>
    );
  }

  const trends: PlanStats[] = dataResponse?.data;

  const targetData: any[] = [];
  const outcomesData: any[] = [];
  const initiativesData: any[] = [];

  const finishDate: Date = new Date();

  const lastPoint = {
    x: finishDate,
    y: 100,
    timestamp: getTime(finishDate),
  };

  targetData.push(lastPoint);

  const trendsMap: { [key: string]: { outcomeSum: number; initiativeSum: number; count: number } } = {};

  trends.forEach((trend: any) => {
    const date = parseISO(trend.reference_date);
    const dateString = format(date, 'yyyy-MM-dd'); // Format date to string for grouping

    // Initialize the entry for the date if it doesn't exist
    if (!trendsMap[dateString]) {
      trendsMap[dateString] = { outcomeSum: 0, initiativeSum: 0, count: 0 };
    }

    // Accumulate the sums and count
    trendsMap[dateString].outcomeSum += trend.outcome_progress_prct;
    trendsMap[dateString].initiativeSum += trend.initiative_progress_prct;
    trendsMap[dateString].count += 1;
  });

  // Calculate averages and populate outcomesData and initiativesData
  Object.keys(trendsMap).forEach((dateString) => {
    const { outcomeSum, initiativeSum, count } = trendsMap[dateString];
    const averageOutcome = (outcomeSum / count) * 100; // Convert to percentage
    const averageInitiative = (initiativeSum / count) * 100; // Convert to percentage

    const date = new Date(dateString);

    // Create the point using the average data
    outcomesData.push({
      x: date,
      y: averageOutcome,
      timestamp: getTime(date),
      displayText: `${Math.round(averageOutcome)}%`,
    });

    initiativesData.push({
      x: date,
      y: averageInitiative,
      timestamp: getTime(date),
      displayText: `${Math.round(averageInitiative)}%`,
    });
  });

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        <Container className="panel-checkins-chart" ref={chartRef}>
          <RenderChart
            width={dimensions.width}
            height={dimensions.height}
            targetData={targetData}
            outcomesData={outcomesData}
            initiativesData={initiativesData}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default WorkspaceOutcomeTaskGraphWidget;

/**
 * Section component
 */
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import * as TabilityTypes from '../../../types';
import styled from 'styled-components';

// Actions
import {
  editorCreateInitiative,
  editorSetDisplayNewBlockForm,
  editorSelectBlockBelow,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import InitiativeLabel from './InitiativeLabel';
import TitleInput from './TitleInput';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaButton from 'koala/components/Button';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useTranslation } from 'react-i18next';

const InitiativeContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  .block-title {
    flex: 1;
  }
`;

interface Props {
  selectedInitiativeId: string;
  outcomeId: string;
}

function NewInitiativeForm(props: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedInitiativeId, outcomeId } = props;

  const [title, setTitle] = useState('');

  const selectedInitiative: TabilityTypes.Initiative = useSelector(
    (state: any) => state.editorEntities.initiatives[selectedInitiativeId],
  );
  const currentWorkspaceId = useSelector((state: any) => state.editorUI.currentWorkspaceId);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // Handles changes to the title
  const handleChange = (e: any) => {
    e.preventDefault();
    let newTitle = e.target.value || '';

    newTitle = newTitle.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines

    setTitle(newTitle);
  };

  const addInitiative = (title: string) => {
    const newInitiative = {
      id: uuidv4(),
      nano_slug: nanoid(12),
      workspace_id: currentWorkspaceId,
      outcome_id: selectedInitiative.outcome_id,
      title,
      rank: '',
      state: 'open',
    };
    dispatch(editorCreateInitiative(newInitiative, selectedInitiative));
  };

  const handleBlur = (e: any) => {
    if (title.length > 0) {
      addInitiative(title);
      dispatch(editorSetDisplayNewBlockForm(false));
    }
  };

  const handlePress = (e: any) => {
    if (e.keyCode === 27) {
      // Esc key
      e.preventDefault();
      if (title.length > 0) {
        addInitiative(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    // Disable field if we press delete and no text is entered
    if (e.keyCode === 8) {
      // Delete key
      const value = e.target.value;
      if (!value) {
        e.preventDefault();
        dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 13) {
      // Enter key
      e.preventDefault();
      if (title.length > 0) {
        addInitiative(title);
      } else {
        //dispatch(editorSetDisplayNewBlockForm(false));
      }
    }

    if (e.keyCode === 38) {
      // Arrow up
      e.preventDefault();
      if (title.length > 0) {
        addInitiative(title);
      }
      dispatch(editorSetDisplayNewBlockForm(false));
    }

    if (e.keyCode === 40) {
      // Arrow down
      e.preventDefault();
      if (title.length > 0) {
        addInitiative(title);
      }
      dispatch(editorSelectBlockBelow());
    }
  };

  const initLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  const placeholder =
    t(`workspacePlan.write.anotherInitiative`, { label: initLabel }) ?? `Is there another ${initLabel} that can help?`;

  const generateInitiatives = () => {
    // const action = `outcome:${outcomeId}:populate.ai`;
    // dispatch(setGlobalModalContent(action));
    dispatch(setGlobalModalContent(`outcome:${outcomeId}:chat.ai.initiatives`));
  };

  return (
    <BlockGrid className={`initiative`}>
      <BlockGutter />
      <BlockContent className={'initiative'}>
        <InitiativeContentWrapper>
          <InitiativeLabel>&#10003;</InitiativeLabel>
          <TitleInput
            text={title}
            type={'initiative'}
            isNewInput={true}
            handleBlur={handleBlur}
            placeholder={placeholder}
            handleChange={handleChange}
            handlePress={handlePress}
          />
        </InitiativeContentWrapper>
      </BlockContent>
      <BlockMeta>
        {currentWorkspace.open_ai_enabled && (
          <KoalaButton appearance="subtle" size="small" onClick={generateInitiatives}>
            {t(`workspacePlan.write.generateItem`, {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </KoalaButton>
        )}
      </BlockMeta>
    </BlockGrid>
  );
}

export default NewInitiativeForm;

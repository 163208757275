import React, { useEffect, useState } from 'react';
import * as TabilityTypes from 'types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Base64 } from 'js-base64';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';
import * as insightsUtils from 'utils/insights';
import { Helmet } from 'react-helmet';
import { hasPremiumSubscription, hasTrial } from 'utils/workspaceUtils';
import { useHistory, useLocation } from 'react-router-dom';

// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';

// API and State
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as routes from 'routes';

// Components
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';
import Loader from 'components/Loader';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';

import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';

import ActivityGraphs from './ActivityGraphs';
import PlansOverview from './PlansOverview';
import OutcomesOverview from './OutcomesOverview';
import InitiativesOverview from './InitiativesOverview';

import UpgradeRequiredV4 from './UpgradeRequiredV4';
import KoalaButton from 'koala/components/Button';
import KoalaProgressBar from 'koala/components/ProgressBar';
import WarningIcon from './WarningIcon';
import TeamFilter from './TeamFilter';
import PlansFilter from './PlansFilter';
import ShareButton from 'components/ShareButton';

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  svg {
    margin-left: ${theme.spacing.x1};
  }

  @media ${theme.devices.mobile} {
    .avatar--custom-size {
      display: none;
    }
    gap: unset;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
`;

const Card = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
  background: ${theme.colors.N0};

  h4 {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const IconLineData = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: ${theme.spacing.x2};
`;

const ProgressWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
`;

const PremiumContainer = styled.div`
  position: relative;

  &.premium-required {
    .content {
      filter: blur(4px);
    }
  }
`;

const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x5};
`;

const CircleWrapper = styled.div`
  padding-bottom: ${theme.spacing.x2};
`;

const OverviewGrid = styled.div`
  margin-top: ${theme.spacing.x2};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${theme.spacing.x2};
`;

export const OverviewCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.N0};

  h4 {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

export const OverviewLine = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-items: center;
  border: 1px solid ${theme.colors.blockBorder};
  border-bottom: 0;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }
  &:last-of-type {
    border-bottom: 1px solid ${theme.colors.blockBorder};
    border-radius: 0 0 4px 4px;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background: ${theme.colors.N3};
    }
  }

  .badge_container {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
    &:nth-child(2) {
      font-size: 1.6rem;
      font-weight: 800;
    }

    &:nth-child(3) {
      font-size: 1.2rem;
      font-weight: 800;
      color: ${theme.colors.subtleText};
    }
  }

  .red {
    color: ${theme.colors.red};
  }
  .yellow {
    color: ${theme.colors.O50};
  }
  .green {
    color: ${theme.colors.green};
  }
  .grey {
    color: ${theme.colors.grey};
  }

  &.header {
    background: ${theme.colors.N5};
    font-size: 1.4rem;
    font-weight: 600;
    span {
      &:nth-child(2) {
        font-size: 1.4rem;
      }
    }
  }
`;

const Mask = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  z-index: 1;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  max-height: 25rem;
  height: 25rem;
  top: 50%;
  left: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const UpgradeBanner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2} 0;

  button {
    color: #fff;
  }

  .upgrade-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const AuditScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${theme.spacing.x8};
  padding: ${theme.spacing.x2};

  width: 100%;
`;

const OverallAuditScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const DetailedAuditScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: ${theme.spacing.x2};
  > div {
    display: flex;
    width: 100%;
    gap: ${theme.spacing.x2};
    align-items: center;

    b {
      display: block;
      width: 35rem;
      font-weight: 500;
    }

    span {
      display: block;
      width: 15rem;
    }
  }
`;

const AuditIssuesScore = styled.div`
  big {
    font-size: 3rem;
    font-weight: 600;
  }
`;

const AuditIssuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  width: 100%;
`;

const AuditIssue = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  small {
    color: ${theme.colors.subtleText};
  }

  span {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

const AuditOk = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.subtleText};
`;

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
  .react-filter__control {
    height: unset;
  }
  .react-filter__value-container {
    height: unset;
    overflow: unset;
  }
  input {
    width: 30%;
    min-width: 20rem;
  }
  @media ${theme.devices.tablet} {
    input {
      width: 50%;
    }
  }
`;

const staleTime = 5 * 60 * 1000;

const auditScoreToConfidence = (score: number) => {
  if (score < 25) {
    return 'red';
  }
  if (score < 50) {
    return 'orange';
  }
  if (score < 75) {
    return 'yellow';
  }
  return 'green';
};

interface FilterParams {
  archived: boolean;
  is_in_progress: boolean;
  plan_id?: string[];
  id?: string[];
  team_id?: string[];
}

function WorkspaceInsights() {
  const [teamsFilter, setTeamsFilter] = useState<any[]>([]);
  const [plansFilter, setPlansFilter] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';

  let showInvites = true;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);
  if (currentWorkspace.invites_restricted && !isAdmin) {
    showInvites = false;
  }

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Premium workspaces should have access to insights
  if (hasPremiumSubscription(currentWorkspace) || hasTrial(currentWorkspace)) {
    upgradeRequired = false;
  }

  if (hasTrial(currentWorkspace)) {
    showUpgradeBanner = true;
  }

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.insights.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  const filterPlanIds: string[] = plansFilter.map((plan) => plan.value);
  const filterTeamIds: string[] = teamsFilter.map((plan) => plan.value);
  const outcomesFilterParams: FilterParams = {
    archived: false,
    is_in_progress: true,
  };
  const initiativesFilterParams: FilterParams = {
    archived: false,
    is_in_progress: true,
  };
  const plansFilterParams: FilterParams = {
    archived: false,
    is_in_progress: true,
  };
  if (filterPlanIds.length > 0) {
    outcomesFilterParams.plan_id = filterPlanIds;
    initiativesFilterParams.plan_id = filterPlanIds;
    plansFilterParams.id = filterPlanIds;
  }
  if (filterTeamIds.length > 0) {
    outcomesFilterParams.team_id = filterTeamIds;
    initiativesFilterParams.team_id = filterTeamIds;
    plansFilterParams.team_id = filterTeamIds;
  }

  // Construct the query key using the plan Id
  const queryKeyOutcomesStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug: currentWorkspace.slug,
      filter: outcomesFilterParams,
    },
  ];

  const { data: outcomeStatsResponse } = useQuery(queryKeyOutcomesStats, remoteApi.fetchOutcomesStats, {
    staleTime,
  });

  const queryKeyInitiativesStats = [
    queryKeys.initiatives,
    'stats',
    {
      workspaceSlug: currentWorkspace.slug,
      filter: initiativesFilterParams,
    },
  ];

  const { data: initiativesStatsData } = useQuery(queryKeyInitiativesStats, remoteApi.fetchInitiativesStats, {
    staleTime,
  });

  const queryKeyPlansStats = [
    queryKeys.plans,
    'stats',
    {
      workspaceSlug: currentWorkspace.slug,
      filter: plansFilterParams,
    },
  ];

  const { data: plansStatsData } = useQuery(queryKeyPlansStats, remoteApi.fetchPlansStats, {
    staleTime,
  });

  const queryKeyMembershipsStats = [
    queryKeys.memberships,
    'stats',
    {
      workspaceSlug: currentWorkspace.slug,
      filter: {},
    },
  ];

  const { data: membershipsStatsData } = useQuery(queryKeyMembershipsStats, remoteApi.fetchMembershipsStats, {
    staleTime,
  });
  const plansStats = plansStatsData?.data;
  const outcomesStats = outcomeStatsResponse?.data;
  const initiativesStats = initiativesStatsData?.data;
  const membershipsStats = membershipsStatsData?.data;

  const isLoading = !outcomesStats || !plansStats || !initiativesStats || !membershipsStatsData;

  if (isLoading) {
    return <Loader />;
  }

  const scoreData = insightsUtils.setupScore({
    plansStats,
    outcomesStats,
    initiativesStats,
    membershipsStats,
  });

  let auditScoreColor = 'G';
  if (scoreData.totalScore < 75) {
    auditScoreColor = 'Y';
  }
  if (scoreData.totalScore < 50) {
    auditScoreColor = 'O';
  }
  if (scoreData.totalScore < 25) {
    auditScoreColor = 'R';
  }

  const progressLabel = t('workspaceInsights.progressOf') ?? 'of 100';
  const auditScoreData = {
    progressPercentage: scoreData.totalScore,
    colorType: auditScoreColor,
    centerLabel: [`${scoreData.totalScore}`, `${progressLabel}`],
  };

  // Outcomes Stats calculations
  // Progress
  const outcomeProgressData = {
    progressPercentage: outcomesStats.avg_outcomes_progress * 100,
    colorType: 'B',
    centerLabel: `${Math.round(outcomesStats.avg_outcomes_progress * 100)}%`,
  };

  // NCS
  const totalOutcomesCount = outcomesStats.total_outcomes_count;
  let ncs = 0;
  if (totalOutcomesCount > 0) {
    ncs = (outcomesStats.green_outcomes_count - outcomesStats.red_outcomes_count) / totalOutcomesCount;
  }
  interface ConfidenceData {
    x: number;
    y: number;
    color: string;
    legendTitle: string;
  }
  const confidenceData: ConfidenceData[] = [];
  let dataIndex = 1;

  if (outcomesStats.green_outcomes_count) {
    confidenceData.push({
      x: dataIndex++,
      y: outcomesStats.green_outcomes_count,
      color: theme.colors.green,
      legendTitle: 'on track',
    });
  }
  if (outcomesStats.yellow_outcomes_count) {
    confidenceData.push({
      x: dataIndex++,
      y: outcomesStats.yellow_outcomes_count,
      color: theme.colors.yellow,
      legendTitle: 'at risk',
    });
  }
  if (outcomesStats.red_outcomes_count) {
    confidenceData.push({
      x: dataIndex++,
      y: outcomesStats.red_outcomes_count,
      color: theme.colors.red,
      legendTitle: 'off track',
    });
  }
  if (outcomesStats.grey_outcomes_count) {
    confidenceData.push({
      x: dataIndex++,
      y: outcomesStats.grey_outcomes_count,
      color: theme.colors.grey,
      legendTitle: 'pending',
    });
  }

  let initiativeProgressPrct = 0;
  let closedInitiativesCount = initiativesStats.done_initiatives_count + initiativesStats.abandoned_initiatives_count;
  if (initiativesStats.total_initiatives_count > 0) {
    initiativeProgressPrct = closedInitiativesCount / initiativesStats.total_initiatives_count;
  }
  const initiativeProgressData = {
    progressPercentage: initiativeProgressPrct * 100,
    colorType: 'B',
    centerLabel: [`${closedInitiativesCount}`, `/${initiativesStats.total_initiatives_count}`],
  };

  const handleInvite = (e: any) => {
    e.preventDefault();
    const action = `workspace:${currentWorkspace.slug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  // Function to navigate to the plans page with a filter
  const goToPeopleFilter = (filter: any) => {
    window.open(
      `${routes.WORKSPACE_PEOPLE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug)}?insight=${filter}`,
      '_blank',
    );
  };

  // Function to navigate to the plans page with a filter
  const goToPlansFilter = (insight: string) => {
    const filterHash: { [key: string]: any } = {
      insight,
    };
    if (plansFilter?.length > 0) {
      filterHash.plan = plansFilter;
    }
    if (teamsFilter?.length > 0) {
      filterHash.team = teamsFilter;
    }
    const encodedHash = Base64.encode(JSON.stringify(filterHash));

    history.push(
      `${routes.WORKSPACE_PLANS_ROUTE.replace(':workspaceSlug', currentWorkspace.slug)}?insight=${encodedHash}`,
    );
  };

  const goToOutcomesFilter = (filter: any) => {
    const filterHash = {
      ...filter,
      plan_status: 'is_in_progress',
      sorting: 'plan',
    };

    if (plansFilter?.length > 0) {
      filterHash.plan = plansFilter;
    }
    if (teamsFilter?.length > 0) {
      filterHash.team = teamsFilter;
    }
    const encodedHash = Base64.encode(JSON.stringify(filterHash));

    history.push(
      routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':filter',
        encodedHash,
      ),
    );
  };

  const goToInitiativesFilter = (filter: any) => {
    const filterHash = {
      ...filter,
      plan_status: 'is_in_progress',
      sorting: 'plan',
    };

    if (plansFilter?.length > 0) {
      filterHash.plan = plansFilter;
    }
    if (teamsFilter?.length > 0) {
      filterHash.team = teamsFilter;
    }

    const encodedHash = Base64.encode(JSON.stringify(filterHash));

    history.push(
      routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':filter',
        encodedHash,
      ),
    );
  };

  const handleChangePlans = (options: any) => {
    setPlansFilter(options);
  };
  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:insights:share'));
  };

  const handleChangeTeamId = (options: any) => {
    setTeamsFilter(options);
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const premiumClass = upgradeRequired ? 'premium-required' : '';

  const filterParams = {
    plan: plansFilter,
    team: teamsFilter,
  };

  return (
    <ContentNarrowWrapper>
      <Helmet>
        <title>
          {currentWorkspace.name} | {t('workspaceInsights.title')} | Tability
        </title>
      </Helmet>

      <Header>
        <Title>{t('workspaceInsights.title')}</Title>
        <ShareButton id="insights" link={link} shareCallback={handleShare} />
      </Header>
      <BackgroundWrapper>
        <FilterContainer>
          <PlansFilter handleChange={handleChangePlans} selectedValue={plansFilter} />
          <TeamFilter handleChange={handleChangeTeamId} value={teamsFilter} />
        </FilterContainer>
        <h3>{t('workspaceInsights.audit.workspaceAudit')}</h3>
        <div>
          <Card>
            <AuditScoreContainer>
              <OverallAuditScoreContainer>
                <h4>{t('workspaceInsights.audit.overallAuditScore')}</h4>
                <KoalaCircularProgress data={auditScoreData} size="xlarge" />
              </OverallAuditScoreContainer>
              <DetailedAuditScoreContainer>
                <h4>{t('workspaceInsights.audit.auditDetails')}</h4>
                <div>
                  <b>{t('workspaceInsights.audit.usersAuditScore')}</b>
                  <KoalaProgressBar
                    value={scoreData.membershipScore}
                    size="large"
                    confidence={auditScoreToConfidence(scoreData.membershipScore)}
                  />
                  <span>{scoreData.membershipScore} / 100</span>
                </div>
                <div>
                  <b>{t('workspaceInsights.audit.plansAuditScore')}</b>
                  <KoalaProgressBar
                    value={scoreData.planScore}
                    size="large"
                    confidence={auditScoreToConfidence(scoreData.planScore)}
                  />
                  <span>{scoreData.planScore} / 100</span>
                </div>
                <div>
                  <b>
                    {t('workspaceInsights.audit.outcomesAuditScore', {
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                    })}
                  </b>
                  <KoalaProgressBar
                    value={scoreData.outcomeScore}
                    size="large"
                    confidence={auditScoreToConfidence(scoreData.outcomeScore)}
                  />
                  <span>{scoreData.outcomeScore} / 100</span>
                </div>
                <div>
                  <b>
                    {t('workspaceInsights.audit.initiativesAuditScore', {
                      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </b>
                  <KoalaProgressBar
                    value={scoreData.initiativeScore}
                    size="large"
                    confidence={auditScoreToConfidence(scoreData.initiativeScore)}
                  />
                  <span>{scoreData.initiativeScore} / 100</span>
                </div>
              </DetailedAuditScoreContainer>
            </AuditScoreContainer>
          </Card>
        </div>
        <ProgressWrapper>
          <Card>
            <h4>{t('workspaceInsights.audit.usersAuditScore')}</h4>
            <AuditIssuesScore>
              <big>{scoreData.membershipScore}</big> / 100
            </AuditIssuesScore>
            <AuditIssuesContainer>
              {scoreData.membershipScore === 100 && (
                <AuditOk>
                  <span>{t('workspaceInsights.audit.noIssuesFound')}</span>
                </AuditOk>
              )}
              {scoreData.membershipAuditIssues.not_enough_members && (
                <AuditIssue>
                  <span>
                    <WarningIcon /> {t('workspaceInsights.audit.only1user')}
                  </span>
                  <small>{t('workspaceInsights.audit.only1userTip')}</small>
                  {showInvites && (
                    <div>
                      <KoalaButton size="small" appearance="secondary" onClick={handleInvite}>
                        {t('shared.userSelect.invite')}
                      </KoalaButton>
                    </div>
                  )}
                </AuditIssue>
              )}
              {scoreData.membershipAuditIssues.not_enough_active_users && (
                <AuditIssue>
                  <span>
                    <WarningIcon /> {t('workspaceInsights.audit.notEnoughActiveUsers')}
                  </span>
                  <small>{t('workspaceInsights.audit.notEnoughActiveUsersTip')}</small>
                </AuditIssue>
              )}
              {scoreData.membershipAuditIssues.not_enough_with_outcomes && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.noUsersWithOutcomes', {
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </span>
                  <small>
                    {t('workspaceInsights.audit.noUsersWithOutcomesTip', {
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </small>
                </AuditIssue>
              )}
              {scoreData.membershipAuditIssues.with_too_many_outcomes && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.usersWithTooManyOutcomes', {
                      count: membershipsStats.too_many_outcomes_count,
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() => goToPeopleFilter('too_many_outcomes')}
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
            </AuditIssuesContainer>
          </Card>
          <Card>
            <h4>{t('workspaceInsights.audit.plansAuditScore')}</h4>
            <AuditIssuesScore>
              <big>{scoreData.planScore}</big> / 100
            </AuditIssuesScore>
            <AuditIssuesContainer>
              {scoreData.planScore === 100 && (
                <AuditOk>
                  <span>{t('workspaceInsights.audit.noIssuesFound')}</span>
                </AuditOk>
              )}
              {scoreData.planAuditIssues.not_enough_plans && (
                <AuditIssue>
                  <span>
                    <WarningIcon /> {t('workspaceInsights.audit.noPublishedPlans')}
                  </span>
                  <small>{t('workspaceInsights.audit.noPublishedPlansTip')}</small>
                </AuditIssue>
              )}
              {scoreData.planAuditIssues.too_many_empty_plans && (
                <AuditIssue>
                  <span>
                    <WarningIcon /> {t('workspaceInsights.audit.emptyPlans', { count: plansStats.empty_plans_count })}
                  </span>
                  <div>
                    <KoalaButton size="small" appearance="secondary" onClick={() => goToPlansFilter('no_outcomes')}>
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.planAuditIssues.too_many_without_checkins && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.noCheckinsPlans', { count: plansStats.no_checkins_plans_count })}
                  </span>
                  <div>
                    <KoalaButton size="small" appearance="secondary" onClick={() => goToPlansFilter('no_checkins')}>
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.planAuditIssues.with_too_many_outcomes && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.plansWithTooManyOutcomes', {
                      count: plansStats.outcomes_warning_plans_count,
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() => goToPlansFilter('too_many_outcomes')}
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
            </AuditIssuesContainer>
          </Card>
          <Card>
            <h4>
              {t('workspaceInsights.audit.outcomesAuditScore', {
                label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
              })}
            </h4>
            <AuditIssuesScore>
              <big>{scoreData.outcomeScore}</big> / 100
            </AuditIssuesScore>
            <AuditIssuesContainer>
              {scoreData.outcomeScore === 100 && (
                <AuditOk>
                  <span>{t('workspaceInsights.audit.noIssuesFound')}</span>
                </AuditOk>
              )}
              {scoreData.outcomeAuditIssues.not_enough_outcomes && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.notEnoughOutcomes', {
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </span>
                  <small>
                    {t('workspaceInsights.audit.notEnoughOutcomesTip', {
                      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                    })}
                  </small>
                </AuditIssue>
              )}
              {scoreData.outcomeAuditIssues.without_owners && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.outcomesWithoutOwners', {
                      count: outcomesStats.without_owner_outcomes_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.OUTCOME,
                        outcomesStats.without_owner_outcomes_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToOutcomesFilter({
                          insights: 'without_owner',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.outcomeAuditIssues.too_many_not_measurable && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.outcomesNotMeasurable', {
                      count: outcomesStats.pnone_outcomes_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.OUTCOME,
                        outcomesStats.pnone_outcomes_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToOutcomesFilter({
                          insights: 'without_target',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.outcomeAuditIssues.too_many_with_default_targets && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.outcomesWithDefaultTarget', {
                      count: outcomesStats.default_target_outcomes_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.OUTCOME,
                        outcomesStats.default_target_outcomes_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToOutcomesFilter({
                          insights: 'with_default_target',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.outcomeAuditIssues.with_overdue_checkins && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.outcomesWithOverdueCheckins', {
                      count: outcomesStats.pending_checkin_overdue_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.OUTCOME,
                        outcomesStats.pending_checkin_overdue_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToOutcomesFilter({
                          insights: 'with_overdue_checkin',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
            </AuditIssuesContainer>
          </Card>
          <Card>
            <h4>
              {t('workspaceInsights.audit.initiativesAuditScore', {
                label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
              })}
            </h4>
            <AuditIssuesScore>
              <big>{scoreData.initiativeScore}</big> / 100
            </AuditIssuesScore>
            <AuditIssuesContainer>
              {scoreData.initiativeScore === 100 && (
                <AuditOk>
                  <span>{t('workspaceInsights.audit.noIssuesFound')}</span>
                </AuditOk>
              )}
              {scoreData.initiativeAuditIssues.not_enough_initiatives && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.notEnoughInititiatives', {
                      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </span>
                  <small>
                    {t('workspaceInsights.audit.notEnoughInititiativesTip', {
                      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                    })}
                  </small>
                </AuditIssue>
              )}
              {scoreData.initiativeAuditIssues.with_overdue_initiatives && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.initativesOverdue', {
                      count: initiativesStats.overdue_initiatives_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.INITIATIVE,
                        initiativesStats.overdue_initiatives_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToInitiativesFilter({
                          insights: 'is_overdue',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.initiativeAuditIssues.too_many_backlog_initiatives && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.initiativesInBacklog', {
                      percentage:
                        Math.round(
                          initiativesStats.backlog_initiatives_count / initiativesStats.total_initiatives_count,
                        ) * 100,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.INITIATIVE,
                        initiativesStats.overdue_initiatives_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <div>
                    <KoalaButton
                      size="small"
                      appearance="secondary"
                      onClick={() =>
                        goToInitiativesFilter({
                          work_state: 'backlog',
                        })
                      }
                    >
                      {t('workspaceInsights.audit.viewList')}
                    </KoalaButton>
                  </div>
                </AuditIssue>
              )}
              {scoreData.initiativeAuditIssues.not_enough_in_progress && (
                <AuditIssue>
                  <span>
                    <WarningIcon />{' '}
                    {t('workspaceInsights.audit.notEnoughInitiativesInProgress', {
                      count: initiativesStats.overdue_initiatives_count,
                      label: translate(
                        currentWorkspace,
                        CustomTermKey.INITIATIVE,
                        initiativesStats.overdue_initiatives_count,
                      ).toLowerCase(),
                    })}
                  </span>
                  <small>
                    {t('workspaceInsights.audit.notEnoughInitiativesInProgressTip', {
                      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                    })}
                  </small>
                </AuditIssue>
              )}
            </AuditIssuesContainer>
          </Card>
        </ProgressWrapper>
      </BackgroundWrapper>
      <PremiumContainer className={premiumClass}>
        {upgradeRequired && (
          <Mask>
            <UpgradeModal>{<UpgradeRequiredV4 />}</UpgradeModal>
          </Mask>
        )}

        {showUpgradeBanner && (
          <UpgradeBanner>
            <KoalaIcon iconName="upgrade" className="upgrade-icon" />
            {t(`shared.availableDuringTrial`, { subscription: 'Premium' })}
            {isOwner && (
              <KoalaButton size="small" appearance="secondary" isLink to={`/${currentWorkspace.slug}/settings/billing`}>
                {t('workspaceInsights.upgrade.unlock')}
              </KoalaButton>
            )}
          </UpgradeBanner>
        )}
        <div className="content">
          <BackgroundWrapper className="insights-activity">
            <ActivityGraphs />
          </BackgroundWrapper>

          <BackgroundWrapper className="insights-overview">
            <h3>{t('workspaceInsights.workspaceOverviewHeader')}</h3>
            <ProgressWrapper>
              <Card className="insights-card-data-summary">
                <h4>{t('workspaceInsights.activeData')}</h4>
                <IconLineData>
                  <KoalaIcon iconName="plan" iconSize="small" /> {plansStats.total_plans_count} {t('shared.plans')}
                </IconLineData>
                <IconLineData>
                  <KoalaIcon iconName="metric" iconSize="small" /> {outcomesStats.total_outcomes_count}{' '}
                  {translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}
                </IconLineData>
                <IconLineData>
                  <KoalaIcon iconName="task" iconSize="small" /> {initiativesStats.total_initiatives_count}{' '}
                  {translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}
                </IconLineData>
                <IconLineData>
                  <KoalaIcon iconName="person" iconSize="small" /> {currentWorkspace.memberships_count}{' '}
                  {t('shared.people')}
                </IconLineData>
              </Card>
              <Card className="insights-card-outcome-progress">
                <h4>
                  {t('workspaceInsights.progressLabel', {
                    label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                  })}
                </h4>
                <CircleWrapper>
                  <KoalaCircularProgress data={outcomeProgressData} size="large" />
                </CircleWrapper>
              </Card>

              <Card className="insights-card-initiative-progress">
                <h4>
                  {t('workspaceInsights.progressLabel', {
                    label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                  })}
                </h4>
                <CircleWrapper>
                  <KoalaCircularProgress data={initiativeProgressData} size="large" />
                </CircleWrapper>
              </Card>

              <Card className="insights-card-ncs">
                <h4>
                  {t('shared.ncs.full')}
                  <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
                </h4>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id="explain-ncs"
                  delayHide={500}
                  clickable={true}
                >
                  <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
                <CircleWrapper>
                  <KoalaNCSPie
                    centerLabel={[Math.round(ncs * 100).toString(), t('shared.ncs.acronym')]}
                    data={confidenceData}
                    size="large"
                  />
                </CircleWrapper>
              </Card>
            </ProgressWrapper>
          </BackgroundWrapper>
          <OverviewGrid>
            <BackgroundWrapper className="insights-warning-overview">
              <PlansOverview plansStats={plansStats} filterParams={filterParams} />
            </BackgroundWrapper>
            <BackgroundWrapper>
              <OutcomesOverview outcomesStats={outcomesStats} filterParams={filterParams} />
            </BackgroundWrapper>
            <BackgroundWrapper>
              <InitiativesOverview initiativesStats={initiativesStats} filterParams={filterParams} />
            </BackgroundWrapper>
          </OverviewGrid>
        </div>
      </PremiumContainer>
    </ContentNarrowWrapper>
  );
}

export default WorkspaceInsights;

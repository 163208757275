import React from 'react';
import { IntegrationUser, Membership } from 'types';
import ProfileCard, { ProfileBlock, ProfileCardContainer, ProfileDetails } from '.';
import styled from 'styled-components';
import { t } from 'i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual } from 'react-redux';
import { useSelector } from 'react-redux';
import * as integrationUtils from 'utils/integrationUtils';
import theme from 'theme';
import KoalaAvatar from 'koala/components/Avatar';

const IntegrationProfileCardContainer = styled(ProfileCardContainer)`
  max-width: 25rem;
`;

const IntegrationOwnerWarning = styled.p`
  padding: ${theme.spacing.x1};
`;

const ProfileName = styled.div`
  font-weight: 600;
  font-size: 1.6rem;
`;

interface IntegrationProfileCardProps {
  membership: Membership | null;
  integrationAssignee: IntegrationUser | null;
  integrationType?: string;
}

function IntegrationProfileCard(props: IntegrationProfileCardProps) {
  const { integrationAssignee, integrationType, membership } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const integration = integrationType ? integrationUtils.getIntegrationName(integrationType) : '';

  // handles if the integration matches a membership
  if (membership) {
    const integrationBlockText = t('components.profileCard.managedBy', {
      label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
      integration,
    });
    return <ProfileCard membership={membership ?? null} integrationBlockText={integrationBlockText} />;
  }

  if (!integrationAssignee) {
    return (
      <ProfileCardContainer>
        <p className="subtle">{t('components.profileCard.noMembershipIntegration')}</p>
      </ProfileCardContainer>
    );
  }

  return (
    <IntegrationProfileCardContainer>
      <ProfileBlock>
        <ProfileDetails>
          <KoalaAvatar
            size={4}
            integrationAssignee={integrationAssignee}
            integrationType={integrationType}
            tooltipType="none"
            membership={membership}
          />
          <ProfileName>{integrationAssignee.name}</ProfileName>
          {integrationAssignee.email && <p className="subtle">{integrationAssignee.email}</p>}
        </ProfileDetails>
      </ProfileBlock>
      <IntegrationOwnerWarning className="subtle">
        {t('components.profileCard.managedBy', {
          label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
          integration,
        })}
      </IntegrationOwnerWarning>
    </IntegrationProfileCardContainer>
  );
}

export default React.memo(IntegrationProfileCard);

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Membership, Workspace } from 'types';
import { ChromeContent } from 'components/Chrome';
import styled from 'styled-components';
import theme from 'theme';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import WorkspaceHeader from 'components/WorkspaceHeader';
import { shallowEqual, useSelector } from 'react-redux';
import { hasPremiumSubscription, hasTrial } from 'utils/workspaceUtils';
import UpgradeRequiredV4 from './UpgradeRequiredV4';
import UpgradeBanner from './UpgradeBanner';
import CustomDashboards from './CustomDashboards';
import KoalaTabs from 'koala/components/Tabs';
// import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as routes from 'routes';
import PremadeDashboard from 'pages/WorkspaceDashboards/PremadeDashboard';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import Insights from './Insights';

const Mask = styled.div`
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  z-index: 80;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  max-height: 25rem;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -40rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

export const DashboardLoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x2};
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 120rem;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const EmptyDashboardSection = styled.div`
  text-align: center;
  color: ${theme.colors.subtleText};
  padding: ${theme.spacing.x4};
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
  margin: 0;
  padding: 0 ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  background: #fff;
`;

export const DashboardActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.x1} 0px;
`;

interface Props {
  workspace: Workspace;
  premadeDashboard?: string;
}

function WorkspaceDashboards(props: Props) {
  const { workspace, premadeDashboard } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Premium workspaces should have access to insights
  if (hasPremiumSubscription(currentWorkspace) || hasTrial(currentWorkspace)) {
    upgradeRequired = false;
  }

  if (hasTrial(currentWorkspace)) {
    showUpgradeBanner = true;
  }

  const workspaceSlug = currentWorkspace.slug;

  const tabs = [
    {
      label: t('workspaceDashboards.custom'),
      key: 'custom',
      id: 'dashboard-custom',
      link: routes.WORKSPACE_DASHBOARDS_ROUTE.replace(':workspaceSlug', workspaceSlug),
    },
    {
      label: t('workspaceDashboards.people'),
      key: 'people',
      id: 'dashboard-people',
      link: routes.WORKSPACE_PEOPLE_DASHBOARD_ROUTE.replace(':workspaceSlug', workspaceSlug),
    },
    {
      label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
      key: 'outcomes',
      id: 'dashboard-outcomes',
      link: routes.WORKSPACE_OUTCOMES_DASHBOARD_ROUTE.replace(':workspaceSlug', workspaceSlug),
    },
    {
      label: t('workspaceInsights.title'),
      key: 'insights',
      id: 'dashboard-insights',
      link: routes.WORKSPACE_INSIGHTS_DASHBOARD_ROUTE.replace(':workspaceSlug', workspaceSlug),
    },
    // {
    //   label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
    //   key: 'workspaceDashboardOutcomes',
    //   id: 'dashboard-outcomes',
    // },
    // {
    //   label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
    //   key: 'workspaceDashboardInitiatives',
    //   id: 'dashboard-initiatives',
    // },
  ];

  const viewType = premadeDashboard ?? 'custom';

  return (
    <>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceDashboards.title')} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={<h1>{t('workspaceDashboards.title')}</h1>}
        />
      </MobileReadyChromeHeader>
      <ChromeContent>
        {upgradeRequired && (
          <Mask>
            <UpgradeModal>{<UpgradeRequiredV4 />}</UpgradeModal>
          </Mask>
        )}
        <Header>
          <KoalaTabs tabs={tabs} />
        </Header>
        <Wrapper>
          {showUpgradeBanner && <UpgradeBanner />}
          {viewType === 'insights' && <Insights />}
          {viewType === 'custom' && <CustomDashboards currentMembership={currentMembership} workspace={workspace} />}
          {viewType === 'people' && <PremadeDashboard type="people" workspaceSlug={workspace.slug} />}
          {viewType === 'outcomes' && <PremadeDashboard type="outcomes" workspaceSlug={workspace.slug} />}
          {viewType === 'initiatives' && <PremadeDashboard type="initiatives" workspaceSlug={workspace.slug} />}
        </Wrapper>
      </ChromeContent>
    </>
  );
}

export default WorkspaceDashboards;

import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { parseISO, getUnixTime } from 'date-fns';
import { isAppInIframe } from 'utils/appUtils';
import { REFERENCE_TEMPLATE_STORAGE_KEY } from 'config/constants';

// Components
import JoinWorkspace from './JoinWorkspace';
import LoadingScreen from 'components/LoadingScreen';

// Authentication pages
import AuthenticateGoogle from 'pages/AuthenticateGoogle';

// Integrations pages
import InstallSlack from 'pages/InstallSlack';
import InstallClickup from 'pages/InstallClickup';
import InstallAsana from 'pages/InstallAsana';
import InstallHubspot from 'pages/InstallHubspot';
import InstallGoogle from 'pages/InstallGoogle';
import InstallLinear from 'pages/InstallLinear';
import InstallGithub from 'pages/InstallGithub';
import InstallZendeskSales from 'pages/InstallZendeskSales';
import InstallZendeskSupport from 'pages/InstallZendeskSupport';
import InstallHelpScout from 'pages/InstallHelpScout';
import InstallGitlab from 'pages/InstallGitlab';
import InstallSalesforce from 'pages/InstallSalesforce';
import InstallTrello from 'pages/InstallTrello';
import InstallPowerBI from 'pages/InstallPowerBI';
import InstallMonday from 'pages/InstallMonday';

// Public Plan
import PublicPlanDashboard from 'pages/PublicPlanDashboard';

// Embed
import WorkspaceEmbedOutcome from 'pages/WorkspaceEmbedOutcome';
import WorkspaceEmbedObjective from 'pages/WorkspaceEmbedObjective';
import WorkspaceEmbedPlan from 'pages/WorkspaceEmbedPlan';

// Templates
import TemplatesHome from 'pages/TemplatesHome';
import TemplateFile from 'pages/TemplateFile';
import TemplateDetails from 'pages/TemplateDetails';
import TemplatesSearch from 'pages/TemplatesSearch';

// Public pages
import SessionConfirm from 'pages/SessionConfirm';
import SessionLogin from 'pages/SessionLogin';
import SessionSignup from 'pages/SessionSignup';
import SessionSSO from 'pages/SessionSSO';
import SessionResetPassword from 'pages/SessionResetPassword';
import SessionNewPassword from 'pages/SessionNewPassword';

import SAMLLogin from 'pages/SAMLLogin';

// Unauthenticated notifications management
import UnsubscribeFromEmail from 'pages/UnsubscribeFromEmail';

// Routes

import PublicPlanPresentation from 'pages/PublicPlanPresentation';
import MembershipRedirect from './MembershipRedirect';

import AccountRoutes from './AccountRoutes';

import DashboardRoutes from './DashboardRoutes';

import PublicTemplatesRoutes from './PublicTemplatesRoutes';

import WorkspaceRoutes from './WorkspaceRoutes';
import PublicRetrospective from 'pages/PublicRetrospective';
import LogoutWithStoredPath from './LogoutWithStoredPath';
import WorkspaceDeleted from 'pages/WorkspaceDeleted';
import InstallPagerDuty from 'pages/InstallPagerDuty';

/**********
 * ROUTES
 **********/

// Routes definitions
export const ROOT_ROUTE = '/';

// Authentication routes
export const AUTHENTICATE_GOOGLE_ROUTE = '/authenticate/google';

// SAML routes
export const SAML_LOGIN_ROUTE = '/saml/login';
export const SAML_FAILED_ROUTE = '/saml/failed';

// Integrations install routes
export const INSTALL_SLACK_ROUTE = '/install/slack';
export const INSTALL_CLICKUP_ROUTE = '/install/clickup';
export const INSTALL_TRELLO_ROUTE = '/install/trello';
export const INSTALL_GITHUB_ROUTE = '/install/github';
export const INSTALL_SALESFORCE_ROUTE = '/install/salesforce';
export const INSTALL_SENTRY_ROUTE = '/install/sentry';
export const INSTALL_ASANA_ROUTE = '/install/asana';
export const INSTALL_GITLAB_ROUTE = '/install/gitlab';
export const INSTALL_HUBSPOT_ROUTE = '/install/hubspot';
export const INSTALL_PAGERDUTY_ROUTE = '/install/pagerduty';
export const INSTALL_HELPSCOUT_ROUTE = '/install/helpscout';
export const INSTALL_ZENDESK_SUPPORT_ROUTE = '/install/zendesk_support';
export const INSTALL_ZENDESK_SALES_ROUTE = '/install/zendesk_sales';
export const INSTALL_GOOGLE_ROUTE = '/install/google';
export const INSTALL_LINEAR_ROUTE = '/install/linear';
export const INSTALL_POWER_BI_ROUTE = '/install/power_bi';
export const INSTALL_MONDAY_ROUTE = '/install/monday';

// Public routes
export const LOGIN_ROUTE = '/login';
export const SSO_ROUTE = '/sso';
export const SIGNUP_ROUTE = '/signup';
export const CONFIRM_ROUTE = '/confirm';
export const RESET_PASSWORD_ROUTE = '/password/reset';
export const NEW_PASSWORD_ROUTE = '/password/new';
export const TEMPLATES_HOME_ROUTE = '/templates';
export const TEMPLATES_SEARCH_ROUTE = '/templates/search/:keyword';
export const TEMPLATES_TAG_ROUTE = '/templates/:tag';
export const TEMPLATE_FILE_ROUTE = '/templates/t/:planId';
export const TEMPLATE_FILE_OLD_ROUTE = '/templates/p/:templateId';

export const PUBLIC_PLAN_TEMPLATE_ROOT_ROUTE = '/templates/p/:planId';

export const INVITE_ROUTE = '/invite/:invite_link_token';

export const UNSUBSCRIBE_ROUTE = '/unsubscribe/:unsubscribe_token';

// Public plans routes
export const PUBLIC_PLAN_HOME_ROUTE = `/:workspaceSlug/plans/:planId/public`;
export const PUBLIC_PLAN_TV_ROUTE = `/:workspaceSlug/plans/:planId/public/tv`;
export const PUBLIC_RETROSPECTIVE_ROUTE = `/:workspaceSlug/plans/:planId/retrospectives/:retrospectiveId/public`;

export const PUBLIC_PLAN_PREZ_ROOT_ROUTE = '/:workspaceSlug/plans/:planId/public/present';
export const PUBLIC_PLAN_PREZ_OUTCOMES_ROUTE = '/:workspaceSlug/plans/:planId/public/present/:outcomeId';
export const PUBLIC_PLAN_PREZ_OUTCOMES_ITEM_ROUTE = '/:workspaceSlug/plans/:planId/public/present/outcomes/:outcomeId';
export const PUBLIC_PLAN_PREZ_DASHBOARD_ROUTE = '/:workspaceSlug/plans/:planId/public/present/dashboard';

export const PUBLIC_EMBED_OUTCOME_ROUTE = '/:workspaceSlug/embed/outcome/:outcomeId';
export const PUBLIC_EMBED_OBJECTIVE_ROUTE = '/:workspaceSlug/embed/objective/:objectiveId';
export const PUBLIC_EMBED_PLAN_ROUTE = '/:workspaceSlug/embed/plan/:planId';

// Private routes
export const DASHBOARD_ROOT_ROUTE = '/onboarding';
export const DASHBOARD_INBOX_ROUTE = '/onboarding/inbox';
export const DASHBOARD_WORKSPACES_ROUTE = '/onboarding/workspaces';
export const DASHBOARD_WORKSPACES_NEW_ROUTE = '/onboarding/workspaces/new';
export const DASHBOARD_TERMS_ROUTE = '/onboarding/terms';
export const ONBOARDING_PASSWORD_ROUTE = '/onboarding/password';

export const ACCOUNT_ROOT_ROUTE = '/account';
export const ACCOUNT_EMAIL_ROUTE = '/account/email';
export const ACCOUNT_PASSWORD_ROUTE = '/account/password';
export const ACCOUNT_2FA_ROUTE = '/account/2fa';
export const ACCOUNT_DELETE_ROUTE = '/account/delete';
export const ACCOUNT_REFERRAL_ROUTE = '/account/referral';

// Public template routes
export const PUBLIC_TEMPLATES_ROOT_ROUTE = '/public_templates';
export const PUBLIC_TEMPLATES_DETAILS_ROUTE = '/public_templates/:publicTemplateId';
export const PUBLIC_TEMPLATES_EDIT_ROUTE = '/public_templates/:publicTemplateId/edit';

// Private workspace routes
export const WORKSPACE_ROOT_ROUTE = '/:workspaceSlug';

export const WORKSPACE_ONBOARDING_ABOUT_ROUTE = '/:workspaceSlug/onboarding/about';
export const WORKSPACE_ONBOARDING_ROLE_ROUTE = '/:workspaceSlug/onboarding/role';
export const WORKSPACE_ONBOARDING_ROLLOUT_ROUTE = '/:workspaceSlug/onboarding/rollout';
export const WORKSPACE_ONBOARDING_GOALS_ROUTE = '/:workspaceSlug/onboarding/tools';
export const WORKSPACE_ONBOARDING_WORKFLOW_ROUTE = '/:workspaceSlug/onboarding';
export const WORKSPACE_ONBOARDING_TEAM_ROUTE = '/:workspaceSlug/onboarding/team';
export const WORKSPACE_ONBOARDING_TEMPLATE_ROUTE = '/:workspaceSlug/onboarding/template';
export const WORKSPACE_ONBOARDING_DONE_ROUTE = '/:workspaceSlug/onboarding/done';
export const WORKSPACE_ONBOARDING_REFERRAL_ROUTE = '/:workspaceSlug/onboarding/referral';
export const WORKSPACE_ONBOARDING_TRIAL_ROUTE = '/:workspaceSlug/onboarding/trial';

export const WORKSPACE_MEMBERSHIP_NOTIFICATIONS_ROUTE = '/:workspaceSlug/account/notifications';
export const WORKSPACE_MEMBERSHIP_STANDUPS_ROUTE = '/:workspaceSlug/account/standups';
export const WORKSPACE_MEMBERSHIP_INTEGRATIONS_ROUTE = '/:workspaceSlug/account/integrations';
export const WORKSPACE_MEMBERSHIP_DELETE_ROUTE = '/:workspaceSlug/account/delete';

export const WORKSPACE_SETTINGS_ROUTE = '/:workspaceSlug/settings';
export const WORKSPACE_SETTINGS_USAGE_ROUTE = '/:workspaceSlug/settings/usage';
export const WORKSPACE_SETTINGS_AUDIT_ROUTE = '/:workspaceSlug/settings/audit';
export const WORKSPACE_SETTINGS_BILLING_ROUTE = '/:workspaceSlug/settings/billing';
export const WORKSPACE_SETTINGS_BILLING_PLANS_ROUTE = '/:workspaceSlug/settings/billing/plans';
export const WORKSPACE_SETTINGS_WORKFLOW_ROUTE = '/:workspaceSlug/settings/workflow';
export const WORKSPACE_SETTINGS_INTEGRATIONS_ROUTE = '/:workspaceSlug/settings/integrations';
export const WORKSPACE_SETTINGS_THEME_ROUTE = '/:workspaceSlug/settings/theme';
export const WORKSPACE_SETTINGS_TERMS_ROUTE = '/:workspaceSlug/settings/terms';
export const WORKSPACE_SETTINGS_TAGS_ROUTE = '/:workspaceSlug/settings/tags';
export const WORKSPACE_SETTINGS_SECURITY_ROUTE = '/:workspaceSlug/settings/security';
export const WORKSPACE_SETTINGS_WHITELISTING_ROUTE = '/:workspaceSlug/settings/whitelisting';
export const WORKSPACE_SETTINGS_SSO_ROUTE = '/:workspaceSlug/settings/sso';
export const WORKSPACE_SETTINGS_PRIVACY_ROUTE = '/:workspaceSlug/settings/privacy';
export const WORKSPACE_SETTINGS_PERMISSIONS_ROUTE = '/:workspaceSlug/settings/permissions';
export const WORKSPACE_SETTINGS_ACCESS_ROUTE = '/:workspaceSlug/settings/access';
export const WORKSPACE_SETTINGS_DELETE_ROUTE = '/:workspaceSlug/settings/delete';
export const WORKSPACE_SETTINGS_MEMBERSHIPS_ROUTE = '/:workspaceSlug/settings/users';
export const WORKSPACE_SETTINGS_MEMBERSHIP_DETAILS_ROUTE = '/:workspaceSlug/settings/users/:membershipId';
export const WORKSPACE_QUICKSTART_ROUTE = '/:workspaceSlug/quickstart';
export const WORKSPACE_ACCELERATOR_ROUTE = '/:workspaceSlug/accelerator';
export const WORKSPACE_ACCELERATOR_SECTION_ROUTE = '/:workspaceSlug/accelerator/:section';
export const WORKSPACE_INBOX_ROUTE = '/:workspaceSlug/inbox';
export const WORKSPACE_NEWSFEED_ROUTE = '/:workspaceSlug/newsfeed';
export const WORKSPACE_INSIGHTS_ROUTE = '/:workspaceSlug/insights';
export const WORKSPACE_PLANS_ROUTE = '/:workspaceSlug/plans';
export const WORKSPACE_MAP_ROUTE = '/:workspaceSlug/map';
export const WORKSPACE_DASHBOARDS_ROUTE = '/:workspaceSlug/dashboards/custom';
export const WORKSPACE_PEOPLE_DASHBOARD_ROUTE = '/:workspaceSlug/dashboards/people';
export const WORKSPACE_OUTCOMES_DASHBOARD_ROUTE = '/:workspaceSlug/dashboards/outcomes';
export const WORKSPACE_INSIGHTS_DASHBOARD_ROUTE = '/:workspaceSlug/dashboards/insights';
export const WORKSPACE_DASHBOARD_ROUTE = '/:workspaceSlug/dashboards/:dashboardId';
export const WORKSPACE_DASHBOARD_EDIT_ROUTE = '/:workspaceSlug/dashboards/:dashboardId/edit';
export const WORKSPACE_MAP_V2_ROUTE = '/:workspaceSlug/map2';
export const WORKSPACE_MAP_V2_ROUTE_WITH_FILTERS = '/:workspaceSlug/map/:filter';
export const WORKSPACE_MAP_V2_SEGMENT_ROUTE_WITH_FILTERS = '/:workspaceSlug/map/:segmentId/:filter';
export const WORKSPACE_OUTCOMES_ROUTE = '/:workspaceSlug/outcomes';
export const WORKSPACE_FILTERS_ROUTE = '/:workspaceSlug/filters';
export const WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS = '/:workspaceSlug/outcomes/:filter';
export const WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS = '/:workspaceSlug/outcomes/segments/:segmentId/:filter';
export const WORKSPACE_PEOPLE_ROUTE = '/:workspaceSlug/people';
export const WORKSPACE_INITIATIVE_ROUTE = '/:workspaceSlug/tasks';
export const WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS = '/:workspaceSlug/tasks/:filter';
export const WORKSPACE_INITIATIVE_SEGMENT_ROUTE_WITH_FILTERS = '/:workspaceSlug/tasks/segments/:segmentId/:filter';
export const WORKSPACE_OBJECTIVES_ROUTE = '/:workspaceSlug/objectives';
export const WORKSPACE_OBJECTIVES_ROUTE_WITH_FILTERS = '/:workspaceSlug/objectives/:filter';
export const WORKSPACE_OBJECTIVES_SEGMENT_ROUTE_WITH_FILTERS = '/:workspaceSlug/objectives/segments/:segmentId/:filter';
export const WORKSPACE_PROFILE_ROUTE = '/:workspaceSlug/people/:membershipId';
export const WORKSPACE_PLAN_ROOT_ROUTE = '/:workspaceSlug/plans/:planId';
export const WORKSPACE_PLAN_WRITE_ROUTE = '/:workspaceSlug/plans/:planId/write';
export const WORKSPACE_PLAN_TEMPLATE_ROUTE = '/:workspaceSlug/plans/:planId/template';
export const WORKSPACE_PLAN_ALIGNMENT_ROUTE = '/:workspaceSlug/plans/:planId/alignment';
export const WORKSPACE_PLAN_TRACK_ROUTE = '/:workspaceSlug/plans/:planId/outcomes';
export const WORKSPACE_PLAN_TRACK_MINIMAP_ROUTE = '/:workspaceSlug/plans/:planId/outcomes/minimap';
export const WORKSPACE_PLAN_INSIGHTS_ROUTE = '/:workspaceSlug/plans/:planId/insights';
export const WORKSPACE_PLAN_RETROSPECTIVES_ROUTE = '/:workspaceSlug/plans/:planId/retrospectives';
export const WORKSPACE_RETROSPECTIVE_EDIT_ROUTE = '/:workspaceSlug/plans/:planId/retrospectives/:retrospectiveId/edit';
export const WORKSPACE_RETROSPECTIVE_ROUTE = '/:workspaceSlug/plans/:planId/retrospectives/:retrospectiveId';
export const WORKSPACE_PLAN_MAP_ROUTE = '/:workspaceSlug/plans/:planId/map';
export const WORKSPACE_PLAN_WORK_ROUTE = '/:workspaceSlug/plans/:planId/tasks';
export const WORKSPACE_PLAN_WORK_WEEK_ROUTE = '/:workspaceSlug/plans/:planId/tasks/:weekView';
export const WORKSPACE_PLAN_NOTES_ROUTE = '/:workspaceSlug/plans/:planId/notes';
export const WORKSPACE_PLAN_ACTIVITY_ROUTE = '/:workspaceSlug/plans/:planId/activity';
export const WORKSPACE_PLAN_SETTINGS_ROUTE = '/:workspaceSlug/plans/:planId/settings';
export const WORKSPACE_PLAN_SETTINGS_NOTIFICATIONS_ROUTE = '/:workspaceSlug/plans/:planId/settings/notifications';
export const WORKSPACE_PLAN_SETTINGS_SHARING_ROUTE = '/:workspaceSlug/plans/:planId/settings/sharing';
export const WORKSPACE_DELETE_SUCCESSFUL_ROUTE = '/delete_successful';

export const WORKSPACE_TEAMS_ROUTE = '/:workspaceSlug/teams';
export const WORKSPACE_TEAM_DETAILS_ROUTE = '/:workspaceSlug/teams/:teamId';

export const WORKSPACE_STANDUPS_ROUTE = '/:workspaceSlug/standups';

// Presentation mode
export const WORKSPACE_PLAN_PREZ_ROOT_ROUTE = '/:workspaceSlug/plans/:planId/present';
export const WORKSPACE_PLAN_PREZ_OUTCOMES_ROUTE = '/:workspaceSlug/plans/:planId/present/:outcomeId';
export const WORKSPACE_PLAN_PREZ_DASHBOARD_ROUTE = '/:workspaceSlug/plans/:planId/present/dashboard';

declare global {
  interface Window {
    Intercom: any;
    wootricSettings: any;
    wootric: any;
    Canny: any;
  }
}

const sessionRoutes = [
  AUTHENTICATE_GOOGLE_ROUTE,
  INSTALL_HUBSPOT_ROUTE,
  INSTALL_HELPSCOUT_ROUTE,
  INSTALL_SLACK_ROUTE,
  INSTALL_CLICKUP_ROUTE,
  INSTALL_TRELLO_ROUTE,
  INSTALL_GITHUB_ROUTE,
  INSTALL_SALESFORCE_ROUTE,
  INSTALL_ASANA_ROUTE,
  INSTALL_PAGERDUTY_ROUTE,
  INSTALL_GITLAB_ROUTE,
  INSTALL_ZENDESK_SUPPORT_ROUTE,
  INSTALL_ZENDESK_SALES_ROUTE,
  INSTALL_GOOGLE_ROUTE,
  INSTALL_LINEAR_ROUTE,
  INSTALL_POWER_BI_ROUTE,
  INSTALL_MONDAY_ROUTE,
  LOGIN_ROUTE,
  SSO_ROUTE,
  SIGNUP_ROUTE,
  CONFIRM_ROUTE,
  RESET_PASSWORD_ROUTE,
  NEW_PASSWORD_ROUTE,
  SAML_LOGIN_ROUTE,
  SAML_FAILED_ROUTE,
];

function Routes() {
  const cookies = new Cookies();
  const location = useLocation(); // Location will be used to know which route we're using
  // How this should work
  // First load of the app should try to auth the user. Don't assume that we're logged in.
  const isAuthenticated = useSelector((state: any) => state.session.isAuthenticated);
  const isInitialized = useSelector((state: any) => state.session.isInitialized);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const inIframe: boolean = isAppInIframe();
  const [isIdentified, setIsIdentified] = useState(false);

  const splittedPath = location.pathname.split('/');

  // Need to identify embedded routes to disable Intercom
  const isEmbedRoute = splittedPath[2] === 'embed';
  const isPublic = splittedPath[4] === 'public';

  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  });

  useEffect(() => {
    if (isEmbedRoute || isPublic) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, [isEmbedRoute, isPublic]);

  useEffect(() => {
    if (currentUser && !isIdentified) {
      const confirmedAtUnix = getUnixTime(parseISO(currentUser.confirmed_at));
      // Shutdown first to clear the session
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: currentUser.id,
        name: currentUser.fullname, // Full name
        email: currentUser.email, // Email address
        created_at: confirmedAtUnix,
        user_hash: currentUser.intercom_hash,
      });
      window.wootricSettings = {
        email: currentUser.email,
        // external_id: 'abc123',
        created_at: confirmedAtUnix,
        account_token: 'NPS-ba471f53', // This is your unique account token.
      };
      window.Canny('identify', {
        appID: '60aeb0271bfea77b6a53261f',
        user: {
          // Replace these values with the current user's data
          email: currentUser.email,
          name: currentUser.name,
          id: currentUser.id,

          // These fields are optional, but recommended:
          avatarURL: currentUser.avatar_url,
          created: currentUser.created_at,
        },
      });

      if (window.wootric && !inIframe && !isEmbedRoute) {
        window.wootric('run');
      }
      setIsIdentified(true);
    }
  }, [currentUser, isIdentified, inIframe, isEmbedRoute]);

  // Helper function to redirect the user if they're logged in
  const RedirectToRootIfAuthed = () => {
    if (isAuthenticated) {
      return <MembershipRedirect />;
    }
  };

  // *******************************
  // * START OF TEMPLATE DETECTION *
  // *******************************

  /**
    The code below looks for a query param ?tpl=<template.nano_slug>
    If it's detected it stores it in local storage so that we can re-use it in
    the onboarding workflow.
   */
  const urlParams = new URLSearchParams(location.search);
  const tpl = urlParams.get('tpl');

  useEffect(() => {
    if (tpl) {
      localStorage.setItem(REFERENCE_TEMPLATE_STORAGE_KEY, JSON.stringify(tpl));
    }
  }, [tpl]);

  // *****************************
  // * END OF TEMPLATE DETECTION *
  // *****************************

  // Testing different matches
  const templatesMatch: any = useRouteMatch(TEMPLATES_HOME_ROUTE);
  const publicPlanMatch: any = useRouteMatch(PUBLIC_PLAN_HOME_ROUTE);
  const publicRetrospectiveMatch: any = useRouteMatch(PUBLIC_RETROSPECTIVE_ROUTE);
  const embedOutcomeMatch: any = useRouteMatch(PUBLIC_EMBED_OUTCOME_ROUTE);
  const embedObjectiveMatch: any = useRouteMatch(PUBLIC_EMBED_OBJECTIVE_ROUTE);
  const embedPlanMatch: any = useRouteMatch(PUBLIC_EMBED_PLAN_ROUTE);
  const workspaceDeletedMatch: any = useRouteMatch(WORKSPACE_DELETE_SUCCESSFUL_ROUTE);
  const inviteMatch: any = useRouteMatch(INVITE_ROUTE);
  const unsubscribeMatch: any = useRouteMatch(UNSUBSCRIBE_ROUTE);
  const planMatch: any = useRouteMatch(WORKSPACE_PLAN_ROOT_ROUTE);
  const retrospectiveMatch: any = useRouteMatch(WORKSPACE_RETROSPECTIVE_ROUTE);

  // Redirect to unsubscribe if it exists
  if (unsubscribeMatch) {
    return (
      <Route path={UNSUBSCRIBE_ROUTE} exact={true}>
        <UnsubscribeFromEmail />
      </Route>
    );
  }

  // Store the invite token in cookie and redirect to home if on invite page
  if (inviteMatch) {
    const invite_link_token = inviteMatch.params.invite_link_token;
    cookies.set('_tbty_inv', invite_link_token, {
      path: '/',
      secure: true,
    });
    return <Redirect to="/login" />;
  }

  // redirect to workspace deleted
  if (workspaceDeletedMatch) {
    return (
      <Route path={WORKSPACE_DELETE_SUCCESSFUL_ROUTE} exact={true}>
        <WorkspaceDeleted />
      </Route>
    );
  }

  // Early return in case of embed
  if (embedOutcomeMatch) {
    return (
      <Route path={PUBLIC_EMBED_OUTCOME_ROUTE} exact={true}>
        <WorkspaceEmbedOutcome />
      </Route>
    );
  }

  // Early return in case of embed
  if (embedObjectiveMatch) {
    return (
      <Route path={PUBLIC_EMBED_OBJECTIVE_ROUTE} exact={true}>
        <WorkspaceEmbedObjective />
      </Route>
    );
  }

  // Early return in case of embed
  if (embedPlanMatch) {
    return (
      <Route path={PUBLIC_EMBED_PLAN_ROUTE} exact={true}>
        <WorkspaceEmbedPlan />
      </Route>
    );
  }

  if (publicRetrospectiveMatch) {
    return (
      <Route path={PUBLIC_RETROSPECTIVE_ROUTE} exact={true}>
        <PublicRetrospective />
      </Route>
    );
  }

  if (publicPlanMatch) {
    return (
      <Switch>
        <Route path={PUBLIC_PLAN_PREZ_DASHBOARD_ROUTE} exact={true}>
          <PublicPlanDashboard />
        </Route>
        <Route path={PUBLIC_PLAN_PREZ_ROOT_ROUTE} exact={true}>
          <PublicPlanPresentation />
        </Route>
        <Route path={PUBLIC_PLAN_PREZ_OUTCOMES_ROUTE} exact={true}>
          <PublicPlanPresentation />
        </Route>
        <Route path={PUBLIC_PLAN_PREZ_OUTCOMES_ITEM_ROUTE} exact={true}>
          <PublicPlanPresentation />
        </Route>
      </Switch>
    );
  }

  // Public pages don not require any particular checks so let's handle these routes right away
  // If we're on a session page, use the session switch
  if (sessionRoutes.includes(location.pathname) || templatesMatch) {
    return (
      <Switch>
        <Route path={TEMPLATE_FILE_ROUTE} exact={true}>
          <TemplateDetails />
        </Route>
        <Route path={TEMPLATE_FILE_OLD_ROUTE} exact={true}>
          <TemplateFile />
        </Route>
        <Route path={TEMPLATES_SEARCH_ROUTE} exact={true}>
          <TemplatesSearch />
        </Route>
        <Route path={TEMPLATES_TAG_ROUTE} exact={true}>
          <TemplatesHome />
        </Route>
        <Route path={TEMPLATES_HOME_ROUTE} exact={true}>
          <TemplatesHome />
        </Route>
        <Route path={INSTALL_SLACK_ROUTE} exact={true}>
          <InstallSlack />
        </Route>
        <Route path={INSTALL_CLICKUP_ROUTE} exact={true}>
          <InstallClickup />
        </Route>
        <Route path={INSTALL_TRELLO_ROUTE} exact={true}>
          <InstallTrello />
        </Route>
        <Route path={INSTALL_GITHUB_ROUTE} exact={true}>
          <InstallGithub />
        </Route>
        <Route path={INSTALL_SALESFORCE_ROUTE} exact={true}>
          <InstallSalesforce />
        </Route>
        <Route path={INSTALL_ASANA_ROUTE} exact={true}>
          <InstallAsana />
        </Route>
        <Route path={INSTALL_GITLAB_ROUTE} exact={true}>
          <InstallGitlab />
        </Route>
        <Route path={INSTALL_HUBSPOT_ROUTE} exact={true}>
          <InstallHubspot />
        </Route>
        <Route path={INSTALL_PAGERDUTY_ROUTE} exact={true}>
          <InstallPagerDuty />
        </Route>
        <Route path={INSTALL_HELPSCOUT_ROUTE} exact={true}>
          <InstallHelpScout />
        </Route>
        <Route path={INSTALL_ZENDESK_SUPPORT_ROUTE} exact={true}>
          <InstallZendeskSupport />
        </Route>
        <Route path={INSTALL_ZENDESK_SALES_ROUTE} exact={true}>
          <InstallZendeskSales />
        </Route>
        <Route path={INSTALL_GOOGLE_ROUTE} exact={true}>
          <InstallGoogle />
        </Route>
        <Route path={INSTALL_LINEAR_ROUTE} exact={true}>
          <InstallLinear />
        </Route>
        <Route path={INSTALL_POWER_BI_ROUTE} exact={true}>
          <InstallPowerBI />
        </Route>
        <Route path={INSTALL_MONDAY_ROUTE} exact={true}>
          <InstallMonday />
        </Route>
        <Route path={AUTHENTICATE_GOOGLE_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <AuthenticateGoogle />
        </Route>
        <Route path={SAML_LOGIN_ROUTE} exact={true}>
          <SAMLLogin />
        </Route>
        {/* Session routes */}
        <Route path={LOGIN_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionLogin />
        </Route>
        <Route path={SIGNUP_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionSignup />
        </Route>
        <Route path={SSO_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionSSO />
        </Route>
        <Route path={CONFIRM_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionConfirm />
        </Route>
        <Route path={RESET_PASSWORD_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionResetPassword />
        </Route>
        <Route path={NEW_PASSWORD_ROUTE} exact={true}>
          {RedirectToRootIfAuthed()}
          <SessionNewPassword />
        </Route>
      </Switch>
    );
  }

  // Wait for auth to be initialized before running the next bit of logic
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // Back to Login is the user is not authed and tyring to access a private route
  // This works because all public routes would have been handled above

  // Get the invite token cookie if set
  const invite_link_token = cookies.get('_tbty_inv');

  if (invite_link_token) {
    return <JoinWorkspace invite_link_token={invite_link_token} />;
  }

  if (currentUser && !currentUser.legals_accepted_at && location.pathname !== DASHBOARD_TERMS_ROUTE) {
    return <Redirect to={DASHBOARD_TERMS_ROUTE} />;
  }

  if (
    currentUser &&
    currentUser.password_update_required &&
    ![ONBOARDING_PASSWORD_ROUTE, DASHBOARD_TERMS_ROUTE].includes(location.pathname)
  ) {
    return <Redirect to={ONBOARDING_PASSWORD_ROUTE} />;
  }

  // handle retrospective redirect to public view
  if (retrospectiveMatch && !isAuthenticated) {
    const redirectPath = PUBLIC_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', splittedPath[1])
      .replace(':planId', splittedPath[3])
      .replace(':retrospectiveId', splittedPath[5]);
    return <Redirect to={redirectPath} />;
  }

  // Handle plan redirect if not logged in but public access is enabled
  if (planMatch && !isAuthenticated) {
    const redirectPath = PUBLIC_PLAN_PREZ_ROOT_ROUTE.replace(':workspaceSlug', splittedPath[1]).replace(
      ':planId',
      splittedPath[3],
    );
    return <Redirect to={redirectPath} />;
  }

  // Redirect to Login if not authed
  if (!isAuthenticated) {
    return <LogoutWithStoredPath />;
  }

  const dashboardRoutes = React.lazy(() => import('./DashboardRoutes'));
  const accountRoutes = React.lazy(() => import('./AccountRoutes'));
  const publicTemplatesRoutes = React.lazy(() => import('./PublicTemplatesRoutes'));
  const workspaceRoutes = React.lazy(() => import('./WorkspaceRoutes'));

  // Now we can display the app with the Chrome because we're authenticated
  return (
    <Switch>
      <Route path={ROOT_ROUTE} exact={true}>
        <MembershipRedirect />
      </Route>
      <Route path={DASHBOARD_ROOT_ROUTE}>
        <DashboardRoutes />
      </Route>
      <Route path={ACCOUNT_ROOT_ROUTE}>
        <AccountRoutes />
      </Route>
      <Route path={PUBLIC_TEMPLATES_ROOT_ROUTE}>
        <PublicTemplatesRoutes />
      </Route>
      <Route path={WORKSPACE_ROOT_ROUTE}>
        <WorkspaceRoutes />
      </Route>
    </Switch>
  );

  // Now we can display the app with the Chrome because we're authenticated
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route path={ROOT_ROUTE} exact={true}>
          <MembershipRedirect />
        </Route>
        <Route path={DASHBOARD_ROOT_ROUTE} component={dashboardRoutes} />
        <Route path={ACCOUNT_ROOT_ROUTE} component={accountRoutes} />
        <Route path={PUBLIC_TEMPLATES_ROOT_ROUTE} component={publicTemplatesRoutes} />
        <Route path={WORKSPACE_ROOT_ROUTE} component={workspaceRoutes} />
      </Switch>
    </Suspense>
  );
}

export default React.memo(Routes);

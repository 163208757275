import { addDays, isSameDay, startOfWeek } from 'date-fns';
import KoalaTextButton from 'koala/components/TextButton';
import React, { RefObject, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { midString } from 'state/reducers/utils';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory } from 'react-router-dom';

import { Initiative, Objective, Outcome } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import InitiativeCard from './InitiativeCard';
import { editorUpdateInitiative } from 'state/actions/workspaceEditorActions';
import KoalaIcon from 'koala/components/Icons';
import CreateInitiativeCard from './CreateInitiativeCard';
import { useTranslation } from 'react-i18next';
import { TFunction, i18n } from 'i18next';
import { formatLocale } from 'utils/dateUtils';
import * as integrationUtils from 'utils/integrationUtils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ObjectiveTitle = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  font-weight: bold;
  position: sticky;
  left: 0px;
  z-index: 1;
  width: 100%;
  max-width: 50rem;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Block = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-right: 1px solid ${theme.colors.N10};
  border-bottom: 1px solid ${theme.colors.N10};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 20rem;
`;
const HeaderBlock = styled(Block)`
  border-right: 1px solid ${theme.colors.N0};
  border-top: 1px solid ${theme.colors.N10};
`;
const OutcomeStickyBlock = styled(Block)`
  color: ${theme.colors.N60};
  position: sticky;
  background-color: ${theme.colors.N0};
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;
const TaskBlock = styled(Block)<{ isCurrent?: boolean }>`
  background-color: ${({ isCurrent }) => (isCurrent ? theme.colors.G10 : theme.colors.N3)};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x1};
  gap: ${theme.spacing.x1};

  .add-task {
    display: none;
  }
  :hover {
    .add-task {
      display: flex;
    }
  }
`;
const HeaderRow = styled.div<{ columnCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columnCount }) => columnCount}, auto);
  grid-template-rows: auto;
`;

const Row = styled.div<{ columnCount: number; rowCount: number }>`
  display: grid;
  grid-template-columns: auto repeat(${({ columnCount }) => columnCount}, auto);
  grid-template-rows: repeat(${({ rowCount }) => rowCount}, auto);
  width: fit-content;
`;

const UnsetInitiatives = styled.div`
  button {
    width: 100%;
    min-width: 1rem;
    display: block;
    overflow: hidden;
    white-space: normal;
  }

  .btn--unset {
    font-weight: 600;
    text-align: left;
  }
`;

const CreateSpacer = styled.div`
  height: 3rem;
  margin-top: ${theme.spacing.half};
`;
const CreateSection = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  color: ${theme.colors.N80};
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  border: none;
  width: 100%;
  :hover {
    cursor: pointer;
    background: ${theme.colors.N10};
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
    filter: none;
  }
`;

const InitiativeWrapper = styled.div<{ isDragging: boolean }>`
  background: ${theme.colors.N0};
  box-shadow: ${(props) => (props.isDragging ? '0px 0px 4px rgba(21, 21, 21, 0.15)' : 'unset')};
  border-radius: 4px;
  margin-top: 0.4rem;
`;

function compare(a: Initiative, b: Initiative) {
  if (!a.due_at || !b.due_at) {
    return 0;
  }
  if (a.due_at < b.due_at) {
    return -1;
  } else {
    return 1;
  }
}

interface Props {
  objective: Objective;
  initiatives: Initiative[];
  outcomes: Outcome[];
  weeks: Date[];
  isFirst?: boolean;
  scrollContainerRef: RefObject<HTMLDivElement>;
}

function ObjectiveSection(props: Props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { objective, outcomes, initiatives, weeks, isFirst, scrollContainerRef } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const dispatch = useDispatch();
  const [showNewTaskCard, setShowNewTaskCard] = useState('');
  const [currentWeekDiv, setCurrentWeekDiv] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentWeekDiv && scrollContainerRef?.current) {
      const containerC = scrollContainerRef.current;
      const containerCLeft = containerC.getBoundingClientRect().left;
      const weekDivLeft = currentWeekDiv.getBoundingClientRect().left;
      const scrollAmount = weekDivLeft - containerCLeft - containerC.clientWidth / 2;

      containerC.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  }, [currentWeekDiv, scrollContainerRef]);

  if (!outcomes) {
    return <></>;
  }

  const handleShowUnsetInitiatives = (outcomeId: string) => {
    const path = `#outcome:${outcomeId}:view.unset`;
    history.push(path);
  };
  const handleCancel = () => {
    setShowNewTaskCard('');
  };

  interface InitiativeParams {
    column_rank: string;
    due_at: Date;
  }
  const updateInitiative = (initiativeId: string, initiativeParams: InitiativeParams) => {
    dispatch(editorUpdateInitiative(initiativeId, initiativeParams));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const initiativeId = result.draggableId;
    const initiative = initiatives.find((initiative) => initiative.id === initiativeId);
    // check initiative isn't integration
    if (initiative && initiative.integration_remote_id && initiative.integration_type) {
      const integrationType = integrationUtils.getIntegrationType(initiative);
      const alertMessage = t('workspacePlan.initiatives.integrationAlert', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
        integrationType,
      });
      alert(alertMessage);
      return;
    }
    const [outcomeId, week] = result.destination.droppableId.split('_');

    let column_rank = '';
    const weekInitiatives = initiativesByOutcome[outcomeId][week];
    if (weekInitiatives && result.destination.droppableId !== result.source.droppableId) {
      const index = result.destination.index;
      const currentItem = initiativesByOutcome[outcomeId][week][index];
      let currentItemRank = currentItem && currentItem.column_rank ? currentItem.column_rank : '';
      const previousItem = initiativesByOutcome[outcomeId][week][index - 1];
      let previousItemRank = previousItem && previousItem.column_rank ? previousItem.column_rank : '';
      column_rank = midString(previousItemRank, currentItemRank);
    }
    if (weekInitiatives && result.destination.droppableId === result.source.droppableId) {
      if (result.source.index === result.destination.index) {
        return;
      }
      // going backwards
      if (result.source.index > result.destination.index) {
        const index = result.destination.index;
        const currentItem = initiativesByOutcome[outcomeId][week][index];
        let currentItemRank = currentItem && currentItem.column_rank ? currentItem.column_rank : '';
        const previousItem = initiativesByOutcome[outcomeId][week][index - 1];
        let previousItemRank = previousItem && previousItem.column_rank ? previousItem.column_rank : '';
        column_rank = midString(previousItemRank, currentItemRank);
      }
      // going forwards
      if (result.source.index < result.destination.index) {
        const index = result.destination.index;
        const currentItem = initiativesByOutcome[outcomeId][week][index];
        let currentItemRank = currentItem && currentItem.column_rank ? currentItem.column_rank : '';
        const nextItem = initiativesByOutcome[outcomeId][week][index + 1];
        let nextItemRank = nextItem && nextItem.column_rank ? nextItem.column_rank : '';
        column_rank = midString(currentItemRank, nextItemRank);
      }
    }

    const fridayDate = addDays(new Date(week), 4);
    const initiativeParams: InitiativeParams = { column_rank, due_at: fridayDate };
    updateInitiative(initiativeId, initiativeParams);
  };

  const renderColumn = (
    week: Date,
    initiatives: Initiative[],
    outcomeId: string,
    index: number,
    t: TFunction,
    i18n: i18n,
  ) => {
    const isCurrentWeek = isSameDay(week, currentWeek);
    const id = `${outcomeId}_${week.toISOString()}`;
    return (
      <Droppable droppableId={id} key={index}>
        {(provided, snapshot) => {
          const isDraggingStyle = snapshot.isDraggingOver ? { backgroundColor: '#8CC1B5' } : {};
          return (
            <TaskBlock ref={provided.innerRef} {...provided.innerRef} isCurrent={isCurrentWeek} style={isDraggingStyle}>
              <div>
                {initiatives.map((initiative, index) => (
                  <Draggable draggableId={initiative.id} index={index} key={initiative.id}>
                    {(provided, snapshot) => (
                      <InitiativeWrapper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                      >
                        <InitiativeCard initiativeId={initiative.id} outcomeId={outcomeId} key={initiative.id} />
                      </InitiativeWrapper>
                    )}
                  </Draggable>
                ))}
                {showNewTaskCard !== id && (
                  <CreateSpacer>
                    <CreateSection className="add-task" tabIndex={0} onClick={() => setShowNewTaskCard(id)}>
                      <KoalaIcon iconName="plus" iconSize="small" />
                      {t('workspacePlan.initiatives.create', {
                        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
                      })}
                    </CreateSection>
                  </CreateSpacer>
                )}
                {showNewTaskCard === id && (
                  <CreateInitiativeCard week={week} outcomeId={outcomeId} handleCancel={handleCancel} />
                )}
              </div>
            </TaskBlock>
          );
        }}
      </Droppable>
    );
  };

  const initiativesByOutcome: { [outcomeId: string]: { [dueDate: string]: Initiative[] } } = {};

  if (initiatives) {
    initiatives.forEach((initiative) => {
      let weekDue: string;
      if (initiative.due_at) {
        weekDue = startOfWeek(new Date(initiative.due_at), { weekStartsOn: 1 }).toISOString();
      } else if (initiative.integration_type && initiative.integration_remote_id) {
        const dueDate = integrationUtils.getDueDate(initiative);
        weekDue = dueDate ? startOfWeek(new Date(dueDate), { weekStartsOn: 1 }).toISOString() : 'unset';
      } else {
        weekDue = 'unset';
      }
      if (initiativesByOutcome[initiative.outcome_id] && initiativesByOutcome[initiative.outcome_id][weekDue]) {
        initiativesByOutcome[initiative.outcome_id][weekDue].push(initiative);
      } else if (initiativesByOutcome[initiative.outcome_id]) {
        initiativesByOutcome[initiative.outcome_id][weekDue] = [initiative];
      } else {
        const test = { [weekDue]: [initiative] };
        initiativesByOutcome[initiative.outcome_id] = test;
      }
    });
  }

  const handleObjectiveClick = () => {
    history.push(`#objective:${objective.nano_slug}:show`);
  };

  const currentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  return (
    <>
      <ObjectiveTitle onClick={handleObjectiveClick}>{objective.title}</ObjectiveTitle>

      <Container>
        {/* header row */}
        <HeaderRow columnCount={weeks.length + 2}>
          <HeaderBlock></HeaderBlock>
          {weeks.map((week, i) => {
            const isCurrentWeek = isSameDay(week, currentWeek);
            const isCenter = isCurrentWeek && isFirst;
            return (
              <HeaderBlock ref={isCenter ? setCurrentWeekDiv : undefined} key={i}>
                {formatLocale(week, 'd MMM', i18n)}
              </HeaderBlock>
            );
          })}
        </HeaderRow>
        {/* outcome rows */}
        {outcomes &&
          outcomes.map((outcome) => {
            const initiatives = initiativesByOutcome[outcome.id];
            const initiativeCount = initiatives && initiatives['unset'] ? initiatives['unset'].length : 0;
            return (
              <Row columnCount={weeks.length + 1} rowCount={outcomes.length} key={outcome.id}>
                <OutcomeStickyBlock>
                  <div>{outcome.title}</div>
                  {initiatives && initiatives['unset'] && (
                    <UnsetInitiatives>
                      <KoalaTextButton
                        onClick={() => handleShowUnsetInitiatives(outcome.id)}
                        appearance="subtle"
                        size="small"
                        className="btn--unset"
                      >
                        {t(`workspacePlans.table.withoutDueDates`, {
                          count: initiativeCount,
                          label: translate(currentWorkspace, CustomTermKey.INITIATIVE, initiativeCount).toLowerCase(),
                        })}
                      </KoalaTextButton>
                    </UnsetInitiatives>
                  )}
                </OutcomeStickyBlock>

                <DragDropContext onDragEnd={onDragEnd}>
                  {weeks.map((week, i) => {
                    const weekString = week.toISOString();
                    const weekInitiatives =
                      initiatives && initiatives[weekString] ? initiatives[weekString].sort(compare) : [];
                    return renderColumn(week, weekInitiatives, outcome.id, i, t, i18n);
                  })}
                </DragDropContext>
              </Row>
            );
          })}
      </Container>
    </>
  );
}

export default ObjectiveSection;

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';

// Components
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TipsContent = styled.div`
  font-size: 1.4rem;
  padding: 0 ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  h3 {
    font-size: 2.4rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.4rem;
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x1};
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

interface Props {}

function MovePlan(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const closePanel = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.tips.okrs.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  const handleLeaveReview = (e: any) => {
    e.preventDefault();
    history.push('#review::create');
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>🎁 Get 10k extra AI credits</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <TipsContent>
          <h3>Never run out of AI credits in Tability</h3>
          <p>AI credits are used any time you generate OKRs, tasks, or use any of the AI features in Tability.</p>
          <p>You can get 10k AI credits for free by leaving us a positive review on G2.</p>
          <ol>
            <li>
              1. Click on the following link 👉
              <a href="https://www.g2.com/products/tability/take_survey" target="_blank" rel="noreferrer noopener">
                Leave a G2 review
              </a>
            </li>
            <li>2. Leave a positive review</li>
            <li>3. Send us a screenshot of your review</li>
          </ol>
          <p>That's it! We will add 10k AI extra credits to your workspace once your review is verified.</p>
          <div>
            <KoalaButton appearance="primary" onClick={handleLeaveReview}>
              Leave a review
            </KoalaButton>
          </div>
        </TipsContent>
      </PanelContent>
    </PanelGrid>
  );
}

export default MovePlan;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

import NewCheckin from 'modals/NewCheckin';
import EditCheckin from 'modals/EditCheckin';
import CreatePlan from 'modals/CreatePlan';
import SharePlan from 'modals/SharePlan';
import EditPlanPermissions from 'modals/EditPlanPermissions';
import EditTeamMembers from 'modals/EditTeamMembers';
import DeletePlan from 'modals/DeletePlan';
import InviteTeam from 'modals/InviteTeam';
import UpgradeRequired from 'modals/UpgradeRequired';
import TemplatesDirectoryV2 from 'modals/TemplatesDirectoryV2';
import OnboardingTemplatesDirectoryV2 from 'modals/OnboardingTemplatesDirectoryV2';
import MoveInitiative from 'modals/MoveInitiative';
import MoveOutcome from 'modals/MoveOutcome';
import MovePlan from 'modals/MovePlan';
import DownloadChart from 'modals/DownloadChart';
import RenameSegment from 'modals/RenameSegment';
import CreateOutcomeSegment from 'modals/CreateOutcomeSegment';
import CreateInitiativeSegment from 'modals/CreateInitiativeSegment';
import CreateTeam from 'modals/CreateTeam';
import PopulateAI from 'modals/PopulateAI';
import PreviewAI from 'modals/PreviewAI';
import ChatAIOutcomes from 'modals/ChatAIOutcomes';
import ChatAIInitiatives from 'modals/ChatAIInitiatives';
import CreateObjectiveSegment from 'modals/CreateObjectiveSegment';
import SessionTimeoutWarning from 'modals/SessionTimeoutWarning';
import AddOutcomeRelationship from 'modals/AddOutcomeRelationship';
import AddObjectiveRelationship from 'modals/AddObjectiveRelationship';
import PopulateOutcomeAI from 'modals/PopulateOutcomeAI';
import ShareOutcome from 'modals/ShareOutcome';
import ShareObjective from 'modals/ShareObjective';
import StreakLadder from 'modals/StreakLadder';
import PlanPublished from 'modals/PlanPublished';
import WorkspaceCheckinsQuota from 'modals/WorkspaceCheckinsQuota';
import EditTeamColor from 'modals/EditTeamColor';
import EditReporting from 'modals/EditReporting';
import ConnectChartMogul from 'modals/ConnectChartMogul';
import ConnectFavro from 'modals/ConnectFavro';
import OnboardingChecklist from 'modals/OnboardingChecklist';
import ConnectAmplitude from 'modals/ConnectAmplitude';
import UpdateInitiativeWorkState from 'modals/UpdateInitiativeWorkState';
import ConnectZendeskSupport from 'modals/ConnectZendeskSupport';
import BetaLanguage from 'modals/BetaLanguage';
import WorkspaceShortcuts from 'modals/WorkspaceShortcuts';
import CommandCenter from 'modals/CommandCenter';
import CreateRetrospective from 'modals/CreateRetrospective';
import ShareRetrospective from 'modals/ShareRetrospective';
import MoveObjective from 'modals/MoveObjective';
import ConnectPostgreSQL from 'modals/ConnectPostgreSQL';
import ConnectMySQL from 'modals/ConnectMySQL';
import ConnectMariaDB from 'modals/ConnectMariaDB';
import ImportPlansFromCSV from 'modals/ImportPlansFromCSV';
import ImportPlanContent from 'modals/ImportPlanContent';
import ImportPlanContentOnboarding from 'modals/ImportPlanContentOnboarding';
import DeletePlans from 'modals/DeletePlans';
import ArchivePlans from 'modals/ArchivePlans';
import AuditLog from 'modals/AuditLog';
import ConnectSalesforce from 'modals/ConnectSalesforce';
import DeleteMemberships from 'modals/DeleteMemberships';
import BulkCheckin from 'modals/BulkCheckin';
import LoomVideo from 'modals/LoomVideo';
import CreateMapSegment from 'modals/CreateMapSegment';
import PlanSubscribers from 'modals/PlanSubscribers';
import CreateDashboard from 'modals/CreateDashboard';
import RenameDashboard from 'modals/RenameDashboard';
import CreateStandup from 'modals/CreateStandup';
import ConnectTableau from 'modals/ConnectTableau';
import ConnectMonday from 'modals/ConnectMonday';
import ConnectChargebee from 'modals/ConnectChargebee';
import ShareInitiative from 'modals/ShareInitiative';
import ShareLinkViaEmail from 'modals/ShareLinkViaEmail';
import ConnectSentry from 'modals/ConnectSentry';
import ImportInitiatives from 'modals/ImportInitiatives';
import ImportUsers from 'modals/ImportUsers';

const Mask = styled.div<{ showModal: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showModal ? '80' : '-1')};
  opacity: ${(props) => (props.showModal ? '1' : '0')};
  transition: opacity 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div<{ showModal: boolean }>`
  position: fixed;
  top: 10%;
  left: 50%;
  height: 80%;
  margin-left: -50rem;
  width: 100rem;
  max-width: 100rem;
  max-height: 80rem;
  z-index: 80;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  transition: display 0.1s ease-out;

  &.checkin-modal {
    height: 96vh;
    max-height: 96vh;
    width: 96%;
    max-width: 96%;

    top: 50%;
    left: 50%;

    margin-left: -48%;
    margin-top: -48vh;
  }

  &.popup-global-modal {
    height: 29rem;
    top: 50%;
    width: 60rem;
    max-width: 60rem;
    margin-left: -30rem;
    margin-top: -20rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      margin-left: 0;
      left: 0;
    }
  }
  &.small-global-modal {
    height: 45rem;
    top: 50%;
    width: 60rem;
    max-width: 60rem;
    margin-left: -30rem;
    margin-top: -20rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      margin: 20rem 0 0 0;
      top: unset;
      left: unset;
      overflow: auto;
    }
  }

  &.tiny-global-modal {
    height: 20rem;
    top: 50%;
    width: 60rem;
    max-width: 60rem;
    margin-left: -30rem;
    margin-top: -10rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      margin: 20rem 0 0 0;
      top: unset;
      left: unset;
      overflow: auto;
    }
  }

  &.upgrade-global-modal {
    height: 30rem;
    top: 50%;
    width: 60rem;
    max-width: 60rem;
    margin-left: -30rem;
    margin-top: -20rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      left: 0;
      margin-left: 0;
      height: 40rem;
    }
  }

  &.published-modal {
    height: 35rem;
    top: 50%;
    width: 60rem;
    max-width: 60rem;
    margin-left: -30rem;
    margin-top: -20rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      left: 0;
      margin-left: 0;
      height: 40rem;
    }
  }

  &.medium-global-modal {
    height: 80rem;
    top: 50%;
    left: 50%;
    width: 80rem;
    max-width: 80rem;
    margin-left: -40rem;
    margin-top: -40rem;

    @media ${theme.devices.mobile} {
      width: 100%;
      margin: 20rem 0 0 0;
      margin-top: -35rem;
      left: unset;
      overflow: auto;
    }
  }

  &.small-plan-global-modal {
    height: 50rem;
    top: 50%;
    left: 50%;
    width: 70rem;
    max-width: 70rem;
    margin-left: -35rem;
    margin-top: -30rem;
  }

  &.share-global-modal {
    height: 70rem;
    top: 50%;
    left: 50%;
    width: 70rem;
    max-width: 80rem;
    margin-left: -35rem;
    margin-top: -35rem;
  }

  &.plan-global-modal {
    height: 60rem;
    top: 50%;
    left: 50%;
    width: 70rem;
    max-width: 70rem;
    margin-left: -35rem;
    margin-top: -35rem;
  }

  &.large-global-modal {
    height: 90vh;
    max-height: 90vh;
    width: 80%;
    max-width: 80%;

    top: 50%;
    left: 50%;

    margin-left: -40%;
    margin-top: -45vh;
  }

  &.full-screen-modal {
    height: 96vh;
    max-height: 96vh;
    width: 96%;
    max-width: 96%;

    top: 50%;
    left: 50%;

    margin-left: -48%;
    margin-top: -48vh;
  }

  &.mobile-full-screen {
    @media ${theme.devices.mobile} {
      width: 100%;
      left: 0px;
      margin-left: 0px;
      top: 0px;
      height: 100%;
      max-height: unset;
      margin: unset;
      max-width: unset;
    }
  }
  &.tablet-full-screen {
    @media ${theme.devices.tablet} {
      width: 100%;
      left: 0px;
      margin-left: 0px;
      top: 0px;
      height: 100%;
      max-height: unset;
      margin: unset;
      max-width: unset;
    }
  }
  &.laptop-full-screen {
    @media ${theme.devices.laptop} {
      width: 100%;
      left: 0px;
      margin-left: 0px;
      top: 0px;
      height: 100%;
      max-height: unset;
    }
  }

  &.download-chart-modal {
    height: 90%;
    max-height: 85rem;
    top: 5%;
    left: 50%;
    width: 80rem;
    max-width: 80rem;
    margin-left: -40rem;

    @media ${theme.devices.laptop} {
      width: 100%;
      left: 0px;
      margin-left: 0px;
      top: 0px;
      height: 100%;
      max-height: unset;
    }
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;

  background: #fff;
`;

export const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'modal-header'
    'modal-content'
    'modal-footer';
  height: 100%;
`;

export const ModalHeader = styled.div`
  grid-area: modal-header;
  padding: ${theme.spacing.x2} ${theme.spacing.x3};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }

  border-bottom: 1px solid ${theme.colors.blockBorder};
`;

export const ModalContent = styled.div`
  grid-area: modal-content;
  padding: ${theme.spacing.x3};
  position: relative;
  overflow: auto;

  &.grey-bg {
    background: ${theme.colors.N5};
  }

  &.hide-overflow {
    overflow: unset;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export const ModalFooter = styled.div`
  grid-area: modal-footer;
  padding: ${theme.spacing.x2} ${theme.spacing.x3};

  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
  border-top: 1px solid ${theme.colors.blockBorder};
`;

function Panel() {
  const dispatch = useDispatch();
  const globalModalContent: string = useSelector((state: any) => state.globalUI.globalModalContent);

  let showModal = false;
  let content = null;
  let modalClassName = '';
  let showConfirmationBeforeClose = false;

  const context = useHotkeysContext();
  useHotkeys(`escape`, () => handleClosePanel(), { scopes: ['modals'], enabled: !!globalModalContent });

  // set scope so doesn't mess with panel/page below
  useEffect(() => {
    if (globalModalContent) {
      context.enableScope('modals');
    } else {
      context.disableScope('modals');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalModalContent]);

  // Check that we have the right params to display
  if (globalModalContent && globalModalContent.split(':').length === 3) {
    const [entityType, entityId, action] = globalModalContent.split(':');

    if (entityType === 'outcome' && action === 'create.checkin') {
      showModal = true;
      modalClassName = 'checkin-modal';
      showConfirmationBeforeClose = true;
      content = <NewCheckin outcomeId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'add.dependency') {
      showModal = true;
      modalClassName = 'plan-global-modal';
      content = <AddOutcomeRelationship outcomeId={entityId} />;
    }
    if (entityType === 'objective' && action === 'add.dependency') {
      showModal = true;
      modalClassName = 'plan-global-modal';
      content = <AddObjectiveRelationship objectiveId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'move') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <MoveOutcome outcomeId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'download.chart') {
      showModal = true;
      modalClassName = 'download-chart-modal';
      content = <DownloadChart outcomeId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'populate.ai') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <PopulateOutcomeAI outcomeId={entityId} />;
    }
    if (entityType === 'objective' && action === 'move') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <MoveObjective objectiveId={entityId} />;
    }
    if (entityType === 'checkin' && action === 'edit') {
      showModal = true;
      modalClassName = 'checkin-modal laptop-full-screen';
      showConfirmationBeforeClose = true;
      content = <EditCheckin checkinId={entityId} />;
    }
    if (entityType === 'plans' && action === 'delete') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <DeletePlans />;
    }
    if (entityType === 'plans' && action === 'archive') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ArchivePlans />;
    }
    if (entityType === 'plan' && action === 'delete') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <DeletePlan planId={entityId} />;
    }
    if (entityType === 'audit.plan' && action === 'show') {
      showModal = true;
      modalClassName = 'large-global-modal';
      content = <AuditLog isPlan={true} sourceId={entityId} />;
    }
    if (entityType === 'audit' && action === 'show') {
      showModal = true;
      modalClassName = 'large-global-modal';
      content = <AuditLog isPlan={false} sourceId={entityId} />;
    }
    if (entityType === 'plan' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <SharePlan planId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <ShareOutcome outcomeId={entityId} />;
    }
    if (entityType === 'objective' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <ShareObjective objectiveId={entityId} />;
    }
    if (entityType === 'plan' && action === 'edit.permissions') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <EditPlanPermissions planId={entityId} />;
    }

    if (entityType === 'team' && action === 'edit.members') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <EditTeamMembers teamId={entityId} />;
    }
    if (entityType === 'team' && action === 'set.color') {
      showModal = true;
      modalClassName = 'plan-global-modal';
      content = <EditTeamColor teamId={entityId} />;
    }

    if (entityType === 'people' && action === 'edit.reporting') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <EditReporting membershipId={entityId} />;
    }
    if (entityType === 'memberships' && action === 'delete') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <DeleteMemberships />;
    }
    if (entityType === 'plan' && action === 'create.plan') {
      showModal = true;
      modalClassName = 'plan-global-modal tablet-full-screen';
      content = <CreatePlan planId={entityId} />;
    }
    if (entityType === 'plan' && action === 'create.template') {
      showModal = true;
      modalClassName = 'small-global-modal tablet-full-screen';
      content = <CreatePlan planId={entityId} isTemplate={true} />;
    }
    if (entityType === 'plan' && action === 'show.templates') {
      showModal = true;
      modalClassName = 'laptop-full-screen';
      content = <TemplatesDirectoryV2 planId={entityId} />;
    }
    if (entityType === 'plan' && action === 'subscribers') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <PlanSubscribers planId={entityId} />;
    }
    if (entityType === 'onboarding' && action === 'show.templates') {
      showModal = true;
      modalClassName = 'laptop-full-screen';
      content = <OnboardingTemplatesDirectoryV2 />;
    }
    if (entityType === 'plan' && action === 'import.content') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ImportPlanContent planId={entityId} />;
    }
    if (entityType === 'onboarding' && action === 'import.content') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ImportPlanContentOnboarding />;
    }
    if (entityType === 'plan' && action === 'populate.ai') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <PopulateAI planId={entityId} />;
    }
    if (entityType === 'plan' && action === 'preview.ai') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <PreviewAI planId={entityId} />;
    }
    if (entityType === 'plan' && action === 'chat.ai.outcomes') {
      showModal = true;
      modalClassName = 'large-global-modal';
      content = <ChatAIOutcomes planId={entityId} />;
    }
    if (entityType === 'outcome' && action === 'chat.ai.initiatives') {
      showModal = true;
      modalClassName = 'large-global-modal';
      content = <ChatAIInitiatives outcomeId={entityId} />;
    }
    if (entityType === 'plan' && action === 'move') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <MovePlan planId={entityId} />;
    }
    if (entityType === 'plans' && action === 'import.csv') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ImportPlansFromCSV />;
    }
    if (entityType === 'plan' && action === 'published') {
      showModal = true;
      modalClassName = 'published-modal';
      content = <PlanPublished />;
    }
    if (entityType === 'workspace' && action === 'invite') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <InviteTeam />;
    }
    if (entityType === 'workspace' && action === 'import.users') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ImportUsers />;
    }
    if (entityType === 'workspace' && action === 'upgrade') {
      showModal = true;
      modalClassName = 'upgrade-global-modal';
      content = <UpgradeRequired billingPlanId={entityId} />;
    }
    if (entityType === 'workspace' && action === 'streak.ladder') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <StreakLadder />;
    }
    if (entityType === 'initiative' && action === 'move') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <MoveInitiative initiativeId={entityId} />;
    }
    if (entityType === 'initiative' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <ShareInitiative initiativeId={entityId} />;
    }
    if (entityType === 'initiative' && action === 'work_state') {
      showModal = true;
      modalClassName = 'plan-global-modal';
      content = <UpdateInitiativeWorkState initiativeId={entityId} />;
    }
    if (entityType === 'segment' && action === 'create.outcome') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateOutcomeSegment filterHash={entityId} />;
    }
    if (entityType === 'segment' && action === 'create.initiative') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateInitiativeSegment filterHash={entityId} />;
    }
    if (entityType === 'segment' && action === 'create.objective') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateObjectiveSegment filterHash={entityId} />;
    }
    if (entityType === 'segment' && action === 'create.map') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateMapSegment filterHash={entityId} />;
    }
    if (entityType === 'workspace' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <ShareLinkViaEmail />;
    }
    if (entityType === 'segment' && action === 'rename') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <RenameSegment segmentId={entityId} />;
    }
    if (entityType === 'session' && action === 'timeout') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <SessionTimeoutWarning />;
    }
    if (entityType === 'workspace' && action === 'create.team') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateTeam />;
    }
    if (entityType === 'all' && action === 'checkins_quota') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <WorkspaceCheckinsQuota />;
    }
    if (entityType === 'workspace' && action === 'connect.chartmogul') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectChartMogul />;
    }
    if (entityType === 'workspace' && action === 'connect.chargebee') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectChargebee />;
    }
    if (entityType === 'workspace' && action === 'connect.monday') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectMonday />;
    }
    if (entityType === 'workspace' && action === 'connect.favro') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectFavro />;
    }
    if (entityType === 'workspace' && action === 'connect.postgreSQL') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ConnectPostgreSQL />;
    }
    if (entityType === 'workspace' && action === 'connect.mySQL') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ConnectMySQL />;
    }
    if (entityType === 'workspace' && action === 'connect.mariaDB') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <ConnectMariaDB />;
    }
    if (entityType === 'workspace' && action === 'connect.tableau') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectTableau />;
    }
    if (entityType === 'workspace' && action === 'connect.salesforce') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectSalesforce />;
    }
    if (entityType === 'workspace' && action === 'connect.zendesk_support') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <ConnectZendeskSupport />;
    }
    if (entityType === 'workspace' && action === 'onboarding') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <OnboardingChecklist />;
    }
    if (entityType === 'workspace' && action === 'connect.amplitude') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectAmplitude />;
    }
    if (entityType === 'workspace' && action === 'connect.sentry') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ConnectSentry />;
    }
    if (entityType === 'user' && action === 'beta.language') {
      showModal = true;
      modalClassName = 'tiny-global-modal';
      content = <BetaLanguage />;
    }
    if (entityType === 'workspace' && action === 'shortcuts') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <WorkspaceShortcuts />;
    }
    if (entityType === 'workspace' && action === 'show.loom') {
      showModal = true;
      modalClassName = 'full-screen-modal';
      content = <LoomVideo videoId={entityId} />;
    }
    if (entityType === 'workspace' && action === 'command.center') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <CommandCenter />;
    }
    if (entityType === 'workspace' && action && action === 'bulk.checkin') {
      showModal = true;
      modalClassName = 'checkin-modal';
      content = <BulkCheckin />;
    }
    if (entityType === 'plan' && action === 'create.retrospective') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateRetrospective planId={entityId} />;
    }
    if (entityType === 'retrospective' && action === 'share') {
      showModal = true;
      modalClassName = 'share-global-modal';
      content = <ShareRetrospective retrospectiveId={entityId} />;
    }
    if (entityType === 'dashboard' && action === 'create') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <CreateDashboard />;
    }
    if (entityType === 'dashboard' && action === 'rename') {
      showModal = true;
      modalClassName = 'popup-global-modal';
      content = <RenameDashboard dashboardId={entityId} />;
    }
    if (entityType === 'workspace' && action === 'create.standup') {
      showModal = true;
      modalClassName = 'medium-global-modal';
      content = <CreateStandup />;
    }
    if (entityType === 'outcome' && action === 'import.initiatives') {
      showModal = true;
      modalClassName = 'small-global-modal';
      content = <ImportInitiatives outcomeId={entityId} />;
    }
  }

  const handleClosePanel = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    if (!showConfirmationBeforeClose) {
      dispatch(setGlobalModalContent(''));
    } else if (window.confirm('Are you sure you want to close? Changes will not be saved.')) {
      dispatch(setGlobalModalContent(''));
    } else {
      return false;
    }
  };

  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div>
      <Mask onClick={handleClosePanel} showModal={showModal} />
      <ContentWrapper showModal={showModal} className={modalClassName} onClick={handleStopPropagation}>
        <Content>{content}</Content>
      </ContentWrapper>
    </div>
  );
}

export default React.memo(Panel);

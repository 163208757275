import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useParams } from 'react-router-dom';
import parse from 'parse-link-header';

// API
import * as remoteApi from 'api/remote';

// Components
import Loader from 'components/Loader';
import KoalaButton from 'koala/components/Button';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import { Outcome } from 'types';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

const Item = styled.div`
  padding: ${theme.spacing.x1} 0;
  &:hover {
    background: #f3f3f3;
  }

  > div {
    display: flex;
    > div {
      margin-right: ${theme.spacing.x1};
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x1};
`;

interface Props {
  planId: any;
}

function OutcomesTab(props: Props) {
  const { planId } = props;
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const filter = {
    archived: true,
    plan_id: planId,
  };

  // Construct the query key using the plan Id
  const queryKey = [
    queryKeys.outcomes,
    'search',
    {
      workspaceSlug,
      filter,
    },
  ];

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Get the plan details
  const {
    data: outcomesResponse,
    isLoading,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  }: any = useInfiniteQuery(queryKey, remoteApi.searchOutcomes, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const [archiveOutcomeMutation, { isLoading: isArchiving }] = useMutation(remoteApi.archiveOutcome, {
    onSuccess: (response) => {
      const outcome: Outcome = response.data;
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcome.nano_slug]);
    },
  });

  const unarchiveOutcome = (outcomeId: string) => {
    const params = {
      archived: false,
    };
    const mutationParams = {
      outcomeId,
      outcome: params,
    };
    archiveOutcomeMutation(mutationParams);
  };

  let outcomes = [];
  if (outcomesResponse) {
    outcomes = outcomesResponse;
  }

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const hasItems = outcomes[0].data.length > 0;

  return (
    <div>
      {!hasItems && (
        <div>
          {t(`panels.archive.noItems`, {
            label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </div>
      )}
      {outcomes.map((group: any, i: number) => {
        return (
          <Fragment key={i}>
            {group.data.map((outcome: any) => {
              return (
                <Item key={outcome.id}>
                  <Title>
                    <KoalaTextBadge variant="violet-light" isLowercase>
                      {t(`shared.status.archived`)}
                    </KoalaTextBadge>
                    {outcome.title}
                  </Title>
                  <KoalaButton
                    onClick={() => unarchiveOutcome(outcome.id)}
                    disabled={isArchiving}
                    loading={isArchiving}
                    appearance="subtle"
                  >
                    {t(`shared.unarchive`)}
                  </KoalaButton>
                </Item>
              );
            })}
          </Fragment>
        );
      })}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            appearance="secondary"
            loading={isFetchingMore}
            disabled={isFetchingMore}
          >
            {t(`shared.loadMore`)}
          </KoalaButton>
        </LoadMore>
      )}
    </div>
  );
}

export default React.memo(OutcomesTab);

import React, { Fragment, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { Dashboard, Workspace } from 'types';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import WorkspaceHeader from 'components/WorkspaceHeader';
import { ChromeContent } from 'components/Chrome';
import { Helmet } from 'react-helmet';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import KoalaLoader from 'koala/components/Loader';
import { AxiosResponse } from 'axios';
import { hasPremiumSubscription, hasTrial } from 'utils/workspaceUtils';
import UpgradeRequiredV4 from 'pages/WorkspaceDashboards/UpgradeRequiredV4';
import UpgradeBanner from 'pages/WorkspaceDashboards/UpgradeBanner';
import KoalaButton from 'koala/components/Button';
import ShareButton from 'components/ShareButton';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import DashboardContent from './DashboardContent';

const DashboardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: ${theme.spacing.x1};
  padding: 0px ${theme.spacing.x1};
`;
const Mask = styled.div`
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  z-index: 80;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  max-height: 25rem;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -40rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};

  .AriaMenuButton-menu {
    left: 0;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 150rem;
  padding: 1.2rem ${theme.spacing.x2};
  display: flex;
  flex-direction: column;

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${theme.spacing.half};

  position: absolute;
  right: 8px;
  top: 8px;
`;

export const WidgetBlock = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${theme.colors.N10};
  background: ${theme.colors.N0};
  overflow: hidden;

  .drag-handle {
    height: 100%;
  }

  &.read-only {
    .drag-handle {
      display: none;
    }
    .widget-type {
      padding: 1.2rem ${theme.spacing.x2};
      width: 100%;
    }
  }
  &.edit {
    padding-left: 0px;
  }

  &.objective-list,
  &.outcome-list,
  &.initiative-list {
    overflow: hidden;
    height: 100%;
  }
`;

interface Props {
  workspace: Workspace;
}

function WorkspaceDashboardView(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { dashboardId } = useParams<{
    dashboardId: string;
  }>();
  const [dashboard, setDashboard] = useState<Dashboard>();

  // Get the dashboard details
  const queryKey = [queryKeys.currentDashboard, dashboardId];
  const staleTime = 0;
  useQuery(queryKey, remoteApi.fetchDashboardDetails, {
    staleTime,
    onSuccess: (response: AxiosResponse<Dashboard>) => {
      const data = response.data;
      setDashboard(data);
    },
  });

  const handleEdit = () => {
    history.push(`/${workspace.slug}/dashboards/${dashboardId}/edit`);
  };

  // Set upgrade required to true
  let upgradeRequired = true;
  let showUpgradeBanner = false;

  // Only Premium workspaces should have access to insights
  if (hasPremiumSubscription(workspace) || hasTrial(workspace)) {
    upgradeRequired = false;
  }

  if (hasTrial(workspace)) {
    showUpgradeBanner = true;
  }

  if (!dashboard) {
    return (
      <Fragment>
        <MobileReadyChromeHeader>
          <WorkspaceHeader workspace={workspace} useGreyBackground={true} />
        </MobileReadyChromeHeader>
        <ChromeContent isGreyBackground>
          <Wrapper>
            <KoalaLoader size="large" />
          </Wrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:dashboard:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  return (
    <Fragment>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={
            <HeaderTitle>
              <h1>{dashboard.title}</h1>
            </HeaderTitle>
          }
        />
      </MobileReadyChromeHeader>
      <ChromeContent>
        {upgradeRequired && (
          <Mask>
            <UpgradeModal>{<UpgradeRequiredV4 />}</UpgradeModal>
          </Mask>
        )}
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceDashboards.title')} | Tability
          </title>
        </Helmet>
        <Wrapper>
          {showUpgradeBanner && <UpgradeBanner />}
          <DashboardActions>
            {!dashboard.premade_type && (
              <KoalaButton onClick={handleEdit} appearance="subtle">
                {t('workspaceDashboards.edit')}
              </KoalaButton>
            )}
            <ShareButton link={link} id={dashboardId} shareCallback={handleShare} />
          </DashboardActions>
          <DashboardContent dashboard={dashboard} />
        </Wrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceDashboardView;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Outcome } from 'types';
import { getOutcomeGrowthTarget } from 'utils/outcomeUtils';
import PanelLabelButton from './PanelLabelButton';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import KoalaLoader from 'koala/components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import * as remoteApi from 'api/remote';
import { AxiosError } from 'axios';

import { setLoadingOutcomeMetric } from 'state/actions/workspaceEditorActions';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const TargetGrowth = styled.div`
  font-size: 1.2rem;
  color: ${theme.colors.subtleText};
`;

function nFormatter(num: number, digits: number) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

interface Props {
  outcome: Outcome;
  selector: string;
  metricLoading?: boolean;
}

function OutcomeBlockTarget(props: Props) {
  const { outcome, selector, metricLoading } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const history = useHistory();
  const outcomesLoading: string[] = useSelector((state: any) => state.editorUI.outcomesLoading);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const handleEdit = () => {
    const hashPath = `#outcome:${outcome.nano_slug}:edit`;
    history.push(hashPath);
  };

  const { outcome_type, score_format, from, to } = outcome;

  const [adjustOutcomeMetricMutation, { isLoading }] = useMutation(remoteApi.updateOutcomeMetric, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setLoadingOutcomeMetric(outcome.id, false));
    },
    onError: (e: AxiosError) => {
      dispatch(setLoadingOutcomeMetric(outcome.id, false));
      const message = t(`workspacePlan.write.metricError`) ?? 'Unable to detect metric';
      window.alert(message);
    },
  });

  let formattedOutcomeFrom = null;
  let formattedOutcomeTo = null;

  if (score_format) {
    formattedOutcomeFrom = from || from === 0 ? nFormatter(from, 2) : null;
    formattedOutcomeTo = to || to === 0 ? score_format.replace('_number_', nFormatter(to, 2)) : null;
  }
  const targetGrowth = getOutcomeGrowthTarget(outcome);

  if (outcomesLoading.includes(outcome.id)) {
    return (
      <PanelLabelButton className={`${selector}-target target-button`}>
        <KoalaLoader />
      </PanelLabelButton>
    );
  }

  let content = '—';

  if (outcome_type === 'improve_metric') {
    content = `${formattedOutcomeFrom} ➞ ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_above') {
    content = `x > ${formattedOutcomeTo}`;
  } else if (outcome_type === 'stay_below') {
    content = `x < ${formattedOutcomeTo}`;
  } else if (outcome_type === 'kpi') {
    if (score_format) {
      formattedOutcomeFrom = formattedOutcomeFrom ? score_format.replace('_number_', formattedOutcomeFrom) : null;
    }
    content = formattedOutcomeFrom ? `KPI ${formattedOutcomeFrom}` : '—';
  }

  const isLoadingMetric = metricLoading || isLoading;

  if (isLoadingMetric) {
    return (
      <PanelLabelButton className={`${selector}-target target-button`}>
        <KoalaLoader />
      </PanelLabelButton>
    );
  }

  const handleAutoDetectMetric = () => {
    dispatch(setLoadingOutcomeMetric(outcome.id, true));
    adjustOutcomeMetricMutation({ outcomeId: outcome.id });
  };

  const magicButton = () => {
    if (!currentWorkspace.open_ai_enabled) {
      return null;
    }

    const isDefaultParam =
      outcome.outcome_type === 'improve_metric' &&
      outcome.score_format === '_number_%' &&
      outcome.from === 0 &&
      outcome.to === 100;
    const blockId = `outcome:${outcome.id}`;
    if (isDefaultParam) {
      return (
        <>
          <KoalaIconButton
            className="ai-button auto-detect-button"
            edge="square"
            size="small"
            dataFor={`${blockId}-auto-detect-metric`}
            disabled={isLoadingMetric}
            iconName="sparkles"
            iconColor={theme.colors.V50}
            onClick={handleAutoDetectMetric}
          ></KoalaIconButton>
          <ReactTooltip type="dark" id={`${blockId}-auto-detect-metric`} className="tooltip" effect="solid">
            {t('workspacePlan.write.autoDetectMetric')}
          </ReactTooltip>
        </>
      );
    }
    return null;
  };

  return (
    <Container>
      <PanelLabelButton onClick={handleEdit} className={`${selector}-target target-button`}>
        {content}
      </PanelLabelButton>
      {magicButton()}
      {targetGrowth && (
        <TargetGrowth>
          {targetGrowth >= 0 && <span>+</span>}
          <span>{targetGrowth}%</span>
        </TargetGrowth>
      )}
    </Container>
  );
}

export default OutcomeBlockTarget;

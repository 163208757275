import React from 'react';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, LeaderboardRow, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { DashboardWidget, Membership, Workspace } from 'types';
import KoalaIcon from 'koala/components/Icons';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaLoader from 'koala/components/Loader';

const Container = styled.div`
  height: 18rem;
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function CheckinStreakLeaderboardWidget(props: Props) {
  const { widget } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();

  const queryKeyStreakLeaderboard = [queryKeys.workspaceYearReview, 'streak_leaderboard', currentWorkspace.slug];
  const { data: streakLeaderboardResponse, isLoading: isLoadingStreakLeaderboard } = useQuery(
    queryKeyStreakLeaderboard,
    remoteApi.fetchWorkspaceYearReviewStreakLeaderboard,
  );
  const streakLeaderboard: any[] = streakLeaderboardResponse ? streakLeaderboardResponse.data : [];

  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.checkinStreakLeaderboard');

  const handleUserClick = (membership: Membership) => {
    const path = `/${currentWorkspace.slug}/people/${membership.id}`;
    window.open(path, '_blank');
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        {isLoadingStreakLeaderboard && <KoalaLoader />}
        <Container className="panel-checkins-chart">
          {streakLeaderboard.length === 0 && <span>{t('workspaceDashboards.noActivity')}</span>}
          {streakLeaderboard.map((item: any, index: number) => {
            const membership: Membership = item.membership;
            let number_label = (index + 1).toString();
            if (index === 0) {
              number_label = '🔥';
            }
            return (
              <LeaderboardRow key={index}>
                <div className="number">{number_label}</div>
                <div className="profile">
                  <KoalaAvatar
                    membership={membership}
                    tooltipType="none"
                    handleClick={() => handleUserClick(membership)}
                  />
                  <Link to={`/${currentWorkspace.slug}/people/${membership.id}`} target="_blank">
                    {membership.cached_fullname ?? membership.cached_email}
                  </Link>
                </div>

                <span>{item.count}</span>
              </LeaderboardRow>
            );
          })}
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default CheckinStreakLeaderboardWidget;

import React from 'react';
import PlanSummaryWidget from './PlanSummaryWidget';
import { DashboardWidget } from 'types';
import ObjectiveSummaryWidget from './ObjectiveSummaryWidget';
import OutcomeSummaryWidget from './OutcomeSummary';
import InitiativeSummaryWidget from './InitiativeSummaryWidget';
import UserProfileWidget from './UserProfileWidget';
import styled from 'styled-components';
import theme from 'theme';
import ObjectiveListWidget from './ObjectiveListWidget';
import OutcomeListWidget from './OutcomeListWidget';
import InitiativeListWidget from './InitiativeListWidget';
import OutcomeTaskGraphWidget from './OutcomeTaskGraphWidget';
import PlanConfidenceGraphWidget from './PlanConfidenceGraphWidget';
import PlanNCSGraphWidget from './PlanNCSGraphWidget';
import OutcomeGraphWidget from './OutcomeGraphWidget';
import TextBlockWidget from './TextBlockWidget';
import OutcomeStatsWidget from './OutcomeStatsWidget';
import OutcomeDistributionWidget from './OutcomeDistribution';
import ActiveUserWidget from './ActiveUserWidget';
import OverdueCheckinUserCountWidget from './OverdueCheckinUserCountWidget';
import CheckinStreakLeaderboardWidget from './CheckinStreakLeaderboardWidget';
import OutcomeProgressLeaderboardWidget from './OutcomeProgressLeaderboardWidget';
import InitiativeProgressLeaderboardWidget from './InitiativeProgressLeaderboardWidget';
import ConfidenceLeaderboardWidget from './ConfidenceLeaderboardWidget';
import MostCheckinsLeaderboardWidget from './MostCheckinsLeaderboardWidget';
import HeaderBlockWidget from './HeaderBlockWidget';
import CompletedTasksLeaderboardWidget from './CompletedTasksLeaderboardWidget';
import ReactionsLeftLeaderboardWidget from './ReactionsLeftLeaderboardWidget';
import WorkspaceOutcomeTaskGraphWidget from './WorkspaceOutcomeTaskGraphWidget';
import WorkspaceConfidenceGraphWidget from './WorkspaceConfidenceGraphWidget';
import MostCommentedOutcomeWidget from './MostCommentedOutcomeWidget';
import MostViewedOutcomeWidget from './MostViewedOutcomeWidget';
import MostReactedOutcomeWidget from './MostReactedOutcomeWidget';
import OutcomeProgressWidget from './OutcomeProgressWidget';

export const WidgetContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const WidgetHeader = styled.span`
  display: flex;
  background-color: ${theme.colors.N5};
  border-bottom: 1px solid ${theme.colors.N10};
  gap: ${theme.spacing.x1};

  .widget-type {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.2rem 0px;
  }
`;

export const ListContainer = styled.div`
  border: 1px solid ${theme.colors.N10};
  border-radius: 4px;

  .item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .outcome-expanded:last-of-type,
  .outcome-row:last-of-type {
    border-bottom: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};
  height: 100%;
  overflow: scroll;
`;

export const SourceTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
  line-height: 18px;
  cursor: pointer;
  :active {
    text-decoration: underline;
  }
`;

export const DragHandle = styled.div`
  width: ${theme.spacing.x2};
  display: flex;
  justify-content: center;
  cursor: grab;
  padding: 1.2rem ${theme.spacing.x2};
`;

export const ContextHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: ${theme.spacing.x1};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${theme.spacing.x1};
`;

export const PathSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.x1};
  row-gap: ${theme.spacing.half};
  flex-wrap: wrap;

  .icon--custom-size {
    width: 1.6rem;
    height: 1.6rem;
  }
  span {
    height: 1.6rem;
    font-size: 11px;
    font-weight: 500;
    color: ${theme.colors.N60};
  }
  .widget-source {
    cursor: pointer;
  }
  a {
    font-size: 11px;
    font-weight: 500;
    color: ${theme.colors.N60};
  }
`;

export const LeaderboardRow = styled.div`
  display: flex;
  width: 100%;
  gap: ${theme.spacing.x2};
  align-items: center;
  .number {
    width: 2rem;
    font-weight: 600;
  }
  .profile {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x1};
    flex: 1;
    > a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .progress {
    width: 8rem;
  }
  .progress_streak {
    width: 10rem;
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    font-size: 1.1rem;
  }
`;

interface Props {
  widget: DashboardWidget;
}

function DashboardWidgetBlock(props: Props) {
  const { widget } = props;

  switch (widget.widget_type) {
    case 'plan-summary':
      return <PlanSummaryWidget widget={widget} />;
    case 'objective-summary':
      return <ObjectiveSummaryWidget widget={widget} />;
    case 'outcome-summary':
      return <OutcomeSummaryWidget widget={widget} />;
    case 'initiative-summary':
      return <InitiativeSummaryWidget widget={widget} />;
    case 'user-profile':
      return <UserProfileWidget widget={widget} />;
    case 'text-block':
      return <TextBlockWidget widget={widget} />;
    case 'header-block':
      return <HeaderBlockWidget widget={widget} />;
    case 'objective-list':
      return <ObjectiveListWidget widget={widget} />;
    case 'outcome-list':
      return <OutcomeListWidget widget={widget} />;
    case 'outcome-stats':
      return <OutcomeStatsWidget widget={widget} />;
    case 'outcome-progress':
      return <OutcomeProgressWidget widget={widget} />;
    case 'outcome-distribution':
      return <OutcomeDistributionWidget widget={widget} />;
    case 'initiative-list':
      return <InitiativeListWidget widget={widget} />;
    case 'outcome-task-graph':
      return <OutcomeTaskGraphWidget widget={widget} />;
    case 'plan-confidence-graph':
      return <PlanConfidenceGraphWidget widget={widget} />;
    case 'plan-ncs-graph':
      return <PlanNCSGraphWidget widget={widget} />;
    case 'outcome-graph':
      return <OutcomeGraphWidget widget={widget} />;
    case 'active-users':
      return <ActiveUserWidget widget={widget} />;
    case 'overdue-checkin-user-count':
      return <OverdueCheckinUserCountWidget widget={widget} />;
    case 'checkin_streak_leaderboard':
      return <CheckinStreakLeaderboardWidget widget={widget} />;
    case 'outcome_progress_leaderboard':
      return <OutcomeProgressLeaderboardWidget widget={widget} />;
    case 'initiative_progress_leaderboard':
      return <InitiativeProgressLeaderboardWidget widget={widget} />;
    case 'confidence_leaderboard':
      return <ConfidenceLeaderboardWidget widget={widget} />;
    case 'most_checkins_leaderboard':
      return <MostCheckinsLeaderboardWidget widget={widget} />;
    case 'completed_tasks_leaderboard':
      return <CompletedTasksLeaderboardWidget widget={widget} />;
    case 'reactions_left_leaderboard':
      return <ReactionsLeftLeaderboardWidget widget={widget} />;
    case 'workspace-outcome-task-graph':
      return <WorkspaceOutcomeTaskGraphWidget widget={widget} />;
    case 'workspace-confidence-graph':
      return <WorkspaceConfidenceGraphWidget widget={widget} />;
    case 'most_commented_outcome_leaderboard':
      return <MostCommentedOutcomeWidget widget={widget} />;
    case 'most_viewed_outcome_leaderboard':
      return <MostViewedOutcomeWidget widget={widget} />;
    case 'most_reacted_outcome_leaderboard':
      return <MostReactedOutcomeWidget widget={widget} />;
    default:
      return (
        <WidgetContainer>
          <WidgetHeader>
            <p className="widget-type">Invalid widget type</p>
          </WidgetHeader>
          <WidgetContent>
            <p>Invalid widget type</p>
          </WidgetContent>
        </WidgetContainer>
      );
  }
}

export default DashboardWidgetBlock;

import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { DashboardWidget, Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import { Base64 } from 'js-base64';
import KoalaLoader from 'koala/components/Loader';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';
import { StatsResult } from 'pages/WorkspaceOutcomes';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaCircularProgress, { ProgressData } from 'koala/components/CircularProgress';

const OutcomeContainer = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background: #fff;
  justify-content: start;

  .item:last-of-type {
    border: none;
  }
  .outcome-expanded {
    border-bottom: 1px solid ${theme.colors.N10};
  }
`;

const PieContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x2} 0px ${theme.spacing.x1};
`;

const ProgressContainer = styled.div`
  font-weight: 700;
  display: flex;
  font-size: 2.8rem;
  justify-content: center;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
`;
interface Props {
  widget: DashboardWidget;
}

function OutcomeProgressWidget(props: Props) {
  const { widget } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  const [outcomesStats, setOutcomesStats] = useState<StatsResult | null>(null);

  const segment: Segment = widget.source ? widget.source : null;
  let filter;
  if (segment?.id) {
    filter = segment ? segment.filter_hash : null;
  } else if (widget.widget_meta?.filter_hash) {
    filter = widget.widget_meta.filter_hash;
  }

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};

  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);
  // Query keys and query params

  const widgetTitle: string = widget.title
    ? widget.title
    : t('workspaceDashboards.outcomeProgress', { outcome: translate(workspace, CustomTermKey.OUTCOME, 1) });

  // Get the outcome stats
  const queryKeyStats = [
    queryKeys.outcomes,
    'stats',
    {
      workspaceSlug: workspace.slug,
      filter: filterParams,
    },
  ];
  const { isLoading: isLoadingStats } = useQuery(queryKeyStats, remoteApi.fetchOutcomesStats, {
    onSuccess: (response: any) => {
      setOutcomesStats(response.data);
    },
  });

  const segmentRoute =
    segment &&
    WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
      .replace(':segmentId', segment.id)
      .replace(':filter', segment.filter_hash);

  if (!outcomesStats) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{widgetTitle}</p>
        </WidgetHeader>
        <OutcomeContainer>
          {segment && (
            <SourceTitle>
              <Link to={segmentRoute} className="widget-source">
                {segment.title}
              </Link>
            </SourceTitle>
          )}
        </OutcomeContainer>
      </WidgetContainer>
    );
  }

  const avg_outcomes_progress = outcomesStats.avg_outcomes_progress * 100;
  const progressData: ProgressData = {
    progressPercentage: avg_outcomes_progress,
    colorType: 'B',
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <OutcomeContainer>
        {segment && (
          <SourceTitle>
            <Link to={segmentRoute} className="widget-source">
              {segment.title}
            </Link>
          </SourceTitle>
        )}
        <ChartContainer>
          {isLoadingStats && <KoalaLoader />}
          {!isLoadingStats && (
            <PieContainer>
              <KoalaCircularProgress data={progressData} size="large" />
              <ProgressContainer>{Math.round(avg_outcomes_progress)}%</ProgressContainer>
            </PieContainer>
          )}
        </ChartContainer>
      </OutcomeContainer>
    </WidgetContainer>
  );
}

export default OutcomeProgressWidget;

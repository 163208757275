/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as amplitudeUtils from 'utils/amplitude';
import * as membershipUtils from 'utils/membershipUtils';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import * as userUtils from 'utils/userUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TabbySpace from './tabby_space.png';
import ExploreSamplePlan from './explore_sample_plans.png';
import { UXPREFERENCES } from 'config/constants';

// API
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as routes from 'routes';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import ReactTooltip from 'react-tooltip';
import { ChromeContent } from 'components/Chrome';
import ContentNarrowWrapper from 'components/ContentNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import RecentPlans from 'components/RecentPlans';
import KoalaTabs from 'koala/components/Tabs';

import TeamFilter from './TeamFilter';
import InsightFilter from './InsightFilter';
import PlansList from './PlansList';
import KoalaButton from 'koala/components/Button';
import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaIcon from 'koala/components/Icons';
import _ from 'lodash';
import PlansListSearch from './PlansListSearch';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { archivePlan, updatePlan, createPlan } from 'api/remote';
import queryKeys from 'config/queryKeys';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaTextBadge from 'koala/components/TextBadge';
import { SELECTED_PLANS_KEY } from 'config/constants';
import ReadOnlyButton from 'components/ReadOnlyButton';
import { setIsEmptyWorkspace } from 'state/actions/sessionActions';
import { startOfQuarter, endOfQuarter } from 'date-fns';
import KoalaToggleButton from 'koala/components/ToggleButton';

export interface MovedPlan {
  id: string;
  parentId: string;
}

const ColumnContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-gap: ${theme.spacing.x1};
  padding: 0 ${theme.spacing.x2};
  padding-left: 1.8rem;

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr 27rem;
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 13rem;
    .hide-mobile {
      display: none;
    }
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr 4rem;
  }
`;

const ColumnHeader = styled.div`
  color: ${theme.colors.N80};
  display: grid;
  grid-template-columns: 9rem 9rem 9rem 10rem 10rem;
  justify-content: end;
  gap: ${theme.spacing.x2};
  align-items: center;
  @media ${theme.devices.laptop} {
    grid-template-columns: 11rem;
    gap: ${theme.spacing.x1};
    .outcomes,
    .initiatives,
    .confidence,
    .timeline {
      display: none;
    }
  }
  @media ${theme.devices.laptop} {
    grid-template-columns: 11rem;
  }
`;

const HeaderLabel = styled.span`
  display: flex;
  gap: ${theme.spacing.half};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: ${theme.spacing.x1} 0;
`;

const SelectionMenu = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  gap: ${theme.spacing.x1};
  justify-content: end;
  align-items: center;

  &.show-selection-menu {
    height: 32px;
    transition: height 0.4s ease;
  }
  &.hide-selection-menu {
    height: 0;
    overflow-y: hidden;
    transition: height 0.4s ease;
    margin-bottom: 0px;
  }
`;

const FilterContainer = styled.div`
  flex: 1;
  display: flex;
  gap: ${theme.spacing.x1};
  flex-wrap: wrap;
  input {
    border: 1px solid ${theme.colors.inputBorder};
    width: 30%;
    min-width: 20rem;
  }
  @media ${theme.devices.tablet} {
    input {
      width: 50%;
    }
  }
`;

const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${theme.colors.N70};
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.x2};
`;

const PlanListContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;

  .item:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  .item:last-of-type {
    border-radius: 0 0 3px 3px;
  }

  .linebreak:last-child {
    hr {
      border-top: none;
    }
  }
`;

const ActionsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.x1};
  width: 100%;
  position: relative;
  bottom: ${theme.spacing.x1};
`;

const EmptyView = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  padding: ${theme.spacing.x3};
  align-items: center;
  justify-content: center;
  img {
    max-width: 32rem;
  }
  @media ${theme.devices.laptop} {
    flex-direction: column;
  }
`;
const EmptyViewText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  max-width: 40rem;

  p {
    br {
      margin-bottom: ${theme.spacing.x1};
    }
  }

  button {
    width: fit-content;
  }

  button.create-first-plan-button {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    height: initial;
  }

  > div {
    display: flex;
    gap: ${theme.spacing.x2};
    @media ${theme.devices.laptop} {
      flex-direction: column;
    }
  }
`;

const SamplePlanTeaser = styled.div`
  display: flex;
  padding: ${theme.spacing.x2};
  justify-content: space-between;
  img {
    width: 25rem;
    position: relative;
    left: 10rem;

    @media ${theme.devices.laptop} {
      left: 0rem;
    }
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function Plans(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const isTabilityUser = userUtils.isTabilityUser(currentUser);

  // Get UX Preferences from local storage
  const storedUXPreferences = localStorage.getItem(UXPREFERENCES);
  const UXPreferences = storedUXPreferences ? JSON.parse(storedUXPreferences) : {};
  const showWatchedPlans = UXPreferences.showWatchedPlans !== undefined ? UXPreferences.showWatchedPlans : false;
  const storedHideDonePlans = UXPreferences.hideDonePlans !== undefined ? UXPreferences.hideDonePlans : false;

  const [nameToSearch, setNameToSearch] = useState('');
  const [teamsFilter, setTeamsFilter] = useState<any[]>([]);
  const [insightFilter, setInsightFilter] = useState<string>('');
  const [selectedPlans, setSelectedPlans] = useState<TabilityTypes.Plan[]>([]);
  const [hasDummyData, setHasDummyData] = useState(false);
  const [dummyPlanId, setDummyPlanId] = useState<string | null>(null);
  const [planlistRefreshedAt, setPlanlistRefreshedAt] = useState<number>(Date.now());
  const [showWatched, setShowWatched] = useState(showWatchedPlans);
  const [hideDonePlans, setHideDonePlans] = useState(storedHideDonePlans);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const insightFilterURL = params.get('insight');
  const teamFilterURL = params.get('team');
  const isOnboardingRoute = params.get('onboarding');

  // Mutation that will create the workspace in the backend
  const [createPlanMutation, { isLoading: isCreatingPlan }]: [any, any] = useMutation(createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspace.slug).replace(
        ':planId',
        createdPlan.nano_slug,
      );
      history.push(`${planRoute}?onboarding=1`);

      // Unflag the workspace as empty if it was
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
      // let action = `plan:${createdPlan.nano_slug}:chat.ai.outcomes`;

      // dispatch(setGlobalModalContent(action));
    },
  });

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = currentWorkspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const handleCreateAIPlan = (e: any) => {
    e.preventDefault();
    const refDate = Date.now();
    const start_at = startOfQuarter(refDate);
    const finish_at = endOfQuarter(refDate);
    const title = `${currentWorkspace.name} OKRs`;
    const reminders_start_at = getRemindersStartAt(start_at);
    const mutationParams = {
      workspaceSlug: currentWorkspace.slug,
      plan: {
        state: 'published',
        title,
        reminders_start_at,
        reminders_period: 'week',
        reminders_interval: 1,
        reminders_hour: currentWorkspace.weekly_reminder_hour,
        start_at,
        finish_at,
      },
    };
    createPlanMutation(mutationParams);
  };

  useEffect(() => {
    if (insightFilterURL) {
      setInsightFilter(insightFilterURL);
    }
    if (teamFilterURL) {
      const teamValues = JSON.parse(decodeURIComponent(teamFilterURL));
      setTeamsFilter(teamValues);
    }
    // history.replace(`${location.pathname}`); // Must be a replace otherwise you can never go back
  }, [insightFilterURL, teamFilterURL, location.pathname, history]);

  useEffect(() => {
    if (document) {
      const dummyPlans: any = document.querySelectorAll('div[data-dummy="true"]');
      if (dummyPlans.length > 0) {
        setHasDummyData(true);
        setDummyPlanId(dummyPlans[0].dataset.id);
      } else {
        setHasDummyData(false);
        setDummyPlanId(null);
      }
    }
  }, [planlistRefreshedAt]);

  useEffect(() => {
    if (isOnboardingRoute) {
      setTimeout(() => {
        // Delay tour by 500ms to make sure the content has loaded
        window.Intercom('startTour', 535968);
      }, 500);
    }
  }, [isOnboardingRoute]);

  const teamIds = teamsFilter.map((option) => option.value);
  const useSearch = nameToSearch || (teamIds && teamIds.length > 0) || insightFilter;

  useEffect(() => {
    if (selectedPlans) {
      setSelectedPlans([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSearch]);

  const handleCreateNewPlan = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`plan::create.plan`));
  };

  const handleCreateNewPlanAI = (e: any) => {
    e.preventDefault();
    const path = `/${currentWorkspace.slug}/onboarding/done`;
    history.push(path);
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.plans.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };
  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );
  const handleSearch = (e: any) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  const handleChangeTeamIds = (options: any) => {
    setTeamsFilter(options);
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    let nanoBlockId;
    let url;
    switch (action) {
      case 'show-archives':
        nanoBlockId = `workspace:${workspace.slug}`;
        url = `#${nanoBlockId}:archives`;
        history.push(url);
        break;
      case 'import-plans':
        dispatch(setGlobalModalContent(`plans::import.csv`));
        break;
      case 'create-template':
        dispatch(setGlobalModalContent(`plan::create.template`));
        break;
    }
  };

  const handleImportPlans = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`plans::import.csv`));
  };

  const menuItems = () => {
    const items = [];
    items.push(<span data-action="show-archives">{t('workspacePlans.showArchivedPlans')}</span>);
    items.push(<span data-action="import-plans">{t('workspacePlans.importPlans')}</span>);
    if (isTabilityUser) {
      items.push(<span data-action="create-template">{t('workspacePlans.createTemplate')}</span>);
    }
    return items;
  };

  const [updatePlanMutation] = useMutation(updatePlan, {
    onSuccess: (_, variables) => {
      // Rather than invalidating all the plans the logic below will only invalidate the plans involved in the move
      // It reloads:
      //    - The children of the moved plan
      //    - The children of the previous parent plan
      //    - The children of the new parent plan

      const movedPlanId = variables.planId || '';
      const movedParentId = variables.movedParentId || '';
      const newParentId = variables.plan?.parent_id || '';

      // Invalidate the plan
      const filterMovedPlan = {
        children_of: movedPlanId,
        team_id: teamIds,
      };
      const paramsMovedPlan = {
        filter: filterMovedPlan,
      };

      const queryKeyPlanId = [queryKeys.plans, workspace.slug, paramsMovedPlan];

      // Invalidate the parent
      const filterMovedParent = {
        children_of: movedParentId,
        team_id: teamIds,
      };
      const paramsMovedParent = {
        filter: filterMovedParent,
      };

      const queryKeyMovedParentId = [queryKeys.plans, workspace.slug, paramsMovedParent];

      // Invalidate the parent
      const filterNewParent = {
        children_of: newParentId,
        team_id: teamIds,
      };
      const paramsNewParent = {
        filter: filterNewParent,
      };

      const queryKeyNewParentId = [queryKeys.plans, workspace.slug, paramsNewParent];

      queryCache.invalidateQueries(queryKeyPlanId);
      queryCache.invalidateQueries(queryKeyMovedParentId);
      queryCache.invalidateQueries(queryKeyNewParentId);
    },
  });

  const [archivePlanMutation, { isLoading: isArchiving }] = useMutation(archivePlan, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.plans);
    },
  });

  // movePlan: (draggedId: string, hoverId: string) => void;

  const movePlan = (movedPlan: MovedPlan, hoverId: string | null) => {
    let planId = movedPlan.id;
    let parent_id = hoverId;

    updatePlanMutation({
      planId,
      plan: {
        parent_id,
      },
      movedParentId: movedPlan.parentId,
    });
  };

  const handleArchivePlan = (planId: string) => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      planId,
      plan: params,
    };
    archivePlanMutation(mutationParams);
  };

  const clearSelectedPlans = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedPlans([]);
  };

  const deletePlans = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // if only one selected, do regular delete
    if (selectedPlans.length === 1) {
      dispatch(setGlobalModalContent(`plan:${selectedPlans[0].id}:delete`));
    } else {
      const selectedList = selectedPlans.map((plan) => ({ id: plan.id, title: plan.title }));
      localStorage.setItem(SELECTED_PLANS_KEY, JSON.stringify(selectedList));
      dispatch(setGlobalModalContent('plans::delete'));
    }
  };

  const archivePlans = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedPlans.length === 1) {
      if (window.confirm('Are you sure that you want to archive this plan?')) {
        handleArchivePlan(selectedPlans[0].id);
      }
    } else {
      const selectedList = selectedPlans.map((plan) => ({ id: plan.id, title: plan.title }));
      localStorage.setItem(SELECTED_PLANS_KEY, JSON.stringify(selectedList));
      dispatch(setGlobalModalContent('plans::archive'));
    }
  };

  const handleDeleteSampleData = () => {
    if (!dummyPlanId) {
      return;
    }
    const blockId = `plan:${dummyPlanId}`;
    dispatch(setGlobalModalContent(`${blockId}:delete`));
  };

  // Set boolean to determine which type of listing to use
  const showSelectionMenuClass = selectedPlans.length > 0 ? 'show-selection-menu' : 'hide-selection-menu';

  let handleAIbutton = handleCreateNewPlanAI;
  if (true) {
    handleAIbutton = handleCreateAIPlan;
  }

  const handleSetShowWatched = (value: boolean) => {
    localStorage.setItem(UXPREFERENCES, JSON.stringify({ ...UXPreferences, showWatchedPlans: value }));
    setShowWatched(value);
  };

  const handleSetHideDonePlans = (value: boolean) => {
    localStorage.setItem(UXPREFERENCES, JSON.stringify({ ...UXPreferences, hideDonePlans: value }));
    setHideDonePlans(value);
  };

  const activeTab = showWatched ? 'watched' : 'all';

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspacePlans.title')} | Tability
        </title>
      </Helmet>
      <MobileReadyChromeHeader>
        <WorkspaceHeader workspace={workspace} useGreyBackground={true} title={<h1>{t('workspacePlans.title')}</h1>} />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <ContentNarrowWrapper>
          {!isEmptyWorkspace && (
            <>
              <RecentPlans />
              <ContentTitle className="with-tabs">
                <KoalaTabs
                  activeTab={activeTab}
                  setActiveTab={(tab: string) => handleSetShowWatched(tab === 'watched')}
                  tabs={[
                    {
                      key: 'all',
                      label: t('workspacePlans.tabs.everything'),
                    },
                    {
                      key: 'watched',
                      label: t('workspacePlans.tabs.watching'),
                    },
                  ]}
                />
                <ActionsNav>
                  {isReadOnly && (
                    <div>
                      <ReadOnlyButton label={t('workspacePlans.firstPlanEditor')} appearance="primary" />
                    </div>
                  )}
                  {!isReadOnly && (
                    <>
                      <KoalaButton
                        onClick={handleCreateNewPlan}
                        appearance={isEmptyWorkspace ? 'secondary' : 'primary'}
                        className="create-button"
                      >
                        {t('workspacePlans.newPlanButton')}
                      </KoalaButton>
                      <DropdownMenu
                        trigger={<KoalaIconButton iconName="ellipsis" />}
                        onSelection={handleMenuSelection}
                        items={menuItems()}
                      />
                    </>
                  )}
                </ActionsNav>
              </ContentTitle>
              <ToggleButtonContainer>
                <FilterContainer>
                  <input
                    placeholder={t('workspacePlans.nameFilterPlaceholder') ?? 'Filter plans by name'}
                    onChange={handleSearch}
                    maxLength={100}
                  />
                  <TeamFilter handleChange={handleChangeTeamIds} value={teamsFilter} />
                  <InsightFilter handleChange={setInsightFilter} value={insightFilter} />
                </FilterContainer>
                <KoalaToggleButton
                  handleChange={() => handleSetHideDonePlans(!hideDonePlans)}
                  defaultChecked={hideDonePlans}
                  id="hide-completed-initiatives-overview"
                  dataFor="koala-toggle-hide-completed-initiatives-overview"
                />
                <label htmlFor="koala-toggle-hide-completed-initiatives-overview">
                  {t('workspacePlans.hideDonePlans')}
                </label>
                <ReactTooltip
                  id="koala-toggle-hide-completed-initiatives-overview"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  {t('workspacePlans.hideDonePlansHelp')}
                </ReactTooltip>
              </ToggleButtonContainer>
            </>
          )}
          {isEmptyWorkspace && (
            <EmptyView>
              <EmptyViewText>
                <h1>{t('workspacePlans.welcome')}</h1>
                <p>
                  <Trans i18nKey="workspacePlans.welcomeInfo" components={{ br: <br /> }} />
                </p>
                <div>
                  {isReadOnly && (
                    <div>
                      <ReadOnlyButton label={t('workspacePlans.newPlanButton')} appearance="primary" />
                    </div>
                  )}
                  {!isReadOnly && (
                    <>
                      <KoalaButton
                        onClick={handleCreateNewPlan}
                        className="empty-create-button create-button create-first-plan-button"
                        loading={isCreatingPlan}
                      >
                        {t('workspacePlans.newPlanButton')}
                      </KoalaButton>
                    </>
                  )}
                  {false && (
                    <KoalaButton
                      onClick={handleAIbutton}
                      className="empty-import-button import-button"
                      appearance="secondary"
                    >
                      {t('workspacePlans.importPlans')}
                    </KoalaButton>
                  )}
                  {false && (
                    <KoalaButton
                      onClick={handleImportPlans}
                      className="empty-import-button import-button"
                      appearance="secondary"
                    >
                      {t('workspacePlans.importPlans')}
                    </KoalaButton>
                  )}
                </div>
              </EmptyViewText>
              <img src={TabbySpace} alt="Let's create a plan" />
            </EmptyView>
          )}
          {!isEmptyWorkspace && (
            <>
              <SelectionMenu className={showSelectionMenuClass}>
                <KoalaTextBadge variant="blue-light">
                  {t(`workspacePlans.selectedPlans`, { count: selectedPlans.length })}
                </KoalaTextBadge>
                <KoalaButton appearance="subtle" size="small" onClick={archivePlans} loading={isArchiving}>
                  {t(`shared.archive`)}
                </KoalaButton>
                <KoalaButton appearance="subtle" size="small" onClick={deletePlans}>
                  {t(`shared.delete`)}
                </KoalaButton>
                <KoalaTextButton onClick={clearSelectedPlans}>{t(`shared.cancel`)}</KoalaTextButton>
              </SelectionMenu>
              <ColumnContainer>
                <div className="plan">
                  <HeaderLabel>{t('workspacePlans.table.plan')}</HeaderLabel>
                </div>
                <ColumnHeader>
                  <div className="outcomes">
                    <HeaderLabel>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</HeaderLabel>
                  </div>
                  <div className="initiatives">
                    <HeaderLabel>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</HeaderLabel>
                  </div>
                  <div className="confidence">
                    <HeaderLabel>
                      {t('workspacePlans.table.confidence')}
                      <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
                    </HeaderLabel>
                  </div>
                  <div className="timeline">
                    <HeaderLabel>{t('workspacePlans.table.timeline')}</HeaderLabel>
                  </div>
                  <div className="status">
                    <HeaderLabel>{t('workspacePlans.table.status')}</HeaderLabel>
                  </div>
                </ColumnHeader>
              </ColumnContainer>
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id="explain-ncs"
                delayHide={500}
                clickable={true}
              >
                <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                <a
                  href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('shared.learnMore')}
                </a>
              </ReactTooltip>
              <PlanListContainer className="plans-list">
                {(useSearch || showWatched) && (
                  <PlansListSearch
                    workspace={workspace}
                    searchTerm={nameToSearch}
                    teamIds={teamIds}
                    insightFilter={insightFilter}
                    selectedPlans={selectedPlans}
                    setSelectedPlans={setSelectedPlans}
                    hideDonePlans={hideDonePlans}
                    showWatched={showWatched}
                  />
                )}
                {!useSearch && !showWatched && (
                  <DndProvider backend={HTML5Backend}>
                    <PlansList
                      workspace={workspace}
                      parentId={''}
                      level={0}
                      teamIds={teamIds}
                      movePlan={movePlan}
                      selectedPlans={selectedPlans}
                      setSelectedPlans={setSelectedPlans}
                      setPlanlistRefreshedAt={setPlanlistRefreshedAt}
                      hideDonePlans={hideDonePlans}
                      showWatched={showWatched}
                    />
                  </DndProvider>
                )}
              </PlanListContainer>
            </>
          )}
          {hasDummyData && !useSearch && (
            <SamplePlanTeaser>
              <img src={ExploreSamplePlan} alt="Explore the sample plans" />
              {dummyPlanId && (
                <KoalaButton onClick={handleDeleteSampleData} appearance="special">
                  {t('workspacePlan.sampleData')}
                </KoalaButton>
              )}
            </SamplePlanTeaser>
          )}
        </ContentNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default Plans;

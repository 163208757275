import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { DragHandle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import KoalaLoader from 'koala/components/Loader';
import Chart from 'components/CheckinChart';
import { format, getTime, parseISO, startOfWeek, subDays } from 'date-fns';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryStack, VictoryVoronoiContainer } from 'victory';
import KoalaIcon from 'koala/components/Icons';
import { DashboardWidget, Domain, PlanStats } from 'types';

const Container = styled.div`
  height: 100%;
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  width: 100%;
  max-height: 100%;
`;

interface ChartProps {
  greyOutcomesData: any;
  redOutcomesData: any;
  greenOutcomesData: any;
  yellowOutcomesData: any;
  xDomain: any;
  yDomain: Domain;
  dimensions: any;
}
function RenderChart(props: ChartProps) {
  const { greyOutcomesData, redOutcomesData, yellowOutcomesData, greenOutcomesData, dimensions } = props;
  const { i18n } = useTranslation();

  return (
    <VictoryChart
      width={dimensions.width}
      height={dimensions.height - 10}
      domainPadding={{ x: 0, y: 20 }}
      padding={{ top: 10, bottom: 30, left: 35, right: 20 }}
      minDomain={{ y: 0 }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => (datum.y === 0 ? null : datum.displayText)}
          labelComponent={Chart.LabelTooltipStack()}
        />
      }
    >
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryStack>
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.N20,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={greyOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.R40,
                fillOpacity: 0.7,
                strokeLinecap: 'round',
                stroke: 'none',
              },
            }}
            data={redOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.Y40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={yellowOutcomesData}
            interpolation="linear"
          />
          <VictoryArea
            style={{
              data: {
                fill: theme.colors.G40,
                fillOpacity: 0.7,
                stroke: 'none',
              },
            }}
            data={greenOutcomesData}
            interpolation="linear"
          />
        </VictoryStack>
      </VictoryGroup>
    </VictoryChart>
  );
}

interface Props {
  widget: DashboardWidget;
}

function WorkspaceConfidenceGraphWidget(props: Props) {
  const { widget } = props;
  const { t } = useTranslation();
  let yDomain: Domain = { min: 0, max: 0 };
  const widgetTitle: string = widget.title ? widget.title : t('workspaceDashboards.confidenceGraph');

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  const queryKey = [queryKeys.dashboardWidgets, widget.id, 'outcomeTasks'];
  const { data: dataResponse, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeTasksWidgetDetails);

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef} />
      </WidgetContainer>
    );
  }

  const trends: PlanStats[] = dataResponse?.data;
  const trendsMap: {
    [key: string]: {
      greyOutcomeSum: number;
      redOutcomeSum: number;
      greenOutcomeSum: number;
      yellowOutcomeSum: number;
      totalCountSum: number;
    };
  } = {};

  trends.forEach((trend: PlanStats) => {
    const date = parseISO(trend.reference_date);
    const weekStart = startOfWeek(date);
    const dateString = format(weekStart, 'yyyy-MM-dd'); // Format start of week date to string for grouping
    if (!trendsMap[dateString]) {
      trendsMap[dateString] = {
        greyOutcomeSum: 0,
        redOutcomeSum: 0,
        greenOutcomeSum: 0,
        yellowOutcomeSum: 0,
        totalCountSum: 0,
      };
    }
    trendsMap[dateString].greenOutcomeSum += trend.green_outcomes_count;
    trendsMap[dateString].yellowOutcomeSum += trend.yellow_outcomes_count;
    trendsMap[dateString].redOutcomeSum += trend.red_outcomes_count;
    trendsMap[dateString].greyOutcomeSum += trend.grey_outcomes_count;
    trendsMap[dateString].totalCountSum +=
      trend.green_outcomes_count + trend.yellow_outcomes_count + trend.red_outcomes_count + trend.grey_outcomes_count;
  });

  const greyOutcomesData: any[] = [];
  const redOutcomesData: any[] = [];
  const greenOutcomesData: any[] = [];
  const yellowOutcomesData: any[] = [];
  Object.keys(trendsMap).forEach((dateString) => {
    const { greenOutcomeSum, greyOutcomeSum, redOutcomeSum, yellowOutcomeSum, totalCountSum } = trendsMap[dateString];
    const date = new Date(dateString);
    // Create the point using the checkin data
    const greyPrct = Math.round((greyOutcomeSum / totalCountSum) * 100);
    greyOutcomesData.push({
      x: date,
      y: greyPrct,
      timestamp: getTime(date),
      displayText: `${greyPrct}% pending`,
    });

    const redPrct = Math.round((redOutcomeSum / totalCountSum) * 100);
    redOutcomesData.push({
      x: date,
      y: redPrct,
      timestamp: getTime(date),
      displayText: `${redPrct}% off track`,
    });

    const yellowPrct = Math.round((yellowOutcomeSum / totalCountSum) * 100);
    yellowOutcomesData.push({
      x: date,
      y: yellowPrct,
      displayText: `${yellowPrct}% at risk`,
      timestamp: getTime(date),
    });

    const greenPrct = Math.round((greenOutcomeSum / totalCountSum) * 100);
    greenOutcomesData.push({
      x: date,
      y: greenPrct,
      displayText: `${greenPrct}% on track`,
      timestamp: getTime(date),
    });
  });

  const finishDate: Date = new Date();

  const startDate: Date = subDays(finishDate, 90);

  const xDomain = [startDate, finishDate];

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{widgetTitle}</p>
      </WidgetHeader>
      <WidgetContent>
        <Container ref={chartRef}>
          <RenderChart
            dimensions={dimensions}
            greyOutcomesData={greyOutcomesData}
            redOutcomesData={redOutcomesData}
            yellowOutcomesData={yellowOutcomesData}
            greenOutcomesData={greenOutcomesData}
            xDomain={xDomain}
            yDomain={yDomain}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default WorkspaceConfidenceGraphWidget;

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as amplitudeUtils from 'utils/amplitude';

// Components
import { PanelGrid, PanelHeader, PanelContent } from 'components/GlobalPanel';
import KoalaIconButton from 'koala/components/IconButton';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TipsContent = styled.div`
  font-size: 1.4rem;
  padding: 0 ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x4};
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 2rem;
    margin: ${theme.spacing.x4} 0 ${theme.spacing.x1} 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
  p {
    margin-bottom: ${theme.spacing.x2};
  }
  p + p {
    margin-top: 0;
  }
  ol {
    margin-left: ${theme.spacing.x2};
    margin-bottom: ${theme.spacing.x2};
    li {
      margin-bottom: ${theme.spacing.half};
    }
  }
  a {
    color: ${theme.colors.B50};
    text-decoration: underline;
  }
  ul {
    list-style-type: disc;
    margin-left: 3rem;

    li {
      padding-bottom: ${theme.spacing.half};
      color: ${theme.colors.N90};
    }
  }
  p {
    color: ${theme.colors.N90};
  }
`;

interface Props {}

function MovePlan(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const closePanel = () => {
    history.push(location.pathname);
  };

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.workspace.tips.okrs.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
    });
  }, []);

  useEffect(() => {
    // Handle ESC key to close the panel
    const handlePress = (e: any) => {
      if (e.keyCode === 27) {
        // Esc key
        e.preventDefault();
        history.push(location.pathname);
      }
    };
    document.addEventListener('keydown', handlePress, false);
    return () => document.removeEventListener('keydown', handlePress, false);
  }, [history, location.pathname]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    closePanel();
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>Review Tability to get $100 credits 🎁</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        <TipsContent>
          <h3>Add months to your trial by reviewing Tability</h3>
          <p>Are you enjoying Tability? You can add up to 10 months to your trial with a 10/10 review on G2:</p>
          <ol>
            <li>1. Click on the review button</li>
            <li>2. Leave a 10/10 review</li>
            <li>3. Send us a screenshot</li>
          </ol>
          <p>That's it! We will add $100 credits to your workspace once your review is verified.</p>
          <div>
            <a
              href="https://www.g2.com/products/tability/take_survey"
              target="_blank"
              rel="noreferrer noopener"
              className="button primary"
            >
              Leave a review
            </a>
          </div>
        </TipsContent>
      </PanelContent>
    </PanelGrid>
  );
}

export default MovePlan;

import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import CheckinBlock from 'components/CheckinBlock';
import UpgradePanel from 'components/UpgradePanel';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';
import { Checkin, Outcome } from 'types';
import { useTranslation } from 'react-i18next';
import KoalaIconButton from 'koala/components/IconButton';

const PreviousCheckinsContainer = styled.div`
  margin-top: ${theme.spacing.x2};
  &.expanded {
    .icon-btn--square {
      transform: rotate(90deg);
    }
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  padding: ${theme.spacing.x1} 0px;

  h4 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;

const ExpandTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
  border-top: 1px solid ${theme.colors.blockBorder};
  margin-top: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x1};

  
  &.clickable:hover {
    cursor: pointer;
    background-color: ${theme.colors.N3};

  .icon-btn--square {
    transition: transform 0.2s ease;
    &:focus {
      outline: none;
      border: 0;
    }
  }
`;

interface Props {
  checkins: Checkin[];
  outcome: Outcome;
}

const CheckinList = styled.div`
  .checkin {
    padding-bottom: ${theme.spacing.x1};
    margin-bottom: ${theme.spacing.x1};

    border-bottom: 1px solid ${theme.colors.N10};
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

function CheckinsTab(props: Props) {
  const { checkins, outcome } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [showExpanded, setShowExpanded] = useState(false);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  if (!hasStarterSubscription) {
    return (
      <UpgradePanel
        title={t('panels.outcome.upgradeCheckinHistory') ?? 'Upgrade to the Starter plan to see the check-ins history'}
        description={
          t('panels.outcome.upgradeCheckinHistoryDescr') ??
          'Check-ins history is not available on the Free plan—but you can start a trial to have access to this feature.'
        }
      />
    );
  }

  const currentCheckinId = outcome.current_checkin ? outcome.current_checkin.id : null;
  const previousCheckins = checkins.filter((checkin) => checkin.id !== currentCheckinId);
  const translationKey = 'panels.outcome';
  const previousCount = previousCheckins.length;

  if (previousCount === 0) {
    return <></>;
  }

  const checkinsAboveExpand = previousCheckins.slice(0, 2);
  const checkinsBelowExpand = previousCheckins.slice(2);
  const isExpandedClass = showExpanded ? 'expanded' : '';

  return (
    <PreviousCheckinsContainer className={isExpandedClass}>
      <SectionTitle>
        <h4>{t(`${translationKey}.previousCheckins`, { count: previousCount })}</h4>
      </SectionTitle>
      <CheckinList>
        {checkinsAboveExpand.map((checkin, i) => {
          return <CheckinBlock outcome={outcome} checkin={checkin} key={checkin.id} compact />;
        })}
      </CheckinList>
      {checkinsBelowExpand.length > 0 && (
        <>
          <ExpandTitle className="clickable" onClick={() => setShowExpanded(!showExpanded)}>
            <KoalaIconButton iconName={'rightChevron'} size="xsmall" edge="square" className={isExpandedClass} />
            <label>{t(`${translationKey}.morePreviousCheckins`, { count: checkinsBelowExpand.length })}</label>
          </ExpandTitle>
          {showExpanded && (
            <CheckinList>
              {checkinsBelowExpand.map((checkin, i) => {
                return <CheckinBlock outcome={outcome} checkin={checkin} key={checkin.id} compact />;
              })}
            </CheckinList>
          )}
        </>
      )}
    </PreviousCheckinsContainer>
  );
}

export default React.memo(CheckinsTab);

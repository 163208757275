import InitiativeWorkStatus from 'components/InitiativeWorkStatus';
import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative } from 'types';
import InitiativeCard from './InitiativeCard';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import CreateInitiativeCard from './CreateInitiativeCard';
import KoalaTextBadge from 'koala/components/TextBadge';

const StateContainer = styled.div<{ isDraggingOver: boolean }>`
  border: 1px solid ${theme.colors.N10};
  border-radius: 4px;
  padding: ${theme.spacing.x1};
  min-width: 24rem;
  max-width: 24rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  background: ${(props) => (props.isDraggingOver ? theme.colors.G5 : theme.colors.N0)};
`;

const InitiativesContainer = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  min-height: 30vh;
  max-height: 70vh;

  .add-task {
    opacity: 0;
  }
  :hover {
    .add-task {
      opacity: 1;
    }
  }
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InitiativeCardContainer = styled.div<{ isDragging: boolean }>`
  box-shadow: ${(props) => (props.isDragging ? '0px 0px 4px rgba(21, 21, 21, 0.15)' : 'unset')};
`;

const CreateSection = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  color: ${theme.colors.N80};
  height: 3rem;
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  margin-top: ${theme.spacing.half};
  border: none;
  width: 100%;

  :hover {
    cursor: pointer;
    background: ${theme.colors.N3};
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
    filter: none;
  }
`;

interface Props {
  objectiveId: string;
  workState: string;
  initiatives: Initiative[];
}

function StateColumn(props: Props) {
  const { objectiveId, workState, initiatives } = props;
  const [showNewTaskCard, setShowNewTaskCard] = useState('');
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const id = `${objectiveId}:${workState}`;

  const lastCard = initiatives.at(-1);
  const lastColumnRank = lastCard ? lastCard.column_rank : '';

  const handleCancel = () => {
    setShowNewTaskCard('');
  };

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <StateContainer isDraggingOver={snapshot.isDraggingOver}>
          <ColumnHeader>
            <InitiativeWorkStatus providedState={workState} showArrow={false} />
            <KoalaTextBadge edge="circle" variant="neutral-light">
              {initiatives.length}
            </KoalaTextBadge>
          </ColumnHeader>
          <InitiativesContainer ref={provided.innerRef} {...provided.droppableProps}>
            {initiatives.map((initiative, index) => (
              <Draggable
                key={initiative.id}
                draggableId={initiative.id}
                index={index}
                isDragDisabled={!!initiative.integration_type}
              >
                {(provided, snapshot) => (
                  <InitiativeCardContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                  >
                    <InitiativeCard initiative={initiative} />
                  </InitiativeCardContainer>
                )}
              </Draggable>
            ))}
            {showNewTaskCard !== id && (
              <CreateSection className="add-task" tabIndex={0} onClick={() => setShowNewTaskCard(id)}>
                <KoalaIcon iconName="plus" iconSize="small" />
                {t('workspacePlan.initiatives.create', {
                  initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
                })}
              </CreateSection>
            )}
            {showNewTaskCard === id && (
              <CreateInitiativeCard
                lastColumnRank={lastColumnRank}
                objectiveId={objectiveId}
                workState={workState}
                handleCancel={handleCancel}
              />
            )}
          </InitiativesContainer>
        </StateContainer>
      )}
    </Droppable>
  );
}

export default React.memo(StateColumn);

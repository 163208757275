import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { midString } from 'state/reducers/utils';
import styled from 'styled-components';

import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import KoalaCheckbox from 'koala/components/Checkbox';
import KoalaLoader from 'koala/components/Loader';
import theme from 'theme';
import { Initiative } from 'types';
import * as remoteApi from '../../api/remote';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

const TaskDetails = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  height: 2.2rem;
  min-width: 1rem;
  flex: 1;
`;
const TaskMeta = styled.div`
  display: grid;
  grid-template-areas: 'loader state owners';
  grid-template-columns: 1fr auto auto;

  align-items: center;
  gap: ${theme.spacing.x1};
  min-width: fit-content;
  justify-content: flex-end;

  .initiative-avatar {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 2rem;
    width: 6.5rem;
  }
  .avatar {
    margin-left: 2px;
  }
  .initiative-state {
    min-width: fit-content;
  }
  .initiative-loader {
    width: 2.4rem;
  }

  @media ${theme.devices.mobile} {
    .initiative-due-date,
    .initiative-state {
      display: none;
    }
  }
`;
const Form = styled.form`
  flex: 1;
  display: flex;
`;

const TitleInput = styled.input`
  border: none;
  width: 100%;
  outline: none;
  box-shadow: none;
  resize: none;
  padding: 0;
  margin: 0;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 1.4rem;
  line-height: 1.4;
  background: transparent;

  &:focus {
    outline: 0;
  }
`;

const Container = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

interface Props {
  outcomeId: string;
  lastInitiative: Initiative;
}

function AddTask(props: Props) {
  const { outcomeId, lastInitiative } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inputEl: any = useRef(null);
  const [title, setTitle] = useState('');
  const queryCache = useQueryCache();

  const [createInitiativeMutation, { isLoading: isCreating }] = useMutation(remoteApi.createInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
      setTitle('');
    },
  });

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const title = e.target.value;
    setTitle(title);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const lastRank = lastInitiative ? lastInitiative.rank : '';
    const rank = midString(lastRank, '');

    const params = {
      title,
      rank,
      state: 'open',
      roadmap_state: 'later',
      work_state: 'backlog',
    };

    createInitiativeMutation({
      outcomeId,
      initiative: params,
    });
  };

  const handleImport = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`outcome:${outcomeId}:import.initiatives`));
  };

  return (
    <Container className="initiative tree-item">
      <Form onSubmit={handleSubmit}>
        <TaskDetails>
          <KoalaCheckbox disabled={true} checked={false} size="small" />
          <TitleInput
            ref={inputEl}
            value={title}
            onChange={handleTitleChange}
            placeholder={t('shared.addInitiativePlaceholder') ?? `What can help you achieve this goal?`}
          />
        </TaskDetails>
        <TaskMeta>
          <div className="initiative-loader">{isCreating && <KoalaLoader />}</div>
          <KoalaButton size="small" appearance="secondary" type="button" onClick={handleImport}>
            {t('shared.import')}
          </KoalaButton>
          {title && (
            <KoalaButton size="small" appearance="primary" type="submit" disabled={isCreating || !title}>
              {t('shared.save')}
            </KoalaButton>
          )}
        </TaskMeta>
      </Form>
    </Container>
  );
}

export default AddTask;

import FormField from 'components/FormField';
import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { ChangeEvent, useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const LinkInput = styled.div`
  grid-area: input;
  max-width: 80rem;
  margin: ${theme.spacing.x2} 0px;
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 10rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    resize: vertical;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }
`;

interface Props {
  outcomeId: string;
}
function ImportInitiatives(props: Props) {
  const { outcomeId } = props;
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.importInitiatives';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // const [createInitiativeMutation, { isLoading: isCreating }] = useMutation(remoteApi.createInitiative, {
  //   onSuccess: () => {
  //     queryCache.invalidateQueries(queryKeys.initiatives);
  //   },
  // });

  interface InitiativeParams {
    outcomeId: string;
    initiative: {
      title: string;
      state: string;
      roadmap_state: string;
      work_state: string;
    };
  }

  const createInitiativeAsync = async (params: InitiativeParams) => {
    try {
      const response = await remoteApi.createInitiative(params);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      return response.data;
    } catch (error: any) {
      if (error.response?.data?.errors) {
        const errorMessages = Object.entries(error.response.data.errors)
          .map(([field, messages]) => `${field}: ${(messages as string[]).join(', ')}`)
          .join('\n');
        return {
          error: true,
          statusText: errorMessages,
          ...params,
        };
      }
      return {
        error: true,
        statusText: error.response?.statusText || 'Failed to create',
        ...params,
      };
    }
  };

  const [createInitiativesMutation, { isLoading }] = useMutation(
    async (initiatives: InitiativeParams[]) => {
      const createPromises = initiatives.map((initiative) => createInitiativeAsync(initiative));
      return Promise.all(createPromises);
    },
    {
      onSuccess: (results) => {
        const failedInitiatives = results.filter((result) => result.error);
        if (failedInitiatives.length > 0) {
          const failedInitiativesMessage = failedInitiatives
            .map((failed) => `${failed.initiative.title} (${failed.statusText})`)
            .join('\n');
          const failedMessage = `${t(`${translationKey}.failed`, {
            count: failedInitiatives.length,
            plans: failedInitiativesMessage,
          })}`;
          window.alert(failedMessage);
        }
        if (results.length !== failedInitiatives.length) {
          // if there's at least one success, clear cache
          queryCache.invalidateQueries([queryKeys.initiatives, outcomeId]);
        }
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleImport = (e: any) => {
    e.preventDefault();
    const links = content.split('\n').filter((link) => link.trim() !== '');
    if (links.length === 0) {
      return;
    }

    const params: InitiativeParams[] = links.map((link) => ({
      outcomeId,
      initiative: { title: link, state: 'open', roadmap_state: 'later', work_state: 'backlog' },
    }));
    createInitiativesMutation(params);
  };

  const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const inputName = e.target.value;
    setContent(inputName);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <p>
          {t(`${translationKey}.description`, {
            initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
          })}
        </p>
        <LinkInput>
          <form onSubmit={handleImport}>
            <FormField style={{ marginBottom: 0 }}>
              <textarea
                placeholder={
                  t(`${translationKey}.placeholder`, {
                    initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase(),
                  }) ?? 'Enter one per line'
                }
                className="segment-input"
                value={content}
                onChange={handleContentChange}
              />
            </FormField>
            <FormField style={{ marginBottom: 0 }}>
              <KoalaButton onClick={handleImport} loading={isLoading} disabled={isLoading}>
                {t('shared.import')}
              </KoalaButton>
            </FormField>
          </form>
        </LinkInput>
      </ModalContent>
    </ModalGrid>
  );
}

export default ImportInitiatives;
